import { Component, OnInit, Inject, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedmobService } from '../services/sharedmob.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  @ViewChildren("otpfield") otpView: QueryList<ElementRef>;
  
  mobileNumber: any;
  submitMessage: string;
  bearerToken: any;
  showOtp: boolean;
  otpSuccessResp: any;
  loginForm: FormGroup;
  submitted = false;

  constructor(private authService: AuthService,
    private formBuilder: FormBuilder, public activeModal: MatDialog,
    public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,e1: ElementRef,private mobservice: SharedmobService) {

    this.loginForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.minLength(10),
      Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      otp: [''],
    });
    dialogRef.disableClose = true;
    this.showOtp = false;
    if (data) {
      this.loginForm.controls['mobileNumber'].setValue(data.mobileNumber);
    }
  }

  ngOnInit(): void {    
  }

  ngAfterViewInit() {
    //for focus field
    this.otpView.changes.subscribe((list: QueryList<ElementRef>) => {
      if (list.length > 0) {
        list.first.nativeElement.focus();
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  validateOtp() {
    this.authService.validateOtpService(this.loginForm.value.otp).subscribe(
      result => {
        if (result["otp_Status"] === "Success") {
          this.bearerToken = result['token'];
          this.authService.setBearerToken(this.bearerToken);
          window.localStorage.setItem("userName",result['user_Name']);
          window.localStorage.setItem("userId", result['user_Id']);
          let currentTime= new Date().getTime();
          window.localStorage.setItem("userlogintime", currentTime+"");
          let uname= window.localStorage.getItem("userName");
          this.mobservice.changeMobileNo(uname);
          this.dialogRef.close("Success");
        }
        else {
          this.submitMessage = "Wrong OTP! Please give correct OTP";
        }
      },
      err => {
        if (err.status === 404) {
          this.submitMessage = "Unable to validate 404";
        }
        if (err.status === 500) {
          this.submitMessage = "Unable to validate 500";
        } else {
          this.submitMessage = "Unable to validate";
        }
      });
  }

  resendOtp() {
    this.getOtp();
  }

  getOtp() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.authService.getOTP(this.loginForm.value.mobileNumber).subscribe(result => {
        this.otpSuccessResp = result;
        if (result["otp_Status"] === "Success") {
          this.showOtp = true;
          window.localStorage.setItem("mobileNumber", result["mobileNumber"]);
          window.localStorage.setItem("uuId", result["uuId"]);
          window.localStorage.setItem("user_Type", result["user_Type"]);
        }
      },
      err => {
        if (err.status === 404) {
          this.submitMessage = err.message;
        } else {
          this.submitMessage = err.error.message;
        }
      });
  }
  closeDialog(data) {
    this.dialogRef.close(data);
  }
}
