import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { SubProductComponent } from './sub-product/sub-product.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CartcheckoutComponent } from './cartcheckout/cartcheckout.component';
import { PaymentsComponent } from './payments/payments.component';
import { WindowRefService } from './services/window-ref.service';
import { ShowCustOrderComponent } from './show-cust-order/show-cust-order.component';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { EditprofileComponent } from './editprofile/editprofile.component';
import { MyRatingsComponent } from './my-ratings/my-ratings.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { OutOfStockOrderItemsComponent } from './out-of-stock-order-items/out-of-stock-order-items.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { AddToCartComponent } from './add-to-cart/add-to-cart.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DodDialogMSGComponent } from './dod-dialog-msg/dod-dialog-msg.component';
import { DeliveryLocationComponent } from './delivery-location/delivery-location.component';
import { SelectDelAddrDialogMSGComponent } from './select-del-addr-dialog-msg/select-del-addr-dialog-msg.component';
import { ClearCartItemDialogComponent } from './clear-cart-item-dialog/clear-cart-item-dialog.component';
import { CancelOrderModalComponent } from './cancel-order-modal/cancel-order-modal.component';
import { MaxQuantityAllowedPopUpComponent } from './max-quantity-allowed-pop-up/max-quantity-allowed-pop-up.component';
import { AlertComponent } from './alert/alert.component';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';
import { SeacrhComponent } from './seacrh/seacrh.component';
import { CartbarComponent } from './cartbar/cartbar.component';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { ProductDescViewComponent } from './product-desc-view/product-desc-view.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    SubProductComponent,
    DashboardComponent,
    CartcheckoutComponent,
    PaymentsComponent,
    ShowCustOrderComponent,
    EditprofileComponent,
    MyRatingsComponent,
    MyOrdersComponent,
    OutOfStockOrderItemsComponent,
    FooterComponent,
    AddToCartComponent,
    DodDialogMSGComponent,
    DeliveryLocationComponent,
    SelectDelAddrDialogMSGComponent,
    ClearCartItemDialogComponent,
    CancelOrderModalComponent,
    MaxQuantityAllowedPopUpComponent,
    AlertComponent,
    SeacrhComponent,
    CartbarComponent,
    ProductDescViewComponent,
    ViewInvoiceComponent
  ],
  exports: [
    AppRoutingModule,
    // Material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    // ModalModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // Material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    Ng2PageScrollModule,
    Ng2SearchPipeModule,
    NgbModule,
    DropDownListModule,
    BackButtonDisableModule.forRoot(),
    NgxImageZoomModule
  ],
  providers: [SubProductComponent, WindowRefService, 
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService,
  ],
  entryComponents: [LoginComponent, DeliveryLocationComponent, ProductDescViewComponent,AlertComponent, MaxQuantityAllowedPopUpComponent,CancelOrderModalComponent,DodDialogMSGComponent,ClearCartItemDialogComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }