<div class="css-11zk6ke" id="subpid">
    <section class="css-m42crr">
        <div class=" gatsby-image-wrapper" style="position: relative; overflow: hidden;">
            <img aria-hidden="true" [src]="subproduct_banner_Img" style="width: 100%;height: 100%">
        </div>
        <header class="css-ozrrng">
            <h1 class="css-fqak9j">{{subtypeheading}}</h1>
        </header>
    </section>
    <section class="product-subtype-section">
        <div class="row">
            <div class="col-md-3">
                <div class="responsive_buttons" id="click_filter" (click)=openSideFilter()>
                    <button type="button" class="btn btn-primary">Filter</button>
                </div>
                <div class="filter_responsive" id="responsive_open">
                    <div class="side_filter">
                        <a href="javascript:void(0)" class="closebtn" (click)="closeNav()" style="float: right">&times;</a>
                        <!-- discount filter: open -->
                        <div class="cat_listing Discount" id="filter_discount">
                            <h2 class="cat_all_titl">Select Your Category</h2>
                            <ul>
                                <form>
                                    <li *ngFor="let m of alluniqueFarmCheckboxList">
                                        <div for="discount_10to15" class="">
                                            <input name="category" data-filter="checkbox" data-discount="discount" [(ngModel)]="m.isChecked" (change)="farmcheckValue(m.isChecked?m.farm_Id+'-true':m.farm_Id+'-false')" id="discount_10to15" value="10to15" type="checkbox" class="messi">
                                            <span class="label-text"> {{m.farm_Short_Name}} </span>
                                        </div>
                                    </li>
                                </form>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9 padding_left0">
                <div class="dst_fresh">
                    <div id="product_container">

                        <div class="col-12" style="margin-bottom: 10px" *ngFor="let productSType of farmByFarmId;let i=index">
                            <div class="card" style="margin: 0 auto;width:100%" *ngIf="productSType[productSType.farmName].length>0">
                                <h4 class="card-header-1">
                                    <a data-toggle="collapse" *ngIf="productSType.farmName == 'All'" href="#collapse-personaldetails" aria-expanded="true" aria-controls="collapse-personaldetails" id="heading-personaldetails" class="d-block subprod-cardheader">
                                        <i class="fa fa-chevron-down pull-right"></i> {{productSType.farmName}} Categories
                                    </a>
                                    <a data-toggle="collapse" *ngIf="productSType.farmName !== 'All'" href="#collapse-personaldetails" aria-expanded="true" aria-controls="collapse-personaldetails" id="heading-personaldetails" class="d-block subprod-cardheader">
                                        <i class="fa fa-chevron-down pull-right"></i> {{productSType.farmFullName}}
                                    </a>
                                </h4>
                                <div id="collapse-personaldetails" class="collapse show" aria-labelledby="heading-personaldetails">
                                    <div class="card-body">
                                        <div class="row" id="attach_here">
                                            <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3" *ngFor="let productSubType of productSType[productSType.farmName]">
                                                <div class="content_div">
                                                    <div id="showAddToCartButton" class="css-qiwenx" *ngIf="productSubType.selected_inventory_Id.cart_Quantity === 0 && productSubType.selected_inventory_Id.stock_Avlbl" (click)="openAddtoCartDialog(productSubType.selected_inventory_Id.inventory_Id)">
                                                        <button class="active css-pncr0q" title="Add to Cart">
                                                            <svg width="13" height="12" viewBox="0 0 13 12">
                                                                <path d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                                                    transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div id="showAddToCartButton" class="css-qiwenx" *ngIf="productSubType.selected_inventory_Id.cart_Quantity === 0 && !productSubType.selected_inventory_Id.stock_Avlbl" (click)="openOutOfStockPopUp()">
                                                        <button class="active css-pncr0q" title="Add to Cart">
                                                            <svg width="13" height="12" viewBox="0 0 13 12">
                                                                <path d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                                                    transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div id="{{productSubType.selected_inventory_Id.inventory_Id}}" class="css-shownumber" *ngIf="productSubType.selected_inventory_Id && productSubType.selected_inventory_Id.cart_Quantity > 0" (click)="openAddtoCartDialog(productSubType.selected_inventory_Id.inventory_Id)">
                                                        <button class="active css-pncr0q" style="background-color:black" title="Add to Cart">
                                                            {{productSubType.selected_inventory_Id.cart_Quantity}}
                                                        </button>
                                                    </div>
                                                    <div #incrDecrBtn id="p{{productSubType.selected_inventory_Id.inventory_Id}}" class="css-xupwym">
                                                        <button title="Decrement" class="css-110od4n" (click)="onClickDecrement(productSubType.selected_inventory_Id.inventory_Id,'subp',productSubType.selected_inventory_Id.cart_Quantity,productSType.farmName)">
                                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                                viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z"></path>
                                                            </svg>
                                                        </button>
                                                        <div class="css-vurnku">{{productSubType.selected_inventory_Id.cart_Quantity}}</div>
                                                        <button title="Increment" class="css-110od4n" (click)="onClickIncrement(productSubType.selected_inventory_Id.inventory_Id,'subp',productSType.farmName)">
                                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                                viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7 8.8 0 16-7.2 16-16V272h96.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div class="" id="p{{productSubType.selected_inventory_Id.inventory_Id}}" style="cursor: pointer">
                                                        <img [src]="productSubType.product_Image[0]" id="icon" class="feature-product-img" (click)="openProductDescView(productSubType._id)">
                                                        <div class="content css-vurnku">
                                                            <div class="css-10nf7hq">
                                                                <span class="css-jrxylc">{{productSubType.farm_Short_Name}}</span>
                                                            </div>
                                                            <h4 class="css-6tq2gk" style="height: 45px;"> {{productSubType.product_Name}}</h4>
                                                            <div>
                                                                <select class="form-control price-dropdown" (change)="onChangeSUbProductquantyDesc(productSubType.inventory_Id,$event.target.value)" style="padding: 0;">
                                                                    <option *ngFor="let pspricedesc of productSubType.product_Qty_Details; index as j" [value]="j" [selected]="pspricedesc.inventory_Id === productSubType.selected_inventory_Id.inventory_Id">
                                                                        {{ pspricedesc.quantity_Desc }} - Rs.{{ pspricedesc.allfresh_Price | number:'1.2-2'}}
                                                                    </option>
                                                                </select>
                                                                <div class="form-control" style="margin-top: 10px;height: auto;padding: 0;">
                                                                    <span class="css-down-dropdown-price"> Rs.{{productSubType.selected_inventory_Id.allfresh_Price | number:'1.2-2'}}</span>
                                                                    <span class="css-down-dropdown-line" *ngIf="productSubType.selected_inventory_Id.allfresh_Price !== productSubType.selected_inventory_Id.sell_Price">Rs.{{productSubType.selected_inventory_Id.sell_Price | number:'1.2-2'}} </span>
                                                                    <span class="css-down-dropdown-discount">{{productSubType.selected_inventory_Id.discount_Percentage}} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col d-flex justify-content-center">
            <mat-spinner *ngIf="hideSpinner"></mat-spinner>
        </div>
    </section>
</div>

<!-- div for add to cart -->
<app-cartbar [allCartItems]="allCartItems" (onCartChange)="changedCartData()"></app-cartbar>