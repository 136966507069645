<div style="bottom: 30px; position: relative; z-index: 100;">
    <div class="css-1lu020c" *ngIf='count !==0 && allCartItems && allCartItems.length !== 0'>
        <div class="text-center">
            <button class="css-addtocartbutton" (click)="openAddtoCartPopup()" id="addtocartdiv">
                <div class="css-1a8q8lz">
                    <svg width="16" height="16" viewBox="0 0 16 16">
                        <path id="shopping-bag" d="M4.4,3.6H15.6a.8.8,0,0,1,.8.8V15.6a.8.8,0,0,1-.8.8H4.4a.8.8,0,0,1-.8-.8V4.4A.8.8,0,0,1,4.4,3.6ZM2,4.4A2.4,2.4,0,0,1,4.4,2H15.6A2.4,2.4,0,0,1,18,4.4V15.6A2.4,2.4,0,0,1,15.6,18H4.4A2.4,2.4,0,0,1,2,15.6ZM10,10C7.791,10,6,7.851,6,5.2H7.6c0,2.053,1.335,3.2,2.4,3.2s2.4-1.147,2.4-3.2H14C14,7.851,12.209,10,10,10Z"
                            transform="translate(-2 -2)" fill="#ffffff" fill-rule="evenodd"></path>
                    </svg>Added {{count}} Items
                </div>
                <div class="css-cart-mdl">
                    Proceed to order
                </div>
                <div class="css-j0lge4">Rs.{{totalPrice | number:'1.2-2'}} </div>
            </button>
        </div>
    </div>
</div>