import { Component, OnInit, NgZone, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { OrderService } from '../services/order.service';
import { RouterService } from '../services/router.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedmobService } from '../services/sharedmob.service';
import { DodDialogMSGComponent } from '../dod-dialog-msg/dod-dialog-msg.component';
import { LocationService } from '../services/location.service';
import { SelectDelAddrDialogMSGComponent } from '../select-del-addr-dialog-msg/select-del-addr-dialog-msg.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalLibraryService } from './utis';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { AuthService } from '../services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MaxQuantityAllowedPopUpComponent } from '../max-quantity-allowed-pop-up/max-quantity-allowed-pop-up.component';
import { AlertComponent } from '../alert/alert.component';
import { AppComponent } from '../app.component';

declare let Razorpay: any;

@Component({
  selector: 'cartcheckout',
  templateUrl: './cartcheckout.component.html',
  styleUrls: ['./cartcheckout.component.css', './cartcheckout.component1.css']
})

export class CartcheckoutComponent implements OnInit {

  @ViewChild("mobno") mobView: ElementRef;

  inventoryId: any;
  orderRefId: any;
  logedinmobileNumber: string;
  products: any[] = [];
  objectKeys = Object.keys;
  payableAmount = 0;
  WindowRef: any;
  processingPayment: boolean;
  paymentResponse: any = {};
  rzp1: any;
  allCartItemFromSession: any = [];
  subTotal = 0;
  totalCharges = 0;
  shippingCharge = 0;
  itemTax: any;
  itemDiscount: any;
  errMessage: string;
  blockInventoryObj: any;
  blockInventoryObjItems: any = [];
  itemTotalWeight: any;

  newOrderObj: any;

  orderItemDetailsObj: any;
  orderItemDetailsObjItems: any = [];

  orderConfirmationObj: any;
  shippingDetailsObj: any;
  oldShipingAddress: any;

  paymentDetailsObj: any;
  expectedDelveryDate: any;
  dodVal: number;
  freeDelCharges: number;
  myorderId: any;
  cartForm: FormGroup;
  submitted = false;
  orderConfirmationResp: any;

  DODMSG: string;
  DODItemPrice: number = 0;
  totalPriceWithoutDOD: number = 0;

  isAddressExist: boolean = false;
  exstingAddressResp: any;

  isHomeAddrexist: boolean = false;
  isOfficeAddrexist: boolean = false;
  isOtherAddrexist: boolean = false;

  fName: string;
  lName: string;
  exstingUsermobileNumber: any;
  exstingUserEmail: any;
  address1: any;
  address2: any;
  apartment_Name: any;
  house_No: any;
  address_Type: any;
  city: any;
  state: any;
  pinCode: any;
  landMark: any;
  homePreferedAdd: any;

  ofaddress1: any;
  ofaddress2: any;
  ofapartment_Name: any;
  ofhouse_No: any;
  ofaddress_Type: any;
  ofcity: any;
  ofstate: any;
  ofpinCode: any;
  oflandMark: any;
  ofPreferedAdd: any;

  oaddress1: any;
  oaddress2: any;
  oapartment_Name: any;
  ohouse_No: any;
  oaddress_Type: any;
  ocity: any;
  ostate: any;
  opinCode: any;
  olandMark: any;
  oPreferedAdd: any;

  pincodeList: any;
  locationlist: any;
  selectedLocation: any;
  isAddressDisabled: boolean = false;
  deliveryNotAvailableMsg: string;
  isbuttonSelected = false;
  isOFbuttonSelected = false;
  isObuttonSelected = false;
  selectedpincode: any;

  response;
  razorpayResponse;
  showModal = false;

  data: { [key: string]: Object }[];
  fields: Object;
  text: string;
  // set the value to select an item based on mapped value at initial rendering
  value: string;

  charges: any;
  preOrderRequest: any;

  hideSpinner: boolean;
  forspinnerresp: any;

  constructor(private el: ElementRef, private orderService: OrderService,
    private dialog: MatDialog,
    private routerService: RouterService, private formBuilder: FormBuilder,
    private zone: NgZone, private sharedService: SharedmobService,
    private locService: LocationService, private modalService: NgbModal,
    private authService: AuthService,
    private appcomp: AppComponent) {  }

  ngOnInit(): void {

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.cartForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern("[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]],
      mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      pincode: ['', [Validators.required, Validators.minLength(6)]],
      address1: ['', Validators.required],
      address2: [''],
      apartmentName: ['', Validators.required],
      houseNo: ['', Validators.required],
      city: ['', Validators.required],
      state: [''],
      landmark: [''],
      saveAddress: ['']
    });
    this.charges = JSON.parse(window.localStorage.getItem("sCharges"));
    let userId = window.localStorage.getItem("userId");
    if (userId) {
      let getloginTimediff = this.appcomp.getLoginTimeDif();
      if (getloginTimediff >= 14) {
        this.authService.refreshToken().subscribe(resp => {
          getloginTimediff = 1;
          if (resp['tkn_Status']) {
            let token = resp['token'];
            if (token) {
              this.authService.setBearerToken(token);
              let currentTime = new Date().getTime();
              window.localStorage.setItem("userlogintime", currentTime + "");
            } else {
              this.openLoginDialog(this.cartForm.value.mobileNumber);
            }
          }
          else {
            this.openLoginDialog(this.cartForm.value.mobileNumber);
          }
        },
          err => {
            this.openLoginDialog(this.cartForm.value.mobileNumber);
          });
      }
      else {
        this.GetDeliveryAddressForUser(userId);
      }
    }
    this.locationlist = JSON.parse(window.localStorage.getItem("deliveryLocationList"));
    this.selectedLocation = window.localStorage.getItem("delLoc");
    if (this.locationlist && this.selectedLocation) {
      this.loadPinCode(this.selectedLocation);
    }
    this.getDefaultShippingAddr();

    this.oldShipingAddress = JSON.parse(window.localStorage.getItem("shippingObj"));
    let selectedaddressType = window.localStorage.getItem("radioaddresstype");
    if (this.oldShipingAddress && selectedaddressType) {
      this.restoreShildAddress(selectedaddressType);
    }
    this.cartForm.controls['saveAddress'].setValue(false);
    this.allCartItemFromSession = JSON.parse(window.localStorage.getItem("cartItemObj"));
    if (this.allCartItemFromSession) {
      this.sharedService.changeCount((this.allCartItemFromSession).length);
    }

    this.getSubTotal();
    this.getTotalChargesWithoutDOD();
    this.getTotal();
  }
  //Bind the filter event
  onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text != "") ? query.where("pin_Code", "startswith", e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(this.data, query);
  };

  loadPinCode(selCity) {
    if (this.locationlist && this.selectedLocation) {
      for (let loc of this.locationlist) {
        if (loc._id === selCity) {
          this.cartForm.controls.state.setValue(loc.state);
          this.cartForm.controls.city.setValue(selCity);
          this.pincodeList = loc["pin_Codes"];
          // dropdown search
          this.data = this.pincodeList;
          // maps the appropriate column to fields property
          this.fields = { text: "pin_Code", value: "pin_Code" };
          // set the placeholder to the DropDownList input
          this.text = "Select a pincode";
          let pinCode = this.pincodeList[0]["pin_Code"];
          this.cartForm.controls.pincode.setValue(pinCode);
        }
      }
    }
  }

  ngAfterViewInit() {
    //for focus field
    this.mobView.nativeElement.focus();
  }

  // convenience getter for easy access to form fields
  get f() { return this.cartForm.controls; }

  getDefaultShippingAddr() {
    this.shippingDetailsObj = {
      "salutation": "Mr",
      "first_Name": '',
      "last_Name": '',
      "address1": '',
      "address2": '',
      "apartment_Name": '',
      "city": '',
      "email": '',
      "exclusive_Offers_Campaign": false,
      "house_No": '',
      "landMark": '',
      "phone_Number": '',
      "pinCode": '',
      "saveForFuture": '',
      "state": ''
    };
  }
  restoreShildAddress(selectedaddresstype) {

    if (selectedaddresstype === "homeAddress") {
      this.isbuttonSelected = true;
      this.isOFbuttonSelected = false;
      this.isObuttonSelected = false;
    }
    if (selectedaddresstype === "officeAddress") {
      this.isOFbuttonSelected = true;
      this.isbuttonSelected = false;
      this.isObuttonSelected = false;
    }
    if (selectedaddresstype === "otherAddress") {
      this.isObuttonSelected = true;
      this.isOFbuttonSelected = false;
      this.isbuttonSelected = false;
    }
    this.cartForm.controls.firstName.setValue(this.oldShipingAddress.first_Name);
    this.cartForm.controls.lastName.setValue(this.oldShipingAddress.last_Name);
    this.cartForm.controls.email.setValue(this.oldShipingAddress.email);
    this.cartForm.controls.mobileNumber.setValue(this.oldShipingAddress.phone_Number);

    // show selected pincode in form
    this.data = this.pincodeList;
    let pin = this.oldShipingAddress.pinCode
    let idx = this.pincodeList.findIndex(x => x.pin_Code === pin + "");
    if (idx !== -1) {
      let pinCode = this.pincodeList[idx]["pin_Code"];
      this.cartForm.controls.pincode.setValue(pinCode);
    }
    this.cartForm.controls.address1.setValue(this.oldShipingAddress.address1);
    this.cartForm.controls.address2.setValue(this.oldShipingAddress.address2);
    this.cartForm.controls.apartmentName.setValue(this.oldShipingAddress.apartment_Name);
    this.cartForm.controls.houseNo.setValue(this.oldShipingAddress.house_No);
    this.cartForm.controls.city.setValue(this.oldShipingAddress.city);
    this.cartForm.controls.state.setValue(this.oldShipingAddress.state);
    this.cartForm.controls.landmark.setValue(this.oldShipingAddress.landMark);
    this.cartForm.controls.saveAddress.setValue(this.oldShipingAddress.saveAddress);

  }
  addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }
  public initPay(): void {
    let totalAmount = this.totalCharges * 100;
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + this.expectedDelveryDate);
    const todayISOString = currentDate.toISOString();
    let options: any = {
      "id": "order_" + this.inventoryId,
      "key": environment.RAZORPAY_KEY_ID,
      "amount": totalAmount,
      "currency": "INR",
      "name": "allfresh",
      "description": "Pay for allfresh",
      "receipt": this.inventoryId,
      "image": "https://res.cloudinary.com/dignjitwf/image/upload/v1600260597/All_fresh_HD_WEB_ICON_o7exck.png",
      "modal": {
        "escape": false
      },
      "prefill": {
        "name": this.cartForm.value.firstName + " " + this.cartForm.value.lastName,
        "email": this.cartForm.value.email,
        "contact": window.localStorage.getItem("mobileNumber")
      },

      "notes": {
        "address": "allfresh",
        "note_key": "Transaction at allfresh for inventory id : " + this.inventoryId
      },
      "theme": {
        // "color": "#0096d6";
        "color": "#6db500"
      }
    };
    options.handler = ((response) => {
      options['payment_response_id'] = response.razorpay_payment_id;
      if (response.razorpay_payment_id) {
        this.newOrderObj = {
          // "block_Order_Inventory_Id": this.inventoryId,
          "payment_Amount": this.subTotal + this.shippingCharge,
          // "payment_Date": new Date().toISOString(),
          "payment_Gateway_Name": "razorpay",
          "payment_Status": "success",
          "payment_Transaction_Id": response.razorpay_payment_id,
          "order_Ref_Id": this.orderRefId
        }

      } else {
        this.newOrderObj = {
          // "block_Order_Inventory_Id": this.inventoryId,
          "payment_Amount": this.subTotal + this.shippingCharge,
          // "payment_Date": new Date().toISOString(),
          "payment_Gateway_Name": "razorpay",
          "payment_Status": "failure",
          "payment_Transaction_Id": response.razorpay_payment_id,
          "order_Ref_Id": this.orderRefId
        }
      }

      //send request for final order placed after payment success
      this.orderConfirmation(this.newOrderObj);
    });
    options.modal.ondismiss = (() => {
    });
    let rzp = new Razorpay(options);

    rzp.open();


  }

  getSubTotal() {
    let sCartItemPrice = 0;
    for (let scartItem of this.allCartItemFromSession) {
      if (scartItem.deal_Of_Day_Item) {
        sCartItemPrice = scartItem.deal_Price * scartItem.cart_Quantity;
      } else {
        sCartItemPrice = scartItem.allfresh_Price * scartItem.cart_Quantity;
        // sCartItemPrice = scartItem.sell_Price * scartItem.cart_Quantity;
      }
      this.subTotal += sCartItemPrice;

      if (scartItem.units === "Kgs") {
        this.itemTotalWeight = (scartItem.cart_Quantity * scartItem.quantity).toFixed(3);
      } else if (scartItem.units === "Pcs") {
        this.itemTotalWeight = (scartItem.cart_Quantity * scartItem.quantity);
      } else {
        this.itemTotalWeight = (scartItem.cart_Quantity * scartItem.quantity) / 1000;
      }
      //orderItemDetails
      this.orderItemDetailsObj = {
        "inventory_Id": scartItem.inventory_Id,
        "farm_Id": scartItem.farm_Id,
        "product_Id": scartItem._id,
        "item_Quantity": scartItem.cart_Quantity,
      }
      if (scartItem.deal_Of_Day_Item) {
        this.orderItemDetailsObj["item_Price"] = scartItem.deal_Price;
        this.orderItemDetailsObj["item_Amount"] = scartItem.deal_Price * scartItem.cart_Quantity;
      } else {
       this.orderItemDetailsObj["item_Price"] = scartItem.allfresh_Price;
        this.orderItemDetailsObj["item_Amount"] = scartItem.allfresh_Price * scartItem.cart_Quantity;
      }
      if (scartItem.units === "Pcs") {
        this.orderItemDetailsObj["item_Total_Pieces"] = this.itemTotalWeight;
      }
      else {
        this.orderItemDetailsObj["item_Total_Weight"] = this.itemTotalWeight;
      }
      this.orderItemDetailsObjItems.push(this.orderItemDetailsObj);
    }
  }

  getTotal() {
    if (this.charges) {
      this.itemTax = this.charges["tax_Rate"];
      this.itemDiscount = this.charges["discount_Rate"];
      this.expectedDelveryDate = this.charges["expected_Delivery_Date"];
      this.dodVal = this.charges["dod_condition_value"];
      this.freeDelCharges = this.charges["delivery_charges_free"];
      if (this.totalPriceWithoutDOD < this.freeDelCharges) {
        this.shippingCharge = this.charges["delivery_Charges"];
      } else {
        this.shippingCharge = 0;
      }
    }
    if (this.totalPriceWithoutDOD > this.dodVal) {
      this.totalCharges = this.subTotal + this.shippingCharge;

    } else {
      this.totalCharges = this.subTotal + this.shippingCharge;
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  openDeliveryaddressDialog() {
    const modalRef = this.modalService.open(AlertComponent,
      { size: 'sm', backdrop: 'static', centered: true });
    modalRef.componentInstance.msg = "Please enter valid delivery address";

    modalRef.result.then((resp) => {
      this.cartForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
    });
  }

  openErrorPopup(failedstatusreasonMsg) {
    const modalRef = this.modalService.open(AlertComponent,
      { size: 'sm', backdrop: 'static', centered: true });
    modalRef.componentInstance.msg = failedstatusreasonMsg;

    modalRef.result.then((resp) => {
      this.cartForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
    });
  }

  //pre Order
  blockInventory() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.cartForm.invalid) {
      this.openDeliveryaddressDialog();
      return;
    }
    //pre Order request generate
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + this.expectedDelveryDate);
    const todayISOString = currentDate.toISOString();
    // for shipping details to send in pre order request
    this.generateShippingDetailsObj();

    this.preOrderRequest = {
      "user_Id": window.localStorage.getItem("userId"),
      "order_Date": new Date(),
      "location": window.localStorage.getItem("delLoc"),
      "delivery_Charges": this.shippingCharge,
      "total_Item_Amount": this.subTotal,
      "total_Order_Amount": this.subTotal + this.shippingCharge,
      "expected_Order_Delivery_Date": todayISOString
    }
    this.preOrderRequest["orderItemDetails"] = this.orderItemDetailsObjItems;
    this.preOrderRequest["shippingDetails"] = this.shippingDetailsObj;

    let userid = window.localStorage.getItem("userId");
    if (userid) {
      let isPreorder: boolean = false;
      let getloginTimediff = this.appcomp.getLoginTimeDif();
      if (getloginTimediff >= 14) {
        this.authService.refreshToken().subscribe(resp => {
          getloginTimediff = 1;
          if (resp['tkn_Status']) {
            let token = resp['token'];
            if (token) {
              this.authService.setBearerToken(token);
              let currentTime = new Date().getTime();
              window.localStorage.setItem("userlogintime", currentTime + "");
              isPreorder = true;
            } else {
              this.openLoginDialog(this.cartForm.value.mobileNumber);
            }
          }
          else {
            this.openLoginDialog(this.cartForm.value.mobileNumber);
          }
        },
          err => {
            this.openLoginDialog(this.cartForm.value.mobileNumber);
          });
      }
      else {
        isPreorder = true;
      }
      if (isPreorder) {
        const modalpreorderRef = this.modalService.open(AlertComponent,
          { size: 'md', backdrop: 'static', centered: true });
        modalpreorderRef.componentInstance.msg = "Please do not close or press back button until order confirmation.";
        modalpreorderRef.componentInstance.uniqueval = "yes";
        this.orderService.preOrder(this.preOrderRequest).subscribe(resp => {
          modalpreorderRef.close();
          if (resp && resp["status"]) {
            // this.inventoryId = resp["_id"];
            this.orderRefId = resp["order_Ref_Id"];
            let isdodadded = false;
            for (let doditems of this.allCartItemFromSession) {
              if (!isdodadded && doditems.deal_Of_Day_Item) {
                isdodadded = true;
              }
            }
            if (!isdodadded) {
              this.initPay();
            } else if (isdodadded) {
              if (this.totalPriceWithoutDOD >= this.dodVal) {
                this.initPay();
              }
              else {
                this.openDODDialog();
              }

            }
          } else {
            let outOfStockProduct: any = [];
            let priceChangedProducts: any = [];
            let statusReason: string;
            outOfStockProduct = resp["failedInventory"];
            priceChangedProducts = resp["priceChangeList"];
            statusReason = resp["status_Reason"];
            if (outOfStockProduct && outOfStockProduct.length > 0) {
              sessionStorage.setItem("outOfStockItems", JSON.stringify(outOfStockProduct));
            }
            if (priceChangedProducts && priceChangedProducts.length > 0) {
              sessionStorage.setItem("priceChangedItems", JSON.stringify(priceChangedProducts));
            }

            if ((outOfStockProduct && outOfStockProduct.length > 0) || (priceChangedProducts && priceChangedProducts.length > 0)) {
              this.routerService.routeToOutOfStockItems();
            } else {
              if (statusReason) {
                let failedstatusreasonMsg = statusReason;
                this.openErrorPopup(failedstatusreasonMsg);
              } else {
                let othererrormsg = "Order processing failed (Technical challenge)";
                this.openErrorPopup(othererrormsg);
              }
            }
          }
        }, err => {
          modalpreorderRef.close();
          if (err.status === 403) {
            this.openLoginDialog(this.cartForm.value.mobileNumber);
          } else {
            let serviceerrormsg = "Order processing failed (Technical challenge)";
            this.openErrorPopup(serviceerrormsg);
          }
        })
      }
    } else {
      this.openLoginDialog(this.cartForm.value.mobileNumber);
    }
  }

  openDODDialog(): void {
    const modalRef = this.modalService.open(DodDialogMSGComponent,
      { size: 'sm', backdrop: 'static', centered: true });
    modalRef.componentInstance.dodVal = this.dodVal;
    modalRef.componentInstance.totalchagesWithOutDod = this.totalPriceWithoutDOD;
  }

  getTotalChargesWithoutDOD() {
    this.DODItemPrice = 0;
    for (let doditems of this.allCartItemFromSession) {
      if (doditems.deal_Of_Day_Item) {
        this.DODItemPrice += doditems.deal_Price;
      }
    }
    this.totalPriceWithoutDOD = this.subTotal - this.DODItemPrice;
  }

  generateShippingDetailsObj() {
    this.shippingDetailsObj = {
      "salutation": "Mr",
      "first_Name": this.cartForm.value.firstName,
      "last_Name": this.cartForm.value.lastName,
      "address1": this.cartForm.value.address1,
      "address2": this.cartForm.value.address2,
      "apartment_Name": this.cartForm.value.apartmentName,
      "city": this.cartForm.value.city,
      "email": this.cartForm.value.email,
      "exclusive_Offers_Campaign": false,
      "house_No": this.cartForm.value.houseNo,
      "landMark": this.cartForm.value.landmark,
      "phone_Number": this.cartForm.value.mobileNumber,
      "pinCode": this.cartForm.value.pincode,
      "saveForFuture": this.cartForm.value.saveAddress,
      "state": this.cartForm.value.state,
    };
    window.localStorage.setItem("shippingObj", JSON.stringify(this.shippingDetailsObj));
  }

  orderConfirmation(orderdata) {
    this.sharedService.changeOrderedData(orderdata);
    this.zone.run(() => this.routerService.routeToShowOrder());
  }

  openLoginDialog(mobNo): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '350px',
      data: {
        'mobileNumber': mobNo
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "Success") {
        this.logedinmobileNumber = window.localStorage.getItem("userName");
        this.sharedService.changeMobileNo(this.logedinmobileNumber);
        this.routerService.routeToCheckOut();
        let userid = window.localStorage.getItem("userId");
        if (userid) {
          this.orderService.preOrder(this.preOrderRequest).subscribe(resp => {
            if (resp["status"]) {
              this.orderRefId = resp["order_Ref_Id"];
              this.initPay();
            } else {
              let outOfStockProduct: any = [];
              let priceChangedProducts: any = [];
              let statusReason: string;
              outOfStockProduct = resp["failedInventory"];
              priceChangedProducts = resp["priceChangeList"];
              statusReason = resp["status_Reason"];
              if (outOfStockProduct && outOfStockProduct.length > 0) {
                sessionStorage.setItem("outOfStockItems", JSON.stringify(outOfStockProduct));
              }
              if (priceChangedProducts && priceChangedProducts.length > 0) {
                sessionStorage.setItem("priceChangedItems", JSON.stringify(priceChangedProducts));
              }

              if ((outOfStockProduct && outOfStockProduct.length > 0) || (priceChangedProducts && priceChangedProducts.length > 0)) {
                this.routerService.routeToOutOfStockItems();
              } else {
                if (statusReason) {
                  this.errMessage = statusReason;
                } else {
                  this.errMessage = "Order processing failed (service error)";
                }
              }
            }
            this.isAddressExist = true;
            this.routerService.routeToCheckOut();
          }, err => {
            this.errMessage = "error in pre order";
          })
        }

      }
    });
  }

  continueShopping() {
    this.shippingDetailsObj = {
      "salutation": "Mr",
      "first_Name": this.cartForm.get("firstName").value,
      "last_Name": this.cartForm.get("lastName").value,
      "address1": this.cartForm.get("address1").value,
      "address2": this.cartForm.get("address2").value,
      "apartment_Name": this.cartForm.get("apartmentName").value,
      "city": this.cartForm.get("city").value,
      "email": this.cartForm.get("email").value,
      "exclusive_Offers_Campaign": false,
      "house_No": this.cartForm.get("houseNo").value,
      "landMark": this.cartForm.get("landmark").value,
      "phone_Number": this.cartForm.get("mobileNumber").value,
      "pinCode": this.cartForm.get("pincode").value,
      "saveForFuture": this.cartForm.get('saveAddress').value,
      "state": this.cartForm.value.state
    };
    window.localStorage.setItem("shippingObj", JSON.stringify(this.shippingDetailsObj));

    this.allCartItemFromSession = JSON.parse(window.localStorage.getItem("cartItemObj"));

    this.routerService.routeToHome();
  }

  GetDeliveryAddressForUser(userId) {
    let token = this.authService.getBearerToken();
    this.locService.getExstingDeliveryAddress(userId).subscribe(resp => {
      this.exstingAddressResp = resp;
      if (this.exstingAddressResp && this.exstingAddressResp.length > 0) {
        this.isAddressExist = true;
        for (let address of this.exstingAddressResp) {
          if (address['user_Id'] && address['address_Type'] === "Home") {
            this.isHomeAddrexist = true;
            this.fName = address['first_Name'];
            this.lName = address['last_Name'];
            this.exstingUsermobileNumber = address['mobileNumber'];
            this.exstingUserEmail = address['email'];
            this.address1 = address['address1'];
            this.address2 = address['address2'];
            this.apartment_Name = address['apartment_Name'];
            this.house_No = address['house_No'];
            this.address_Type = address['address_Type'];
            this.city = address['city'];
            this.state = address['state'];
            this.pinCode = address['pinCode'];
            this.landMark = address['landMark'];

            if (this.selectedLocation !== address['city']) {
              this.isAddressDisabled = true;
              this.deliveryNotAvailableMsg = "DELIVERY NOT AVAILABLE TO THIS LOCATION"
            }
          }
          if (address['user_Id'] && address['address_Type'] === "Office") {
            this.isOfficeAddrexist = true;
            this.fName = address['first_Name'];
            this.lName = address['last_Name'];
            this.exstingUsermobileNumber = address['mobileNumber'];
            this.exstingUserEmail = address['email'];
            this.ofaddress1 = address['address1'];
            this.ofaddress2 = address['address2'];
            this.ofapartment_Name = address['apartment_Name'];
            this.ofhouse_No = address['house_No'];
            this.ofaddress_Type = address['address_Type'];
            this.ofcity = address['city'];
            this.ofstate = address['state'];
            this.ofpinCode = address['pinCode'];
            this.oflandMark = address['landMark'];

            if (this.selectedLocation !== address['city']) {
              this.isAddressDisabled = true;
              this.deliveryNotAvailableMsg = "DELIVERY NOT AVAILABLE TO THIS LOCATION"
            }
          }
          if (address['user_Id'] && address['address_Type'] === "Other") {
            this.isOtherAddrexist = true;
            this.fName = address['first_Name'];
            this.lName = address['last_Name'];
            this.exstingUsermobileNumber = address['mobileNumber'];
            this.exstingUserEmail = address['email'];
            this.oaddress1 = address['address1'];
            this.oaddress2 = address['address2'];
            this.oapartment_Name = address['apartment_Name'];
            this.ohouse_No = address['house_No'];
            this.oaddress_Type = address['address_Type'];
            this.ocity = address['city'];
            this.ostate = address['state'];
            this.opinCode = address['pinCode'];
            this.olandMark = address['landMark'];
            if (this.selectedLocation !== address['city']) {
              this.isAddressDisabled = true;
              this.deliveryNotAvailableMsg = "DELIVERY NOT AVAILABLE TO THIS LOCATION"
            }
          }
        }
      }
    }, err => {
      this.errMessage = "No adress found..";
    })
    // }
  }

  onCHangeRadioDelAddress(e) {
    if (e.target.value === "homeAddress") {
      this.isbuttonSelected = true;
      this.isOFbuttonSelected = false;
      this.isObuttonSelected = false;
    }
    if (e.target.value === "officeAddress") {
      this.isOFbuttonSelected = true;
      this.isbuttonSelected = false;
      this.isObuttonSelected = false;
    }
    if (e.target.value === "otherAddress") {
      this.isObuttonSelected = true;
      this.isOFbuttonSelected = false;
      this.isbuttonSelected = false;
    }
    this.showSelectedAddressValues(e.target.value);
  }

  showSelectedAddressValues(addresstyperadioval) {
    window.localStorage.setItem("radioaddresstype", addresstyperadioval);
    let selectedDelLocation = window.localStorage.getItem("delLoc");
    for (let address of this.exstingAddressResp) {
      if (address['user_Id'] && address['address_Type'] === "Home" && addresstyperadioval === "homeAddress") {
        this.loadPinCode(address['city']);
        if (selectedDelLocation == address['city']) {
          this.cartForm.controls.firstName.setValue(address['first_Name']);
          this.cartForm.controls.lastName.setValue(address['last_Name']);
          this.cartForm.controls.email.setValue(address['email']);
          this.cartForm.controls.mobileNumber.setValue(address['mobileNumber']);
          // show selected pincode in form
          this.data = this.pincodeList;
          let pin = address['pinCode'];
          let idx = this.pincodeList.findIndex(x => x.pin_Code === pin + "");
          if (idx !== -1) {
            let pinCode = this.pincodeList[idx]["pin_Code"];
            this.cartForm.controls.pincode.setValue(pinCode);
          }
          this.cartForm.controls.address1.setValue(address['address1']);
          this.cartForm.controls.address2.setValue(address['address2']);
          this.cartForm.controls.apartmentName.setValue(address['apartment_Name']);
          this.cartForm.controls.houseNo.setValue(address['house_No']);
          this.cartForm.controls.city.setValue(address['city']);
          this.cartForm.controls.landmark.setValue(address['landMark']);
          this.cartForm.controls.saveAddress.setValue(address['saveAddress']);
        }
      }
      if (address['user_Id'] && address['address_Type'] === "Office" && addresstyperadioval === "officeAddress") {
        if (selectedDelLocation == address['city']) {
          this.cartForm.controls.firstName.setValue(address['first_Name']);
          this.cartForm.controls.lastName.setValue(address['last_Name']);
          this.cartForm.controls.email.setValue(address['email']);
          this.cartForm.controls.mobileNumber.setValue(address['mobileNumber']);
          // show selected pincode in form
          this.data = this.pincodeList;
          let pin = address['pinCode'];
          let idx = this.pincodeList.findIndex(x => x.pin_Code === pin + "");
          if (idx !== -1) {
            let pinCode = this.pincodeList[idx]["pin_Code"];
            this.cartForm.controls.pincode.setValue(pinCode);
          }
          this.cartForm.controls.address1.setValue(address['address1']);
          this.cartForm.controls.address2.setValue(address['address2']);
          this.cartForm.controls.apartmentName.setValue(address['apartment_Name']);
          this.cartForm.controls.houseNo.setValue(address['house_No']);
          this.cartForm.controls.city.setValue(address['city']);
          this.cartForm.controls.landmark.setValue(address['landMark']);
          this.cartForm.controls.saveAddress.setValue(address['saveAddress']);
        }
      }
      if (address['user_Id'] && address['address_Type'] === "Other" && addresstyperadioval === "otherAddress") {
        if (selectedDelLocation == address['city']) {

          this.cartForm.controls.firstName.setValue(address['first_Name']);
          this.cartForm.controls.lastName.setValue(address['last_Name']);
          this.cartForm.controls.email.setValue(address['email']);
          this.cartForm.controls.mobileNumber.setValue(address['mobileNumber']);
          // show selected pincode in form
          this.data = this.pincodeList;
          let pin = address['pinCode'];
          let idx = this.pincodeList.findIndex(x => x.pin_Code === pin + "");
          if (idx !== -1) {
            let pinCode = this.pincodeList[idx]["pin_Code"];
            this.cartForm.controls.pincode.setValue(pinCode);
          }
          this.cartForm.controls.address1.setValue(address['address1']);
          this.cartForm.controls.address2.setValue(address['address2']);
          this.cartForm.controls.apartmentName.setValue(address['apartment_Name']);
          this.cartForm.controls.houseNo.setValue(address['house_No']);
          this.cartForm.controls.city.setValue(address['city']);
          this.cartForm.controls.landmark.setValue(address['landMark']);
          this.cartForm.controls.saveAddress.setValue(address['saveAddress']);
        }
      }
    }
  }

  openSelectAddressDialog(): void {

    const modalRef = this.modalService.open(SelectDelAddrDialogMSGComponent,
      { size: 'sm', backdrop: 'static', centered: true });

    modalRef.result.then((resp) => {
      if (resp === "Success") {
        this.routerService.routeToCheckOut();
      } else {
        this.routerService.routeToCheckOut();
      }
    });
  }
}
