import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  currentNameSubject$ = new BehaviorSubject("");
  allLocationsList:any;
  alldelvaddress:any;
  
  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getAllLocationsList(){
    return this.httpClient.get(AppComponent.LOCATION_API_ENDPOINT+'getlocations', {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.allLocationsList = Resp;
      this.currentNameSubject$.next(this.allLocationsList);
   }))
  }

  getExstingDeliveryAddress(userId){
    const headers = new HttpHeaders()
      .set('content-type', `application/json`)
      .set('Authorization',`Bearer ${this.authService.getBearerToken()}`)
      .set('userid',window.localStorage.getItem("userId"))
      .set('Access-Control-Allow-Origin', '*');
    return this.httpClient.get(AppComponent.USER_API_ENDPOINT + 'getUserDeliveryAddressById/' + userId, {
      'headers':headers
     }).pipe(tap(Resp => {
      this.alldelvaddress = Resp;
      this.currentNameSubject$.next(this.alldelvaddress);
   }))
  }
}
