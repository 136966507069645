import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class TrendingProductService {

  trendingproduct:any;
  currentNameSubject$ = new BehaviorSubject("");

  constructor(private httpClient: HttpClient) { }

  allTrendingProducts(delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getTrendingProductsLocationEnhanced/'+delloc, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.trendingproduct = Resp;
      this.currentNameSubject$.next(this.trendingproduct);
   }))
  }
}
