import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderHistoryService {

  currentNameSubject$ = new BehaviorSubject("");
  getcustOrderResp:any;
  viewInvoiceResp:any;

  constructor(private httpClient: HttpClient, private authService:AuthService) { }

  getOrderByCustomer(custId){
    const headers = new HttpHeaders()
      .set('content-type', `application/json`)
      .set('Authorization',`Bearer ${this.authService.getBearerToken()}`)
      .set('userid',window.localStorage.getItem("userId"))
      .set('Access-Control-Allow-Origin', '*');

    return this.httpClient.get(AppComponent.INVENTORY_API_ENDPOINT+'/getOrderByCustomer/'+custId, {
      headers: headers
    }).pipe(tap(Resp => {
     this.getcustOrderResp = Resp;
     this.currentNameSubject$.next(this.getcustOrderResp);
  }))
  }

  viewInvoice(invoiceId){
    const headers = new HttpHeaders()
      .set('content-type', `application/json`)
      .set('Authorization',`Bearer ${this.authService.getBearerToken()}`)
      .set('userid',window.localStorage.getItem("userId"))
      .set('Access-Control-Allow-Origin', '*');

    return this.httpClient.get(AppComponent.VIEW_INVOICE_API_ENDPOINT+'viewtaxinvoice/'+invoiceId, {
      headers: headers
    }).pipe(tap(Resp => {
     this.viewInvoiceResp = Resp;
     this.currentNameSubject$.next(this.viewInvoiceResp);
  }))
  }
}
