<div style="cursor: pointer;">
    <div class="card">
        <div class="card-header">
            Information
        </div>
        <div class="card-body">
            <h2 class="card-text">
                This action will clear your cart items automatically
            </h2>
            <div class="text-center" style="margin-top: 20px;">
                <button mat-raised-button class="btn css-mybutton" (click)="OnClickYes()" style="margin-right: 20px;">
                    Yes
                </button>
                <button mat-raised-button class="btn css-mybutton" (click)="OnClickNo()">
                    No
                </button>
            </div>
        </div>
    </div>
</div>