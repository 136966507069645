import { Component, OnInit, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterService } from '../services/router.service';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from '../services/cart.service';
import { SharedmobService } from '../services/sharedmob.service';

@Component({
  selector: 'app-out-of-stock-order-items',
  templateUrl: './out-of-stock-order-items.component.html',
  styleUrls: ['./out-of-stock-order-items.component.css']
})
export class OutOfStockOrderItemsComponent implements OnInit {

  allCartItemsFromSession: any = [];
  outOfStockItems: any = [];
  priceChangedItemsList: any = [];
  totalItemsCount: any;
  totalPriceWithoutDOD: number = 0;
  dodVal: number = 0;

  heading: string;
  subHeading: string;
  otfooter: string;

  constructor(private routerService: RouterService, private cartService: CartService,
    private sharedService: SharedmobService) { }

  ngOnInit(): void {

    this.allCartItemsFromSession = JSON.parse(window.localStorage.getItem("cartItemObj"));
    if (this.allCartItemsFromSession) {
      this.totalItemsCount = this.allCartItemsFromSession.length;

      let allOutOfStockProductFromSession = JSON.parse(sessionStorage.getItem("outOfStockItems"));
      let allPriceChangedProductFromSession = JSON.parse(sessionStorage.getItem("priceChangedItems"));

      if (allOutOfStockProductFromSession && allOutOfStockProductFromSession.length > 0 && allPriceChangedProductFromSession && allPriceChangedProductFromSession.length > 0) {
        this.heading = "Stock Unavailable / Price Change Alert";
        this.subHeading = "Stock Unavailable & Price change for the below product(s)";
        this.otfooter = "Confirm will update current price & available quantity of the above product(s) in the shopping cart. Go Back Shopping, you can add product(s) from other categories";
        this.getPriceChangedProducts(allPriceChangedProductFromSession);
        this.getOutOfStockProduct(allOutOfStockProductFromSession);
      }
      else if (allPriceChangedProductFromSession && allPriceChangedProductFromSession.length > 0) {
        this.heading = "Price Change Alert";
        this.subHeading = "Price for the below product(s) has been changed";
        this.otfooter = "Confirm will update current price of the above product(s) in the shopping cart. Go Back Shopping, you can add product(s) from other categories";
        this.getPriceChangedProducts(allPriceChangedProductFromSession);
      }
      else if (allOutOfStockProductFromSession && allOutOfStockProductFromSession.length > 0) {
        this.heading = "Stock Unavailable Alert";
        this.subHeading = "Stock for the below product(s) are not available";
        this.otfooter = "Confirm will update the quantity of the above product(s) in your cart. Go Back Shopping, you can add product(s) from other categories";
        this.getOutOfStockProduct(allOutOfStockProductFromSession);
      }
    }
  }

  getOutOfStockProduct(outofstocks) {
    if (outofstocks && outofstocks.length > 0) {
      for (let cartitems of this.allCartItemsFromSession) {
        this.checkOutofStockItems(cartitems, outofstocks);
      }
    }
  }

  checkOutofStockItems(cartItemObj, outofstockitemsList) {
    for (let outofstockitem of outofstockitemsList) {
      if (outofstockitem.inventory_Id === cartItemObj.inventory_Id) {
        cartItemObj['available_Qty'] = outofstockitem['available_Qty'];
        this.outOfStockItems.push(cartItemObj);
      }
    }
  }

  getPriceChangedProducts(pricechanged) {
    if (pricechanged && pricechanged.length > 0) {
      for (let cartitems of this.allCartItemsFromSession) {
        this.checkPriceChangedItems(cartitems, pricechanged);
      }
    }
  }

  checkPriceChangedItems(cartItemObj, pricechangedlist) {
    for (let pricechangeditem of pricechangedlist) {
      if (pricechangeditem.inventory_Id === cartItemObj.inventory_Id) {
        // cartItemObj["oldPrice"] = cartItemObj["sell_Price"];
        // cartItemObj['sell_Price'] = pricechangeditem.item_Price;
        cartItemObj["oldPrice"] = cartItemObj["allfresh_Price"];
        cartItemObj['allfresh_Price'] = pricechangeditem.item_Price;
        this.priceChangedItemsList.push(cartItemObj);
      }
    }
  }

  confirmStockItems() {
    this.removeOutofStockAndPriceChangedItems();
    let allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
    if (allCartItems && allCartItems.length == 0) {
      this.cartService.clearAllData();
      this.routerService.routeToHome();
    } else {
      this.routerService.routeToCheckOut();
    }

  }
  goBackShopping() {
    this.removeOutofStockAndPriceChangedItems();
    this.routerService.routeToHome();
  }

  removeOutofStockAndPriceChangedItems() {
    if (this.outOfStockItems && this.outOfStockItems.length > 0 && this.priceChangedItemsList && this.priceChangedItemsList.length > 0) {
      this.updatePriceChangedItems();
      for (let rmvStckItems of this.outOfStockItems) {
        for (let cartitems of this.allCartItemsFromSession) {
          if (rmvStckItems.inventory_Id === cartitems.inventory_Id) {
            if (rmvStckItems['available_Qty'] === 0) {
              let cartitems = JSON.parse(window.localStorage.getItem("cartItemObj"));
              const updatedCartItems: any = cartitems.filter(x => x.inventory_Id !== rmvStckItems.inventory_Id);
              if (updatedCartItems) {
                window.localStorage.setItem("cartItemObj", JSON.stringify(updatedCartItems));
              }
              this.allCartItemsFromSession = JSON.parse(window.localStorage.getItem("cartItemObj"));
              this.cartService.getCartItemCountandPrice(this.allCartItemsFromSession);
            } else {
              cartitems['cart_Quantity'] = rmvStckItems['available_Qty'];
            }
          }
        }
        window.localStorage.setItem("cartItemObj", JSON.stringify(this.allCartItemsFromSession));
      }
    }
    else if (this.outOfStockItems && this.outOfStockItems.length > 0) {
      this.removeOutOfStockItems();
    }
    else if (this.priceChangedItemsList && this.priceChangedItemsList.length > 0) {
      this.updatePriceChangedItems();
    }
    sessionStorage.removeItem("outOfStockItems");
    sessionStorage.removeItem("priceChangedItems");
  }

  removeOutOfStockItems() {
    for (let rmvStckItems of this.outOfStockItems) {
      for (let cartitems of this.allCartItemsFromSession) {
        if (rmvStckItems.inventory_Id === cartitems.inventory_Id) {
          if (rmvStckItems['available_Qty'] == 0) {
            let cartitems = JSON.parse(window.localStorage.getItem("cartItemObj"));
            const updatedCartItems: any = cartitems.filter(x => x.inventory_Id !== rmvStckItems.inventory_Id);
            if (updatedCartItems) {
              window.localStorage.setItem("cartItemObj", JSON.stringify(updatedCartItems));
            }
            this.allCartItemsFromSession = JSON.parse(window.localStorage.getItem("cartItemObj"));
            this.cartService.getCartItemCountandPrice(this.allCartItemsFromSession);
          } else {
            cartitems['cart_Quantity'] = rmvStckItems['available_Qty'];
          }
        }
      }
      window.localStorage.setItem("cartItemObj", JSON.stringify(this.allCartItemsFromSession));

    }
  }

  updatePriceChangedItems() {
    window.localStorage.setItem("cartItemObj", JSON.stringify(this.allCartItemsFromSession));
  }
}
