import { Component, OnInit } from '@angular/core';
import { RouterService } from '../services/router.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { CartService } from '../services/cart.service';
import { SharedmobService } from '../services/sharedmob.service';
import { DodDialogMSGComponent } from '../dod-dialog-msg/dod-dialog-msg.component';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MaxQuantityAllowedPopUpComponent } from '../max-quantity-allowed-pop-up/max-quantity-allowed-pop-up.component';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.css']
})
export class AddToCartComponent implements OnInit {

  allCartItemsFromSession: any = [];
  count: number;
  totalPrice: number;
  DODMSG: string;
  dodVal: number;
  isdodadded: boolean = false;
  totalPriceWithoutDOD: number = 0;
  DODItemPrice: number = 0;
  isProceedToCheckoutDisable: boolean;

  constructor(private routerService: RouterService, private sanitizer: DomSanitizer,
    private cartService: CartService, private sharedService: SharedmobService,
    private modalService: NgbModal) {

    this.sharedService.currentCount.subscribe(c => this.count = c);
    this.sharedService.currentTotalPrice.subscribe(tp => this.totalPrice = tp);
  }

  ngOnInit(): void {
    this.getAllCartItems();
    if (this.getAllCartItems) {
      this.sharedService.currentCount.subscribe(c => this.count = c);
      this.sharedService.currentTotalPrice.subscribe(tp => this.totalPrice = tp);
    }
    if (this.count === 0) {
      this.isProceedToCheckoutDisable = true;
    }
    this.cartService.getCartItemCountandPrice(this.allCartItemsFromSession);
    this.getAllRules();
  }

  getAllCartItems() {
    this.allCartItemsFromSession = JSON.parse(window.localStorage.getItem("cartItemObj"));

  }

  getAllRules() {
    let charges = JSON.parse(window.localStorage.getItem("sCharges"));
    if (charges) {
      this.dodVal = charges["dod_condition_value"];
    }
  }
  closeAddToCartPopUp() {
    this.routerService.routeToHome();
  }

  proceedToCheckout() {
    this.getTotalChargesWithoutDOD();
    if (this.totalPriceWithoutDOD >= this.dodVal) {
      this.routerService.routeToCheckOut();
    } else {
      for (let doditems of this.allCartItemsFromSession) {
        if (!this.isdodadded && doditems.deal_Of_Day_Item) {
          this.isdodadded = true;
        }
      }
      if (this.isdodadded) {
        this.isdodadded = false;
        this.openDODDialog();
      }
      else {
        this.routerService.routeToCheckOut();
      }
    }
  }

  getTotalChargesWithoutDOD() {
    this.DODItemPrice = 0;
    for (let doditems of this.allCartItemsFromSession) {
      if (doditems.deal_Of_Day_Item) {
        this.DODItemPrice += doditems.deal_Price;
      }
    }
    this.totalPriceWithoutDOD = this.totalPrice - this.DODItemPrice;
  }

  openDODDialog(): void {
    const modalRef = this.modalService.open(MaxQuantityAllowedPopUpComponent,
      { size: 'sm', backdrop: 'static', centered: true });
    modalRef.componentInstance.dodVal = this.dodVal;
    modalRef.componentInstance.totalchagesWithOutDod = this.totalPriceWithoutDOD;
    modalRef.componentInstance.dodPopUp = "dodPopUp";

  }

  imageconvertor(data) {
    let objectURL = 'data:image/png;base64,' + data;
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  onClickIncrement(uniqueId, type, item) {
    this.cartService.onClickIncrement(uniqueId, type, item);
    this.sharedService.currentTotalPrice.subscribe(tp => this.totalPrice = tp);
    this.allCartItemsFromSession = JSON.parse(window.localStorage.getItem("cartItemObj"));
  }
  onClickDecrement(uniqueId, type) {
    this.cartService.onClickDecrement(uniqueId, type);
    this.sharedService.currentTotalPrice.subscribe(tp => this.totalPrice = tp);
    this.allCartItemsFromSession = JSON.parse(window.localStorage.getItem("cartItemObj"));
    if (this.allCartItemsFromSession.length === 0) {
      this.routerService.routeToHome();
    }
  }
  updatecartItems(itemid, type) {
    this.cartService.updatecartItems(itemid, type);
  }
  removeItemFromCart(cartProduct) {
    // const indexFmObj: number = this.allCartItemsFromSession.indexOf(cartProduct);
    // this.cartService.removeItemFromCart(indexFmObj);

    let cartitems = JSON.parse(window.localStorage.getItem("cartItemObj"));
    const updatedCartItems: any = cartitems.filter(x => x.inventory_Id !== cartProduct.inventory_Id);

    if (updatedCartItems) {
      window.localStorage.setItem("cartItemObj", JSON.stringify(updatedCartItems));
    }
    this.allCartItemsFromSession = JSON.parse(window.localStorage.getItem("cartItemObj"));
    this.cartService.getCartItemCountandPrice(this.allCartItemsFromSession);
    if (this.allCartItemsFromSession.length == 0) {
      this.cartService.clearAllData();
      this.routerService.routeToHome();
    }
  }

  onClickIncrementDOD() {
    this.cartService.openMaxQuantityAddedPopUp(1);
  }

}

