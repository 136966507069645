import { Component, Input, OnInit } from '@angular/core';
import { FeaturedProductService } from '../services/featured-product.service';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from '../services/router.service';
import { CartService } from '../services/cart.service';
import { SharedmobService } from '../services/sharedmob.service';
import { AlertComponent } from '../alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare function imageZoom(imgID: any, resultID: any, myimg: any): any;
@Component({
  selector: 'app-product-desc-view',
  templateUrl: './product-desc-view.component.html',
  styleUrls: ['./product-desc-view.component.css']
})
export class ProductDescViewComponent implements OnInit {

  errMessage: string;
  allProdDescResp: any;
  product_Image: any;
  productLeftSupportiveImg1: any;
  productLeftSupportiveImg2: any;
  productLeftSupportiveImg3: any;
  productLeftSupportiveImg4: any;
  sellPrice: any;
  allfreshPrice: any;
  stockAbailable: any;
  discount: any;
  defaultButtonSelected: any;
  selectedInvId: any;
  ProductType: string;
  totalPrice: number;
  @Input() allCartItems: any[];
  newcartQuantity = 0;
  myThumbnail;
  allrecipesDesc: any;
  hideSpinner: boolean = true;
  recipeHowToPrepare: any;
  productAvailable: boolean = false;

  selectedIndex: any = 0;

  moveLens;
  getCursorPos;

  count: number;
  mobileNumber: any;

  constructor(private featureproductservice: FeaturedProductService,
    private route: ActivatedRoute, private routerService: RouterService,
    private cartService: CartService, private sharedService: SharedmobService,
    private modalService: NgbModal) {
    this.sharedService.currentCount.subscribe(c => (this.count = c));
    this.sharedService.currentTotalPrice.subscribe(tp => (this.totalPrice = tp));
    this.sharedService.currentMobile.subscribe(message => (this.mobileNumber = message));
  }

  ngOnInit(): void {
    let paramData = "";
    this.route.params.subscribe(params => {
      paramData = params.pid;
    });

    this.allCartItems = [];
    this.sharedService.currentMobile.subscribe(
      message => (this.mobileNumber = message)
    );
    let allCartItemFromSessionInHome = JSON.parse(window.localStorage.getItem("cartItemObj"));
    if (allCartItemFromSessionInHome) {
      this.sharedService.currentCount.subscribe(c => (this.count = c));
      this.sharedService.currentTotalPrice.subscribe(tp => (this.totalPrice = tp));

      this.allCartItems = allCartItemFromSessionInHome;
      this.cartService.getCartItemCountandPrice(allCartItemFromSessionInHome);

      if (this.allCartItems && this.allCartItems.length > 0) {
        for (let ct of this.allCartItems) {
          if (ct._id === paramData) {
            this.newcartQuantity = ct.cart_Quantity;
          }
        }
      }
    } else {
      allCartItemFromSessionInHome = [];
    }
    this.getProductDesc(paramData);

  }


  getProductDesc(pid) {
    let delLoc = window.localStorage.getItem("delLoc");
    this.allProdDescResp = [];
    this.featureproductservice.allProductsById(delLoc, pid).subscribe(resp => {
      this.allProdDescResp = resp[0];
      this.productLeftSupportiveImg1 = this.allProdDescResp['product_Image'][1];
      this.productLeftSupportiveImg2 = this.allProdDescResp['product_Image'][2];
      this.productLeftSupportiveImg3 = this.allProdDescResp['product_Image'][3];
      this.productLeftSupportiveImg4 = this.allProdDescResp['product_Image'][4];
      this.product_Image = this.allProdDescResp['product_Image'][0];
      this.sellPrice = this.allProdDescResp['product_Qty_Details'][0].sell_Price;
      this.allfreshPrice = this.allProdDescResp['product_Qty_Details'][0].allfresh_Price;
      let stockavlb = this.allProdDescResp['product_Qty_Details'][0].stock_Avlbl;
      if (stockavlb) {
        this.stockAbailable = "In-Stock";
      }
      else {
        this.stockAbailable = "Out-Of-Stock";
      }
      this.discount = this.allProdDescResp['product_Qty_Details'][0].discount_Percentage;
      this.defaultButtonSelected = this.allProdDescResp['product_Qty_Details'][0].quantity_Desc;
      this.selectedInvId = this.allProdDescResp['product_Qty_Details'][0].inventory_Id;
      this.findProductType(this.allProdDescResp.product_Type);
      this.hideSpinner = false;
    }, err => {
      this.errMessage = err.error.message;
    })
  }

  findProductType(pType) {
    switch (pType) {
      case "V":
        this.ProductType = "Vegetables"
        break;
      case "F":
        this.ProductType = "Fruits"
        break;
      case "O":
        this.ProductType = "Oils"
        break;
      case "M":
        this.ProductType = "Millets & Seeds"
        break;
      case "D":
        this.ProductType = "Dairy & Eggs"
        break;
      case "FL":
        this.ProductType = "Flowers"
        break;
      default:
      // code block
    }

  }

  onSelectUnitsDetails(unit, index) {
    this.selectedIndex = index;
    let selectedbtn: Element = document.getElementById("unitbtnid" + index);
    for (let p of this.allProdDescResp['product_Qty_Details']) {
      if (p.quantity_Desc === unit) {
        this.sellPrice = p.sell_Price;
        this.allfreshPrice = p.allfresh_Price;
        let stockavlb = p.stock_Avlbl;
        if (stockavlb) {
          this.stockAbailable = "In-Stock";
        }
        else {
          this.stockAbailable = "Out-Of-Stock";
        }
        this.discount = p.discount_Percentage;
        this.defaultButtonSelected = p.quantity_Desc;
        this.selectedInvId = p.inventory_Id;
        this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
        if (this.allCartItems && this.allCartItems.length > 0) {
          let filteredcartitems = this.allCartItems.filter(obj => obj.inventory_Id === p.inventory_Id);
          if (filteredcartitems && filteredcartitems.length > 0) {
            if (filteredcartitems[0].inventory_Id === this.selectedInvId) {
              this.newcartQuantity = filteredcartitems[0].cart_Quantity;
            }
          }
          else {
            this.newcartQuantity = 0;
          }
        }
        else {
          this.newcartQuantity = 0;
        }
      }
    }
    this.onchangeSelectedIndexUpdate(index, false);
  }

  onchangeSelectedIndexUpdate(selectedindex, dored) {
    let pQlen = this.allProdDescResp['product_Qty_Details'].length;
    let selectedbtn: Element = document.getElementById("unitbtnid" + selectedindex);
    for (let i = 0; i < pQlen; i++) {

      if (selectedindex === i && selectedbtn) {
        if (dored) {
          selectedbtn.classList.remove('unit-butoon1');
          selectedbtn.classList.add('unit-butoon');
        }

      }
      else {
        let selectedOtherbtn: Element = document.getElementById("unitbtnid" + i);
        if (selectedOtherbtn) {
          selectedOtherbtn.classList.add('unit-butoon1');
          selectedOtherbtn.classList.remove('unit-butoon');
        }

      }
    }
  }
  openProductDesc(pid) {
    this.routerService.routeToViewProductDesc(pid);
  }

  onClickIncrement() {
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
    let selectedbtn: Element = document.getElementById("unitbtnid" + this.selectedIndex);
    if (this.stockAbailable === "In-Stock") {
      if (this.allCartItems && this.allCartItems.length > 0) {
        let filteredcartitems = this.allCartItems.filter(obj => obj.inventory_Id === this.selectedInvId);
        if (filteredcartitems && filteredcartitems.length > 0) {
          if (filteredcartitems[0]['deal_Of_Day_Item']) {
            this.cartService.openMaxQuantityAddedPopUp(1);
          }
          else {
            this.cartService.onClickIncrementNew(this.selectedInvId, filteredcartitems[0]);
            let updateqttobj = this.cartService.updateCartQuantityNew(filteredcartitems[0], "add");
            this.newcartQuantity = updateqttobj.cart_Quantity;
          }
        } else {
          let partObj = this.getObjOfSelectedInvID(this.selectedInvId);
          partObj["farm_Short_Name"] = this.allProdDescResp['farm_Short_Name'];
          partObj["product_Name"] = this.allProdDescResp['product_Name'];
          partObj["product_Image"] = this.allProdDescResp['product_Image'][0];
          partObj["deal_Price"] = this.allProdDescResp["deal_Price"];
          partObj["farm_Id"] = this.allProdDescResp['farm_Id'];
          this.cartService.addtoCart(partObj);
          this.newcartQuantity = partObj.cart_Quantity;
        }
      }
      else {
        let partObj = this.getObjOfSelectedInvID(this.selectedInvId);
        partObj["farm_Short_Name"] = this.allProdDescResp['farm_Short_Name'];
        partObj["product_Name"] = this.allProdDescResp['product_Name'];
        partObj["product_Image"] = this.allProdDescResp['product_Image'][0];
        partObj["deal_Price"] = this.allProdDescResp["deal_Price"];
        partObj["farm_Id"] = this.allProdDescResp['farm_Id'];
        this.cartService.addtoCart(partObj);
        this.newcartQuantity = partObj.cart_Quantity;
        this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
      }
    }
    else {
      this.openOutOfStockPopUp();
    }
    this.onchangeSelectedIndexUpdate(this.selectedIndex, true);
  }

  openOutOfStockPopUp() {
    const modalRef = this.modalService.open(AlertComponent,
      { size: 'sm', backdrop: 'static', centered: true });
    modalRef.componentInstance.msg = "This Product is Out-of-Stock";
  }

  onClickDecrement() {
    if (this.stockAbailable) {
      let cartitems = JSON.parse(window.localStorage.getItem("cartItemObj"));
      if (cartitems && cartitems.length > 0) {
        let filteredcartitems = cartitems.filter(obj => obj.inventory_Id === this.selectedInvId);
        if (filteredcartitems && filteredcartitems.length > 0) {
          this.cartService.onClickDecrementInProdDesc(filteredcartitems[0]);
          let updateqttobj = this.cartService.updateCartQuantityNew(filteredcartitems[0], "remove");
          this.newcartQuantity = updateqttobj.cart_Quantity;
        }
      }
      this.onchangeSelectedIndexUpdate(this.selectedIndex, true);
    }
  }

  getObjOfSelectedInvID(selectedinvid) {
    let selectedObj;
    for (let p of this.allProdDescResp['product_Qty_Details']) {
      if (p.inventory_Id === selectedinvid) {
        selectedObj = p;
      }
    }
    return selectedObj;
  }

  onclicksideimage(clcikedimg) {
    this.product_Image = clcikedimg;
  }

  onClickHome() {
    this.routerService.routeToHome();
  }

  onClickProductType(PType) {
    if (PType == "Vegetables") {
      this.routerService.routeToAllSubProduct("Vegetables", "All-Vegetables");
    } else if (PType == "Fruits") {
      this.routerService.routeToAllSubProduct("Fruits", "All-Fruits");
    }
    else if (PType == "Oils") {
      this.routerService.routeToAllSubProduct("Oils", "AllColdPressed-Oils");
    }
    else if (PType == "Flowers") {
      this.routerService.routeToAllSubProduct("Flowers", "All-Flowers&PotPlants");
    }
    else if (PType == "Dairy & Eggs") {
      this.routerService.routeToAllSubProduct("Grocery&Eggs", "All-Grocery&Eggs");
    }
    else if (PType == "Millets & Seeds") {
      this.routerService.routeToAllSubProduct("Millets&Seeds", "All-Millets&Seeds");
    }
  }

  onClickSubProductType(spType) {
    if (spType == "Fresh Vegetables") {
      this.routerService.routeToAllSubProduct("Vegetables", "Fresh-Vegetables");
    } else if (spType == "Exotic Vegetables") {
      this.routerService.routeToAllSubProduct("Vegetables", "Exotic-Vegetables");
    }
    else if (spType == "Herbs & Seasoning Vegetables") {
      this.routerService.routeToAllSubProduct("Vegetables", "Herbs&Seasoning-Vegetables");
    }
    else if (spType == "Organic Vegetables") {
      this.routerService.routeToAllSubProduct("Vegetables", "Organic-Vegetables");
    }
    else if (spType == "Fresh Fruits") {
      this.routerService.routeToAllSubProduct("Fruits", "Fresh-Fruits");
    }
    else if (spType == "Exotic Fruits") {
      this.routerService.routeToAllSubProduct("Fruits", "Exotic-Fruits");
    }
    else if (spType == "Cut Fruits") {
      this.routerService.routeToAllSubProduct("Fruits", "Cut-Fruits");
    }
    else if (spType == "Cold Pressed Oil") {
      this.routerService.routeToAllSubProduct("Oils", "ColdPressed-Oils");
    }
    else if (spType == "Grocery") {
      this.routerService.routeToAllSubProduct("Grocery&Eggs", "Grocery");
    }
    else if (spType == "Dairy & Eggs") {
      this.routerService.routeToAllSubProduct("Grocery&Eggs", "Dairy&Eggs");
    }
    else if (spType == "Biodegradable") {
      this.routerService.routeToAllSubProduct("Grocery&Eggs", "Biodegradable");
    }
    else if (spType == "ImmuneBooster Millets") {
      this.routerService.routeToAllSubProduct("Millets&Seeds", "ImmuneBoosterMillets");
    }
    else if (spType == "Seeds") {
      this.routerService.routeToAllSubProduct("Millets&Seeds", "Seeds");
    }
    else if (spType == "Roses Marigolds & Gerberas") {
      this.routerService.routeToAllSubProduct("Flowers", "Roses,Marigolds&Gerberas");
    }
    else if (spType == "Lilies & Caronations") {
      this.routerService.routeToAllSubProduct("Flowers", "Lilies&Caronations");
    }
    else if (spType == "Exotic Flowers") {
      this.routerService.routeToAllSubProduct("Flowers", "Exotic-Flowers");
    }
    else if (spType == "Premium Flowers") {
      this.routerService.routeToAllSubProduct("Flowers", "Premium-Flowers");
    }
    else if (spType == "Pot Plants") {
      this.routerService.routeToAllSubProduct("Flowers", "Pot-Plants");
    }
  }

  changedCartData() {
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  }

  public searchCall(data, isclose) {
    this.cartService.searchCall(data, isclose, "prodDescId");
  }
}
