import { Component, OnInit, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { RouterService } from '../services/router.service';
import { SharedmobService } from '../services/sharedmob.service';
import { HomeComponent } from '../home/home.component';
import { CartService } from '../services/cart.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SubProductComponent } from '../sub-product/sub-product.component';
import { MyOrdersComponent } from '../my-orders/my-orders.component';
import { EditprofileComponent } from '../editprofile/editprofile.component';
import { MyRatingsComponent } from '../my-ratings/my-ratings.component';
import { DeliveryLocationComponent } from '../delivery-location/delivery-location.component';
import { LocationService } from '../services/location.service';
import { ClearCartItemDialogComponent } from '../clear-cart-item-dialog/clear-cart-item-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../services/auth.service';
import { ProductsBySubTypeService } from '../services/products-by-sub-type.service';
import { RulesService } from '../services/rules.service';
import { ProductDescViewComponent } from '../product-desc-view/product-desc-view.component';

@Component({
  providers: [HomeComponent, MyOrdersComponent, EditprofileComponent, MyRatingsComponent, ProductDescViewComponent],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', './header.component1.css', './header.component2.css']
})


export class HeaderComponent implements OnInit {

  errMessage: string;

  searchDiv: boolean = false;
  // mainHome: boolean = true;

  events: any[] = [];
  mobileNumber: any;
  count: number;
  totalPrice: number;
  otp: number;
  isUserLogined: boolean = false;
  sessionuserid: any;
  searchKey: any = "";
  filteredAllProducts: any[] = [];
  allCartItems: any;
  previousitemdiv: string;
  // farmObj: any;
  headerCampaign: any;
  isSearchCartBar: boolean = false;
  disableSearch: boolean = false;
  locationsList: any;
  deliveryLocation: any;
  isLocationSelected: boolean = false;
  selectedLocation: string;
  isSeacrhBar: boolean = false;
  isSearch: boolean = false;
  rulesList: any;

  allProducts: any[] = [];

  deviceInfo = null;

  constructor(private dialog: MatDialog, private routerService: RouterService,
    private sharedService: SharedmobService, private sanitizer: DomSanitizer,
    private home: HomeComponent, private subp: SubProductComponent, 
    private prodDesc: ProductDescViewComponent,
    private cartService: CartService, private rulesService: RulesService,
    private myorder: MyOrdersComponent, private editprof: EditprofileComponent,
    private rating: MyRatingsComponent, private ngZone: NgZone,
    private loService: LocationService,
    private modalService: NgbModal, private authService: AuthService,
    private productsbysubtypeService: ProductsBySubTypeService) {
      
    this.sharedService.currentCount.subscribe(c => this.count = c);
    this.sharedService.currentTotalPrice.subscribe(tp => this.totalPrice = tp);
  }

  ngOnInit(): void {
    this.allCartItems = [];
    let allCartItemsFromSession = JSON.parse(window.localStorage.getItem("cartItemObj"));
    if (allCartItemsFromSession) {

      this.sharedService.currentCount.subscribe(c => this.count = c);
      this.sharedService.currentTotalPrice.subscribe(tp => this.totalPrice = tp);
      this.allCartItems = allCartItemsFromSession;
      
      this.cartService.getCartItemCountandPrice(this.allCartItems);
    } else {
      allCartItemsFromSession = [];
    }

    let delLoc = window.localStorage.getItem("delLoc");
    if (delLoc) {
      this.isLocationSelected = true;
      this.selectedLocation = delLoc;
    } else {
      this.getAllLocations();
    }
    this.mobileNumber = window.localStorage.getItem("userName");    
    this.sharedService.changeMobileNo(this.mobileNumber);
    let currentUrl = window.location.href;
    if (currentUrl) {
      if (currentUrl.indexOf('/view/cartcheckout') > -1) {
        this.isSearchCartBar = false;
        this.disableSearch = true;
      }
    }
    this.sessionuserid = window.localStorage.getItem("userId");
    if (this.sessionuserid) {
      this.isUserLogined = true;
    }

    this.sharedService.currentCount.subscribe(c => {
      this.ngZone.run(() => {
        this.count = c
      });
    });

    this.getHeaderCampaign();
  }
  getHeaderCampaign() {
    this.rulesService.getRules().subscribe(resp => {
      if (resp["header_Campaign"]) {
        this.rulesList = resp;
        window.localStorage.setItem("sCharges", JSON.stringify(this.rulesList));
        this.headerCampaign = resp["header_Campaign"];
      }
    }, err => {
      this.errMessage = "header Campaign not available";
    })
  }

  onClickSeacrhButton() {
    this.isSeacrhBar = true;

  }

  imageconvertor(data) {
    let objectURL = 'data:image/png;base64,' + data;
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }
  
  getAllSearchedProducts() {
    let searchedresp;
    this.productsbysubtypeService.searchAllProducts(this.selectedLocation, this.searchKey).subscribe(resp => {
      if (resp) {
        this.filteredAllProducts = [];
        searchedresp = resp;
        this.allProducts = [];
        for (let s of searchedresp) {
          this.allProducts.push(s);
          this.filteredAllProducts.push(s);
        }
        if (this.allCartItems && this.allCartItems.length > 0) {
          this.updateQtForAll('sp');
        }
      }
    }, err => {
      this.errMessage = " error in search";
    })
  }
  onChangeSearchEvent(event: any) {
    this.home.searchCall(event.target.value, false);
    this.subp.searchCall(event.target.value, false);
    this.prodDesc.searchCall(event.target.value, false);
    this.myorder.searchCall(event.target.value, false);
    this.editprof.searchCall(event.target.value, false);
    this.rating.searchCall(event.target.value, false);
    if(this.searchKey && this.searchKey.length==1){
      this.searchDiv = false;
      this.allProducts = [];
    }

    if (this.searchKey && this.searchKey.length > 2) {
      this.searchDiv = true;
      if (this.allProducts && this.allProducts.length == 0) {
        this.getAllSearchedProducts();
      }
      if (this.allProducts !== undefined && this.allProducts.length !== 0) {
        
        if (this.allProducts && this.allProducts.length < 2) {
          this.getAllSearchedProducts();
        }
        this.filteredAllProducts = [];
        this.filteredAllProducts = this.allProducts.filter(e =>
          e.product_Name.toLowerCase().search(this.searchKey.toLowerCase()) !== -1
          || e.farm_Name.toLowerCase().search(this.searchKey.toLowerCase()) !== -1
        );
      }
    }
    if (this.searchKey === '') {
      this.searchDiv = false;
      this.allProducts = [];
      this.filteredAllProducts = [];
    }
  }

  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '350px',
      data: {
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "Success") {
        let currentUrl = window.location.href;
        if (currentUrl) {
          if (currentUrl.indexOf('/view/cartcheckout') > -1) {
            this.routerService.routeToCheckOut();
          }
        }
        this.sessionuserid = window.localStorage.getItem("userId");
        if (this.sessionuserid) {
          this.isUserLogined = true;
          this.sharedService.currentMobile.subscribe(message => this.mobileNumber = message);
        }
      }
    });
  }
  onSearchClose() {
    this.searchDiv = false;
    // this.home.searchCall('', true);
    // this.subp.searchCall('', true);
    let currentUrl = window.location.href;
    if (currentUrl) {
      if (currentUrl.indexOf('/Home') > -1) {
        this.routerService.routeToHome();
      }
      if(currentUrl.indexOf('/Products/Vegetables/All-Vegetables') > -1){
        this.routerService.routeToAllSubProduct('Vegetables','All-Vegetables');
      }
      if(currentUrl.indexOf('/Products/Vegetables/Fresh-Vegetables') > -1){
        this.routerService.routeToAllSubProduct('Vegetables','Fresh-Vegetables');
      }
      if(currentUrl.indexOf('/Products/Vegetables/Exotic-Vegetables') > -1){
        this.routerService.routeToAllSubProduct('Vegetables','Exotic-Vegetables');
      }
      if(currentUrl.indexOf('/Products/Vegetables/Herbs&Seasoning-Vegetables') > -1){
        this.routerService.routeToAllSubProduct('Vegetables','Herbs&Seasoning-Vegetables');
      }
      if(currentUrl.indexOf('/Products/Vegetables/Organic-Vegetables') > -1){
        this.routerService.routeToAllSubProduct('Vegetables','Organic-Vegetables');
      }
      if(currentUrl.indexOf('/Products/Fruits/All-Fruits') > -1){
        this.routerService.routeToAllSubProduct('Fruits','All-Fruits');
      }
      if(currentUrl.indexOf('/Products/Fruits/Fresh-Fruits') > -1){
        this.routerService.routeToAllSubProduct('Fruits','Fresh-Fruits');
      }
      if(currentUrl.indexOf('/Products/Fruits/Exotic-Fruits') > -1){
        this.routerService.routeToAllSubProduct('Fruits','Exotic-Fruits');
      }
      if(currentUrl.indexOf('/Products/Olis/AllColdPressed-Oils') > -1){
        this.routerService.routeToAllSubProduct('Olis','AllColdPressed-Oils');
      }
      if(currentUrl.indexOf('/Products/Olis/ColdPressed-Oils') > -1){
        this.routerService.routeToAllSubProduct('Olis','ColdPressed-Oils');
      }
      if(currentUrl.indexOf('/Products/Grocery&Eggs/All-Grocery&Eggs') > -1){
        this.routerService.routeToAllSubProduct('Grocery&Eggs','All-Grocery&Eggs');
      }
      if(currentUrl.indexOf('/Products/Grocery&Eggs/Grocery') > -1){
        this.routerService.routeToAllSubProduct('Grocery&Eggs','Grocery');
      }
      if(currentUrl.indexOf('/Products/Grocery&Eggs/Dairy&Eggs') > -1){
        this.routerService.routeToAllSubProduct('Grocery&Eggs','Dairy&Eggs');
      }
      if(currentUrl.indexOf('/Products/Millets&Seeds/All-Millets&Seeds') > -1){
        this.routerService.routeToAllSubProduct('Millets&Seeds','All-Millets&Seeds');
      }
      if(currentUrl.indexOf('/Products/Millets&Seeds/ImmuneBoosterMillets') > -1){
        this.routerService.routeToAllSubProduct('Millets&Seeds','ImmuneBoosterMillets');
      }
      if(currentUrl.indexOf('/Products/Millets&Seeds/Seeds') > -1){
        this.routerService.routeToAllSubProduct('Millets&Seeds','Seeds');
      }
      if(currentUrl.indexOf('/Products/Flowers/All-Flowers&PotPlants') > -1){
        this.routerService.routeToAllSubProduct('Flowers','All-Flowers&PotPlants');
      }
      if(currentUrl.indexOf('/Products/Flowers/Roses,Marigolds&Gerberas') > -1){
        this.routerService.routeToAllSubProduct('Flowers','Roses,Marigolds&Gerberas');
      }
      if(currentUrl.indexOf('/Products/Flowers/Lilies&Caronations') > -1){
        this.routerService.routeToAllSubProduct('Flowers','Lilies&Caronations');
      }
      if(currentUrl.indexOf('/Products/Flowers/Exotic-Flowers') > -1){
        this.routerService.routeToAllSubProduct('Flowers','Exotic-Flowers');
      }
      if(currentUrl.indexOf('/Products/Flowers/Premium-Flowers') > -1){
        this.routerService.routeToAllSubProduct('Flowers','Premium-Flowers');
      }
      if(currentUrl.indexOf('/Products/Flowers/Pot-Plants') > -1){
        this.routerService.routeToAllSubProduct('Flowers','Pot-Plants');
      }
      if(currentUrl.indexOf('/editProfile') > -1){
        this.routerService.routeToEditProfile();
      }
      if(currentUrl.indexOf('/myOrders') > -1){
        this.routerService.routeToMyOrders();
      }
      if(currentUrl.indexOf('/rating') > -1){
        this.routerService.routeToRating();
      }
      if(currentUrl.indexOf('/viewProductDesc/') > -1){
        this.routerService.routeToHome();
      }
    }
    this.searchKey = "";
    this.isSeacrhBar = false;
  }

  claerSearchData() {
    this.routerService.routeToHome();
  }

  goToCart() {
    let allCartItemstogocart = JSON.parse(window.localStorage.getItem("cartItemObj"));
    if ((allCartItemstogocart).length > 0) {
      this.routerService.routeToAddToCart();
    }
  }
  selectDiv(divid) {
    this.addVisibilityToItem('p' + divid);
  }

  addVisibilityToItem(itemid) {
    this.cartService.hideItem(this.previousitemdiv, itemid);
    this.cartService.addVisibilityToItem(itemid);
    this.previousitemdiv = itemid;
  }

  updateQtForAll(type) {
    for (let item of this.allCartItems) {
      if (type === 'sp') {
        this.updateSearchcartQuantity(item);
      }
    }
  }

  updateSearchcartQuantity(item) {
    return this.cartService.updatecartQuantity(this.filteredAllProducts, item);
  }

  openAddtoCartPopup() {
    this.routerService.routeToAddToCart();
  }

  getAllLocations() {
    this.loService.getAllLocationsList().subscribe(resp => {
      this.locationsList = resp;
      if (resp) {
        window.localStorage.setItem("deliveryLocationList", JSON.stringify(this.locationsList));
        if (!this.selectedLocation) {
          this.openLocationPopUp();
        }
      }
    }, err => {
      this.errMessage = "Locations not available";
    })
  }

  changeLocation() {
    this.locationsList = JSON.parse(window.localStorage.getItem("deliveryLocationList"));
    this.openLocationPopUp();
  }

  openLocationPopUp(): void {

    const modalRef = this.modalService.open(DeliveryLocationComponent,
      { size: 'sm', backdrop: 'static', centered: true });
    modalRef.componentInstance.deliveryLocationList = this.locationsList;

    modalRef.result.then((resp) => {
      if (resp) {
        this.selectedLocation = resp;
        let delLoc = window.localStorage.getItem("delLoc");
        let cartitems = JSON.parse(window.localStorage.getItem("cartItemObj"));
        let cartitemcount = 0;
        if (cartitems) {
          cartitemcount = cartitems.length;
        } else {
          cartitems = [];
        }
        if (delLoc) {
          this.isLocationSelected = true;
          if (cartitemcount > 0) {
            if (this.selectedLocation !== delLoc)
              this.openToClearCartItemsPopUp(this.selectedLocation);
          } else {
            window.localStorage.setItem("delLoc", this.selectedLocation);
            this.routerService.routeToHome();
          }
        }
      }
    });
  }

  openToClearCartItemsPopUp(updatedloc) {
    this.selectedLocation = updatedloc;
    const modalRef = this.modalService.open(ClearCartItemDialogComponent,
      { size: 'sm', backdrop: 'static', centered: true });

    modalRef.result.then((resp) => {
      if (resp == "Yes") {
        window.localStorage.setItem("delLoc", this.selectedLocation);
        window.localStorage.removeItem("cartItemObj");
        this.cartService.clearAllData();
        this.sharedService.changeCount(0);
        this.sharedService.changeTotalPrice(0);
        this.routerService.routeToHome();
      }
      if (resp == "No") {
        this.selectedLocation = window.localStorage.getItem("delLoc");
      }
    });
  }

  logout() {
    this.authService.logout();
    this.isUserLogined = false;
    this.sharedService.changeCount(0);
    this.cartService.clearAllData();
    this.authService.stopRefreshTokenTimer();
    this.routerService.routeToHome();
  }

  openSideNav() {
    document.getElementById("myNav").style.width = "100%";
  }
}