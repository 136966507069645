import { Component } from '@angular/core';
import { F2HApiEndPointService } from './services/f2-hapi-end-point.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'allfresh';
  
  public static API_ENDPOINT = 'http://52.44.51.232:8082/inventory';
  public static AUTH_API_ENDPOINT = 'http://3.85.227.147:8087/lgn';
  public static RULE_API_ENDPOINT = 'http://52.44.51.232:8085/rule';
  public static INVENTORY_API_ENDPOINT = 'http://3.85.227.147:8084/order';
  public static USER_API_ENDPOINT = 'http://52.44.51.232:8086/user/';
  public static RATING_API_ENDPOINT = 'http://52.44.51.232:8091/preview/';

  public static LOCATION_API_ENDPOINT = 'http://52.44.51.232:8073/delilocation/';
  public static TOKEN_API_ENDPOINT = 'http://3.85.227.147:8087/lgin/';

  public static VIEW_INVOICE_API_ENDPOINT = 'http://52.44.51.232:8098/invoice/';
  
  //https://allfresh.co.in/#/ForApp (production url)
  public static APP_VIEW='http://javatechhub.com/allfresh/#/ForApp';

  fileContent:any;

  constructor(){ }

  
  ngOnInit(): void {   
  }

  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
}

  getLoginTimeDif():number {
    let logintime = window.localStorage.getItem("userlogintime");
    if (!logintime) {
      let ctime = new Date().getTime();
      window.localStorage.setItem("userlogintime", ctime + "");
    }
    let currentTime = new Date().getTime();
    let lastLoginTime: number = +logintime;
    let difTime = currentTime - lastLoginTime;
    let getloginTimediff = (Math.floor(difTime / 1000) / 60);
    return getloginTimediff;
  }

}

