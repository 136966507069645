import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SubProductComponent } from './sub-product/sub-product.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CartcheckoutComponent } from './cartcheckout/cartcheckout.component';
import { ShowCustOrderComponent } from './show-cust-order/show-cust-order.component';
import { EditprofileComponent } from './editprofile/editprofile.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { OutOfStockOrderItemsComponent } from './out-of-stock-order-items/out-of-stock-order-items.component';
import { AddToCartComponent } from './add-to-cart/add-to-cart.component';
import { MyRatingsComponent } from './my-ratings/my-ratings.component';
import { SeacrhComponent } from './seacrh/seacrh.component';
import { ProductDescViewComponent } from './product-desc-view/product-desc-view.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';


const routes: Routes = [
{ 
    path: '', 
    component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'Home', pathMatch: 'full'},
      { path: 'Home',component:HomeComponent},
      { path: 'Products/:mainmenutype/:subtype', component: SubProductComponent},
      { path: 'view/cartcheckout',component:CartcheckoutComponent},
      { path: 'editProfile', component: EditprofileComponent},
      { path: 'myOrders', component: MyOrdersComponent},
      { path: 'rating',component: MyRatingsComponent},
      { path: 'viewProductDesc/:pid', component: ProductDescViewComponent},
    ]
}, 
//no layout routes
{ path: 'login', component: LoginComponent},
{ path: 'view/showOrder',component:ShowCustOrderComponent},
{ path: 'view/addToCart',component:AddToCartComponent},
{ path: 'view/outOfStock',component:OutOfStockOrderItemsComponent},
{ path: 'search/:searchdata',component:SeacrhComponent},
{ path: 'viewInvoice',component:ViewInvoiceComponent},

// otherwise redirect to home
{ path: '**', redirectTo: '' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
