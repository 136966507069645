import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-clear-cart-item-dialog',
  templateUrl: './clear-cart-item-dialog.component.html',
  styleUrls: ['./clear-cart-item-dialog.component.css']
})
export class ClearCartItemDialogComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  OnClickYes() {
    this.activeModal.close("Yes");
  }

  OnClickNo() {
    this.activeModal.close("No");
  }
}
