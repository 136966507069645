import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-del-addr-dialog-msg',
  templateUrl: './select-del-addr-dialog-msg.component.html',
  styleUrls: ['./select-del-addr-dialog-msg.component.css']
})
export class SelectDelAddrDialogMSGComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  OnCLickOk() {
    this.activeModal.close("Success");
  }
}
