import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsBySubTypeService } from '../services/products-by-sub-type.service';
import { RouterService } from '../services/router.service';
import { CartService } from '../services/cart.service';
import { SharedmobService } from '../services/sharedmob.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-seacrh',
  templateUrl: './seacrh.component.html',
  styleUrls: ['./seacrh.component.css']
})
export class SeacrhComponent implements OnInit {

  errMessage: string;
  allProducts: any[] = [];
  @Input() filteredAllProducts;
  searchKey: any;
  allCartItems: any;

  searchDiv: boolean = false;

  events: any[] = [];
  mobileNumber: any;
  count: number;
  totalPrice: number;
  otp: number;
  isUserLogined: boolean = false;
  sessionuserid: any;
  previousitemdiv: string;
  headerCampaign: any;
  disableSearch: boolean = false;
  locationsList: any;
  deliveryLocation: any;
  isLocationSelected: boolean = false;
  selectedLocation: string;

  constructor(private route: ActivatedRoute,
    private routerService: RouterService,
    private cartService: CartService, private sharedService: SharedmobService,
    private deviceService: DeviceDetectorService) {

    this.sharedService.currentCount.subscribe(c => this.count = c);
    this.sharedService.currentTotalPrice.subscribe(tp => this.totalPrice = tp);

  }

  ngOnInit(): void {
    this.allCartItems = [];
    let allCartItemsFromSession = JSON.parse(window.localStorage.getItem("cartItemObj"));
    if (allCartItemsFromSession && allCartItemsFromSession.length > 0) {
      this.sharedService.currentCount.subscribe(c => this.count = c);
      this.sharedService.currentTotalPrice.subscribe(tp => this.totalPrice = tp);
      this.allCartItems = allCartItemsFromSession;
      this.cartService.getCartItemCountandPrice(allCartItemsFromSession);
      if (this.allCartItems && this.allCartItems.length > 0) {
      this.updateQtForAll();
      //this.filteredAllProducts =this.updatecartQuantityList(this.filteredAllProducts);
      this.updateSelectedProduct(0);
    }

    } else {
      allCartItemsFromSession = [];
    }
  }

  ngOnChanges() {
    console.log("filteredAllProducts.........in search...." + JSON.stringify(this.filteredAllProducts));
    // new changes
    // if(this.filteredAllProducts){      
    this.updateSelectedProduct(0);
    // }   
  }
  // Add to cart
  // openAddtoCartDialog(uniqueId, type, etype, sitem) {
  //   if (etype !== 'qn0') {
  //     if (sitem && sitem.cart_Quantity === 0) {
  //       this.cartService.addSearchItemtoCart(sitem);
  //     }
  //   }
  //   this.selectDiv(uniqueId);
  //   this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  // }

  // onClickIncrement(uniqueId, type, item) {
  //   this.cartService.onClickIncrement(uniqueId, type, item);
  //   this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  //   const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === item.inventory_Id);
  //   if (resultItem && resultItem.length == 1 && type === 'sp') {
  //     this.updateSearchcartQuantity(resultItem[0]);
  //   }
  // }
  // onClickDecrement(uniqueId, type, qtt, item) {
  //   this.cartService.onClickDecrement(uniqueId, type);
  //   this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  //   const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === item.inventory_Id);

  //   if (resultItem && resultItem.length == 1 && type === 'sp') {
  //     this.updateSearchcartQuantity(resultItem[0]);
  //   }
  //   if (resultItem && resultItem.length == 0 && type === 'sp') {
  //     item.cart_Quantity = 0;
  //     this.updateSearchcartQuantity(item);
  //   }

  //   if (qtt == 1) {
  //     this.cartService.hidecurrentItem('p' + uniqueId);
  //     // this.previousitemdiv = 'p' + uniqueId;
  //   }
  //   this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  // }

  // selectDiv(divid) {
  //   this.addVisibilityToItem('p' + divid);
  // }
  // addVisibilityToItem(itemid) {
  //   this.cartService.hideItem(this.previousitemdiv, itemid);
  //   this.cartService.addVisibilityToItem(itemid);
  //   this.previousitemdiv = itemid;
  // }

  // updateQtForAll(type) {
  //   for (let item of this.allCartItems) {
  //     if (type === 'sp') {
  //       this.updateSearchcartQuantity(item);
  //     }
  //   }
  // }

  claerSearchData() {
    this.routerService.routeToHome();
  }

  updateSearchcartQuantity(item) {
    this.filteredAllProducts= this.cartService.updatecartQuantity(this.filteredAllProducts, item);
  }

  changedCartData() {
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  }

  //new changes
  updateSelectedProduct(index) {
    console.log("this.filteredAllProducts....." + this.filteredAllProducts);
    for (let obj of this.filteredAllProducts) {
      console.log("obj.product_Qty_Details[index]....on loading...." + JSON.stringify(obj.product_Qty_Details[index]));
      let cartQtt = obj.product_Qty_Details[index].cart_Quantity;
      obj.product_Qty_Details[index]["selected"] = true;
      let selectedP = {
        inventory_Id: obj.product_Qty_Details[index].inventory_Id,
        sell_Price: obj.product_Qty_Details[index].sell_Price,
        allfresh_Price: obj.product_Qty_Details[index].allfresh_Price,
        discount_Percentage: obj.product_Qty_Details[index].discount_Percentage,
        cart_Quantity: cartQtt ? cartQtt : 0,
        stock_Avlbl: obj.product_Qty_Details[index].stock_Avlbl
      };
      obj["selected_inventory_Id"] = selectedP;
      console.log("new obj...." + JSON.stringify(obj["selected_inventory_Id"]));
     

    }
    
   
    if (this.allCartItems && this.allCartItems.length > 0) {
      this.updateQtForAll();
      this.filteredAllProducts =this.updatecartQuantityList(this.filteredAllProducts);
      
    }
  }

  updateQtForAll() {
    for (let item of this.allCartItems) {     
        this.updateTPcartQuantity(item);
    }
  }

  updateTPcartQuantity(item){
    this.filteredAllProducts = this.cartService.updatecartQuantity(this.filteredAllProducts,item);
  }
 

  updatecartQuantityList(pLIst) {
    return this.cartService.updateSubcartQuantity(pLIst);
  }

  onChangeProductquantyDesc(invid, index) {
    for (let obj of this.filteredAllProducts) {
      if (obj.inventory_Id === invid) {
        let cartQtt = obj.product_Qty_Details[index].cart_Quantity;
        let selectedP = {
          inventory_Id: obj.product_Qty_Details[index].inventory_Id,
          sell_Price: obj.product_Qty_Details[index].sell_Price,
          allfresh_Price: obj.product_Qty_Details[index].allfresh_Price,
          discount_Percentage: obj.product_Qty_Details[index].discount_Percentage,
          cart_Quantity: cartQtt ? cartQtt : 0,
          stock_Avlbl: obj.product_Qty_Details[index].stock_Avlbl
        };
        obj["selected_inventory_Id"] = selectedP;
        console.log("new obj...." + JSON.stringify(obj.selected_inventory_Id));
      }
      this.removeVisibilityItem(invid);
    }
  }

  openProductDescView(productid): void {
    this.routerService.routeToViewProductDesc(productid);
    // let currenturl = window.location.href;
    // let url = this.RemoveLastDirectoryPartOf(currenturl);
    // const finalurl = url + this.router.serializeUrl(
    //   this.router.createUrlTree(['/printInvoice'])
    // );
    // window.open(finalurl);
  }

  selectDiv(divid) {
    this.addVisibilityToItem("p" + divid);
  }

  addVisibilityToItem(itemid) {
    // this.cartService.hideItem(this.previousitemdiv, itemid);
    this.cartService.addVisibilityToItem(itemid);
    this.previousitemdiv = itemid;
  }

  removeVisibilityItem(itemid) {
    this.cartService.hideItem(this.previousitemdiv, itemid);
    // this.cartService.addVisibilityToItem(itemid);
    this.previousitemdiv = itemid;
  }

  // openAddtoCartDialog(uniqueId) {
  //   let sitem;
  //   for (let fpp of this.filteredAllProducts) {
  //     for (let pqt of fpp["product_Qty_Details"]) {
  //       if (uniqueId === pqt["inventory_Id"]) {
  //         if (pqt["stock_Avlbl"]) {
  //           sitem = pqt;
  //           sitem["farm_Short_Name"] = fpp['farm_Short_Name'];
  //           sitem["product_Name"] = fpp['product_Name'];
  //           sitem["product_Image"] = fpp['product_Image'];
  //           sitem["farm_Id"] = fpp["farm_Id"];
  //           if (fpp.selected_inventory_Id.cart_Quantity == 0) {
  //             this.cartService.addtoCart(sitem);
  //             this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "add");
  //           }
  //         }
  //       }
  //     }
  //   }
  //   this.selectDiv(uniqueId);
  //   this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  // }

  openAddtoCartDialogNew(uniqueId) {
    let sitem;    
    for (let fpp of this.filteredAllProducts) {
      for (let pqt of fpp["product_Qty_Details"]) {
        if (uniqueId === pqt["inventory_Id"]) {
          if(pqt["stock_Avlbl"]){
            sitem = pqt;
            sitem["farm_Short_Name"] = fpp["farm_Short_Name"];
            sitem["product_Name"] = fpp["product_Name"];
            sitem["product_Image"] = fpp["product_Image"][0];
            sitem["farm_Id"] = fpp["farm_Id"];
            if(fpp.selected_inventory_Id.cart_Quantity == 0){
              this.cartService.addtoCart(sitem);
              this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id,"add");
            }  
          }     
        }
      }
    }
    this.selectDiv(uniqueId);
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  }
  onClickIncrement(uniqueId, type, item) {
    for (let fpp of this.filteredAllProducts) {
      for (let pqt of fpp["product_Qty_Details"]) {
        if (uniqueId === pqt["inventory_Id"]) {
          this.cartService.onClickIncrementNew(uniqueId, pqt);
          this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "add");
          this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
          const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === pqt.inventory_Id);
          if (resultItem && resultItem.length == 1 && type === 'sp') {
            //this.updateSearchcartQuantity(resultItem[0]);
            this.filteredAllProducts = this.cartService.updateSubcartQuantityByDesc(
              this.filteredAllProducts,resultItem[0]
            );
          }
        }
      }
    }
    // this.cartService.onClickIncrement(uniqueId, type, item);
    // this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
    // const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === item.inventory_Id);
    // if (resultItem && resultItem.length == 1 && type === 'sp') {
    //   this.updateSearchcartQuantity(resultItem[0]);
    // }
  }
  onClickDecrement(uniqueId, type, qtt, item) {
    for (let fpp of this.filteredAllProducts) {
      for (let pqt of fpp["product_Qty_Details"]) {
        if (uniqueId === pqt["inventory_Id"]) {
          this.cartService.onClickDecrement(uniqueId, type);
          this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "remove");
          this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
          const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === pqt.inventory_Id);

          if (resultItem && resultItem.length == 1 && type === 'sp') {
            // this.updateSearchcartQuantity(resultItem[0]);
            this.filteredAllProducts = this.cartService.updateSubcartQuantityByDesc(
              this.filteredAllProducts,resultItem[0]
            );
          }
          if (resultItem && resultItem.length == 0 && type === 'sp') {
            pqt.cart_Quantity = 0;
            this.updateSearchcartQuantity(pqt);
            // this.filteredAllProducts = this.cartService.updateSubcartQuantityByDesc(
            //   this.filteredAllProducts,resultItem[0]
            // );
          }
        }
      }
    }
    // this.cartService.onClickDecrement(uniqueId, type);
    // this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
    // const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === item.inventory_Id);

    // if (resultItem && resultItem.length == 1 && type === 'sp') {
    //   this.updateSearchcartQuantity(resultItem[0]);
    // }
    // if (resultItem && resultItem.length == 0 && type === 'sp') {
    //   item.cart_Quantity = 0;
    //   this.updateSearchcartQuantity(item);
    // }

    if (qtt == 1) {
      this.cartService.hidecurrentItem('p' + uniqueId);
      // this.previousitemdiv = 'p' + uniqueId;
    }
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  }
}
