import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  rulesObj:any;

  constructor() { }

  ngOnInit(): void {
    this.rulesObj = JSON.parse(localStorage.getItem("sCharges"));
  }

  onClickPrivacyPolicy(){
    let privacyPolicyUrl = this.rulesObj['privacy_policy'];
    // let privacyPolicyUrl = "http://javatechhub.com/angular/assets/PolicyDoc/PrivacyPolicy.pdf";
    window.open(privacyPolicyUrl);
  }

  onClicyReturnPolicy(){
    let returnPolicyUrl = this.rulesObj['return_policy'];
    window.open(returnPolicyUrl); 
  }

  onClickTermsandCond(){
    let termsnCondUrl = this.rulesObj['terms_and_conditions'];
    window.open(termsnCondUrl);
  }

  onClickFAQ(){
    let faqUrl = this.rulesObj['faq'];
    window.open(faqUrl);
  }

  onClickBlog(){
    let blogUrl = this.rulesObj['blog'];
    window.open(blogUrl); 
  }
}
