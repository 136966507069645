import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RulesService {

  currentNameSubject$ = new BehaviorSubject("");
  rulesObj:any;
  bannersList:any;

  constructor(private httpClient: HttpClient) { }

  getRules(){
    return this.httpClient.get(AppComponent.RULE_API_ENDPOINT+'/getrules', {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.rulesObj = Resp;
      this.currentNameSubject$.next(this.rulesObj);
   }))
  }

  hoemBannerList(){
    return this.httpClient.get(AppComponent.RULE_API_ENDPOINT+'/getCarousel', {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.bannersList = Resp;
      this.currentNameSubject$.next(this.bannersList);
   }))
  }
}
