<div class="css-11zk6ke" id="checkoutmaind">
    <div class="card-group">
        <div class="card">
            <div *ngIf="isAddressExist">
                <h2 class="css-cardheader">Delivery Address</h2>
                <div class="collapse show">
                    <div class="card-body">
                        <div class="row">
                            <div class="card-group col-12 col-lg-4 col-md-12 col-sm-12 col-xs-12" style="padding-right: 0;padding-left: 0;">
                                <div class="card">
                                    <div class="card-body" *ngIf="isHomeAddrexist">
                                        <fieldset class="header-text addr-text" *ngIf="selectedLocation == city">
                                            <input id="homeAddress" type="radio" value="homeAddress" name="delAddress" (change)="onCHangeRadioDelAddress($event)" [checked]="isbuttonSelected"> Home Address
                                        </fieldset>
                                        <fieldset class="header-text addr-text" *ngIf="selectedLocation !== city" [disabled]="true">
                                            <input id="homeAddress" type="radio" value="homeAddress" name="delAddress" (change)="onCHangeRadioDelAddress($event)"> Home Address
                                        </fieldset>
                                        <div class="card-text addr-text" style="padding-top: 10px;">
                                            <b> {{fName}} {{lName}} </b>
                                        </div>
                                        <div class="card-text addr-text">
                                            <b> {{exstingUsermobileNumber}} </b>
                                        </div>
                                        <div class="card-text addr-text">
                                            {{house_No}}, {{apartment_Name}}
                                        </div>
                                        <div class="card-text addr-text">
                                            {{address1}},{{address2}}, {{landMark}}
                                        </div>
                                        <div class="card-text addr-text">
                                            {{city}} - {{pinCode}}
                                        </div>
                                        <div *ngIf="selectedLocation !== city" class="error-message" style="padding-top: 10px;">{{deliveryNotAvailableMsg}}</div>
                                        <div style="padding-top: 20px; text-align: left;">
                                            <button class="btn css-mybutton" *ngIf="selectedLocation == city" value="homeAddress" (click)="onCHangeRadioDelAddress($event)">Deliver Here</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class=" card-group col-12 col-lg-4 col-md-12 col-sm-12 col-xs-12" style="padding-right: 0;padding-left: 0;">
                                <div class="card">
                                    <div class="card-body" *ngIf="isOfficeAddrexist">
                                        <fieldset class="header-text addr-text" *ngIf="selectedLocation == ofcity">
                                            <input id="officeAddress" type="radio" value="officeAddress" name="delAddress" (change)="onCHangeRadioDelAddress($event)" [checked]="isOFbuttonSelected"> Office Address
                                        </fieldset>
                                        <fieldset class="header-text addr-text" *ngIf="selectedLocation !== ofcity" [disabled]="true">
                                            <input id="officeAddress" type="radio" value="officeAddress" name="delAddress" (change)="onCHangeRadioDelAddress($event)"> Office Address
                                        </fieldset>
                                        <div class="card-text addr-text" style="padding-top: 10px;">
                                            <b> {{fName}} {{lName}} </b>
                                        </div>
                                        <div class="card-text addr-text">
                                            <b> {{exstingUsermobileNumber}} </b>
                                        </div>
                                        <div class="card-text addr-text">
                                            {{ofhouse_No}}, {{ofapartment_Name}}
                                        </div>
                                        <div class="card-text addr-text">
                                            {{ofaddress1}},{{ofaddress2}}, {{oflandMark}}
                                        </div>
                                        <div class="card-text addr-text">
                                            {{ofcity}} - {{ofpinCode}}
                                        </div>
                                        <div *ngIf="selectedLocation !== ofcity" class="error-message" style="padding-top: 10px;">{{deliveryNotAvailableMsg}}</div>
                                        <div style="padding-top: 20px; text-align: left;">
                                            <button class="btn css-mybutton" *ngIf="selectedLocation == ofcity" value="officeAddress" (click)="onCHangeRadioDelAddress($event)">Deliver
                        Here</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class=" card-group col-12 col-lg-4 col-md-12 col-sm-12 col-xs-12" style="padding-right: 0;padding-left: 0;">
                                <div class="card">
                                    <div class="card-body" *ngIf="isOtherAddrexist">
                                        <fieldset class="header-text addr-text" *ngIf="selectedLocation == ocity">
                                            <input id="otherAddress" type="radio" value="otherAddress" name="delAddress" (change)="onCHangeRadioDelAddress($event)" [checked]="isObuttonSelected"> Other Address
                                        </fieldset>
                                        <fieldset class="header-text addr-text" *ngIf="selectedLocation !== ocity" [disabled]="true">
                                            <input id="otherAddress" type="radio" value="otherAddress" name="delAddress" (change)="onCHangeRadioDelAddress($event)"> Other Address
                                        </fieldset>
                                        <div class="card-text addr-text" style="padding-top: 10px;">
                                            <b> {{fName}} {{lName}} </b>
                                        </div>
                                        <div class="card-text addr-text">
                                            <b> {{exstingUsermobileNumber}} </b>
                                        </div>
                                        <div class="card-text addr-text">
                                            {{ohouse_No}}, {{oapartment_Name}}
                                        </div>
                                        <div class="card-text addr-text">
                                            {{oaddress1}},{{oaddress2}}, {{olandMark}}
                                        </div>
                                        <div class="card-text addr-text">
                                            {{ocity}} - {{opinCode}}
                                        </div>
                                        <div *ngIf="selectedLocation !== ocity" class="error-message" style="padding-top: 10px;">{{deliveryNotAvailableMsg}}</div>
                                        <div style="padding-top: 20px; text-align: left;">
                                            <button class="btn css-mybutton" *ngIf="selectedLocation == ocity" value="otherAddress" (click)="onCHangeRadioDelAddress($event)">Deliver
                        Here</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h2 class="css-cardheader">Contact Information</h2>
            <div class="collapse show">
                <div class="card-body">
                    <form [formGroup]="cartForm" (ngSubmit)="blockInventory()">
                        <div class="form-group">
                            <label class="labelfort required" for="mobileNumber">Mobile Number</label>
                            <input type="text" #mobno placeholder="Mobile No" maxlength="10" formControlName="mobileNumber" class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors}" autocomplete="disabled" />
                            <div *ngIf="submitted && f.mobileNumber.errors" class="invalid-feedback">
                                <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required</div>
                                <div *ngIf="f.mobileNumber.errors?.minlength">Number should be 10 digit</div>
                                <div *ngIf="f.mobileNumber.errors.pattern"> Please enter a valid Mobile Number</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="labelfort required" for="email">Email</label>
                            <input type="email" placeholder="Email" autocomplete="disabled" formControlName="email" class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f.email.errors}" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.pattern"> Please enter a valid email</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="labelfort required" for="firstName">First Name</label>
                                    <input type="text" placeholder="First Name" pattern="[a-zA-Z ]*" autocomplete="disabled" formControlName="firstName" class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors}" />
                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstName.errors.required">First Name is required</div>
                                        <div *ngIf="f.firstName.errors.pattern"> Please enter only chars.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="labelfort required" for="lastName">Last Name</label>
                                    <input type="text" placeholder="Last Name" pattern="[a-zA-Z ]*" autocomplete="disabled" formControlName="lastName" class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                        <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                                        <div *ngIf="f.lastName.errors.pattern"> Please enter only chars.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="labelfort required" for="houseNo">House Number</label>
                                    <input type="text" placeholder="House Number" pattern="^[A-Za-z0-9 . , /]+$" autocomplete="disabled" formControlName="houseNo" class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f.houseNo.errors }" />
                                    <div *ngIf="submitted && f.houseNo.errors" class="invalid-feedback">
                                        <div *ngIf="f.houseNo.errors.required">House No is required</div>
                                        <div *ngIf="f.houseNo.errors.pattern"> Please enter valid house number </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="labelfort required" for="apartmentName">Building Name</label>
                                    <input type="text" placeholder="Building Name" pattern="^[a-zA-Z0-9 !@#()_\-\[\]';:\\|,.\/]+$" autocomplete="disabled" formControlName="apartmentName" class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f.apartmentName.errors }"
                                    />
                                    <div *ngIf="submitted && f.apartmentName.errors" class="invalid-feedback">
                                        <div *ngIf="f.apartmentName.errors.required">Building Name is required</div>
                                        <div *ngIf="f.apartmentName.errors.pattern"> Please enter valid building name </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="labelfort required" for="address1">Address 1</label>
                            <input type="text" placeholder="Address 1" pattern="^[a-zA-Z0-9 !@#()_\-\[\]';:\\|,.\/]+$" autocomplete="disabled" formControlName="address1" class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" />
                            <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                                <div *ngIf="f.address1.errors.required">Address 1 is required</div>
                                <div *ngIf="f.address1.errors.pattern"> Please enter valid address </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="labelfort" for="address2">Address 2</label>
                            <input type="text" placeholder="Address 2" pattern="^[a-zA-Z0-9 !@#()_\-\[\]';:\\|,.\/]+$" autocomplete="disabled" formControlName="address2" class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" />
                            <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
                                <div *ngIf="f.address2.errors.pattern"> Please enter valid address </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="labelfort" for="landmark">Landmark</label>
                            <input type="text" placeholder="Landmark" pattern="^[A-Za-z0-9 . - / ,]+$" autocomplete="disabled" formControlName="landmark" class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f.landmark.errors }" />
                            <div *ngIf="submitted && f.landmark.errors" class="invalid-feedback">
                                <div *ngIf="f.landmark.errors.pattern"> Please enter valid land mark </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="labelfort required" for="city">City</label>
                            <input type="text" placeholder="City" autocomplete="disabled" pattern="[A-Za-z]*" formControlName="city" class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" readonly />
                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                <div *ngIf="f.city.errors.required">City is required</div>
                                <div *ngIf="f.city.errors.pattern"> Please enter valid city </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="labelfort required" for="Country">Country</label>
                                    <input type="text" placeholder="Country" class="form-control css-inputfont" value="INDIA" readonly />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="labelfort required" for="State">State</label>
                                    <input type="text" placeholder="State" class="form-control css-inputfont" formControlName="state" readonly />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="labelfort required" for="pincode">Pin Code</label>
                                    <ejs-dropdownlist id='ddlelement' #samples [dataSource]='data' [fields]='fields' [placeholder]='text' [allowFiltering]='true' (filtering)='onFiltering($event)' formControlName="pincode" [value]='value'></ejs-dropdownlist>

                                    <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                        <div *ngIf="f.pincode.errors.required">Pincode is required</div>
                                        <div *ngIf="f.pincode.errors?.minlength">Number should be 6 digit</div>
                                        <div *ngIf="f.pincode.errors.pattern"> Please enter only numbers </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="checkbox">
                                <label><input type="checkbox" formControlName="saveAddress" value="" class="css-inputfont">
                  Save this information for next time
                </label>
                            </div>
                        </div>
                        <div class="form-group" style="margin-bottom: 8%;">
                            <button class="btn css-mybutton" style="float: right;">Continue to Payment</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="card" style="margin-left: 10px;">
            <h2 class="css-cardheader">Shipping Product Information</h2>
            <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body" style="padding: 0">
                    <div>
                        <div class="css-mjrs0s">
                            <div class="css-vurnku">
                                <div class="row" *ngFor="let sessionCartItem of allCartItemFromSession" style="margin-left: 0;margin-right: 0;">
                                    <div class=" col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3 css-kgvnaj css-cartitemmdl" style="padding-right: 0">
                                        <div class="css-1kxr47f">
                                            <div class=" gatsby-image-wrapper" style="position: relative; overflow: hidden;">
                                                <div aria-hidden="true" style="width: 100%; padding-bottom: 100%;"></div>
                                                <img aria-hidden="true" [src]="sessionCartItem.product_Image" alt="" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; object-fit: cover; object-position: center center;">
                                            </div>
                                            <div class="css-shownumber" *ngIf='sessionCartItem.cart_Quantity>0'>
                                                <button class="css-pncr0q" title="item count">
                          {{sessionCartItem.cart_Quantity}}
                        </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-6 css-cartitemmdl" style="padding-left: 0;padding-right: 0;">
                                        <div class="css-vurnku">
                                            <div class="css-text css-1q9j665">{{sessionCartItem.farm_Short_Name}}</div>
                                            <div class="css-text css-1facnq0">{{sessionCartItem.product_Name}}</div>
                                            <div class="css-text css-1facnq0" *ngIf="!sessionCartItem.deal_Of_Day_Item">{{sessionCartItem.quantity_Desc}} Rs.{{sessionCartItem.allfresh_Price}}
                                            </div>
                                            <div class="css-text css-1facnq0" *ngIf="sessionCartItem.deal_Of_Day_Item">{{sessionCartItem.quantity_Desc}} Rs.{{sessionCartItem.allfresh_Price}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3 css-cartitemmdl" style="padding-left: 0;padding-right: 0;">
                                        <div class="" *ngIf="!sessionCartItem.deal_Of_Day_Item">
                                            Rs. {{sessionCartItem.cart_Quantity *sessionCartItem.allfresh_Price | number:'1.2-2' }}
                                        </div>
                                        <div class="" *ngIf="sessionCartItem.deal_Of_Day_Item">
                                            Rs. {{sessionCartItem.cart_Quantity *sessionCartItem.allfresh_Price | number:'1.2-2' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="css-mjrs0s-hd">
                            <div class="css-vurnku-hd">
                                <div class="row">
                                    <div class="col-7 col-xs-7 col-sm-7 col-md-8 col-lg-9">
                                        <div class="css-downhead">
                                            <div class="css-text1">Sub Total</div>
                                        </div>
                                    </div>

                                    <div class="col-5 col-xs-5 col-sm-5 col-md-4 col-lg-3">
                                        <div class="">
                                            Rs. {{subTotal | number:'1.2-2' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style="border-bottom-width: 1px;
                  border-bottom-style: solid;
                  border-bottom-color: rgb(230, 230, 230);">
                                    <div class="col-7 col-xs-7 col-sm-7 col-md-8 col-lg-9">
                                        <div class="css-downhead">
                                            <div class="css-text1">Shipping Charges</div>
                                        </div>
                                    </div>

                                    <div class="col-5 col-xs-5 col-sm-5 col-md-4 col-lg-3">
                                        <div class="">
                                            Rs. {{shippingCharge | number:'1.2-2' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-7 col-xs-7 col-sm-7 col-md-8 col-lg-9">
                                        <div class="css-downhead">
                                            <div class="css-text1">Total</div>
                                        </div>
                                    </div>

                                    <div class="col-5 col-xs-5 col-sm-5 col-md-4 col-lg-3">
                                        <div class="">
                                            Rs. {{totalCharges | number:'1.2-2'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" style="margin-bottom: 8%; margin-right: 4%;">
                        <button class="btn css-mybutton" style="float: right;" (click)="continueShopping()">Continue Shopping</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>