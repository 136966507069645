import { Component, OnInit, Inject, ViewEncapsulation, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delivery-location',
  templateUrl: './delivery-location.component.html',
  styleUrls: ['./delivery-location.component.css'],
  // encapsulation: ViewEncapsulation.None 
})
export class DeliveryLocationComponent implements OnInit {

  @Input() public deliveryLocationList;
  
  locationList: any;
  constructor(public activeModal: NgbActiveModal) {  }

  ngOnInit(): void {

    this.locationList = this.deliveryLocationList;
  }

  selectedLoc(locid) {
    let delLoc = localStorage.getItem("delLoc");
    if (!delLoc || (delLoc===locid)) {
      let delLocation = locid;
      localStorage.setItem("delLoc", delLocation);
    }
    this.activeModal.close(locid);

  }
}
