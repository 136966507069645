import { Component, OnInit } from '@angular/core';
import { MyRatingService } from '../services/my-rating.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CartService } from '../services/cart.service';
import { AppComponent } from '../app.component';
import { AuthService } from '../services/auth.service';
import { SharedmobService } from '../services/sharedmob.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-my-ratings',
  templateUrl: './my-ratings.component.html',
  styleUrls: ['./my-ratings.component.css']
})
export class MyRatingsComponent implements OnInit {

  hideSpinner: boolean = true;
  hideSpinner1: boolean = true;

  errMessage: string;
  ratingToBeProducts: any;
  ratedProductsResp: any;
  ratingProductsResp: any;

  constructor(private ratingService: MyRatingService, private sanitizer: DomSanitizer,
    private cartService: CartService, private appcomp: AppComponent, private dialog: MatDialog,
    private authService: AuthService, private sharedService: SharedmobService) { }

  ngOnInit(): void {
    this.productToBeRatedComp();
    this.productRated();
  }

  productToBeRatedComp() {
    let userId = window.localStorage.getItem("userId");
    if (userId) {
      let getloginTimediff = this.appcomp.getLoginTimeDif();
      if (getloginTimediff >= 14) {
        this.authService.refreshToken().subscribe(resp => {
          getloginTimediff = 1;
          if (resp['tkn_Status']) {
            let token = resp['token'];
            if (token) {
              this.authService.setBearerToken(token);
              let currentTime = new Date().getTime();
              window.localStorage.setItem("userlogintime", currentTime + "");
              this.getMyRatingProductTobe();
            } else {
              this.openLoginDialog(window.localStorage.getItem("mobileNumber"));
            }
          }
          else {
            this.openLoginDialog(window.localStorage.getItem("mobileNumber"));
          }
        },
          err => {
            this.openLoginDialog(window.localStorage.getItem("mobileNumber"));
          });
      }
      else {
        this.getMyRatingProductTobe();
      }
    }
  }
  getMyRatingProductTobe() {
    let userId = window.localStorage.getItem("userId");
    this.ratingService.productToBeRated(userId).subscribe(resp => {
      this.ratingToBeProducts = resp;
      this.hideSpinner = false;
    }, err => {
      this.errMessage = "data not available";
    })
  }
  openLoginDialog(mobNo): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '350px',
      data: {
        'mobileNumber': mobNo
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === "Success") {
        let logedinmobileNumber = window.localStorage.getItem("userName");
        this.sharedService.changeMobileNo(logedinmobileNumber);
        this.productToBeRatedComp();
      }
    });
  }

  rateCount(prodObj, id) {
    let rateproductReq = { "_id": id, "inventory_id": prodObj.inventory_Id, "review_Stars": prodObj.review_Stars };
    this.rateProduct(rateproductReq);
  }
  
  productRated() {
    let userId = window.localStorage.getItem("userId");
    if (userId) {
      let getloginTimediff = this.appcomp.getLoginTimeDif();
      if (getloginTimediff >= 14) {
        this.authService.refreshToken().subscribe(resp => {
          getloginTimediff = 1;
          if (resp['tkn_Status']) {
            let token = resp['token'];
            if (token) {
              this.authService.setBearerToken(token);
              let currentTime = new Date().getTime();
              window.localStorage.setItem("userlogintime", currentTime + "");
              this.getMyRatingRatedProduct();
            } else {
              this.openLoginDialog(window.localStorage.getItem("mobileNumber"));
            }
          }
          else {
            this.openLoginDialog(window.localStorage.getItem("mobileNumber"));
          }
        },
          err => {
            this.openLoginDialog(window.localStorage.getItem("mobileNumber"));
          });
      }
      else {
        this.getMyRatingRatedProduct();
      }
    }
  }

  getMyRatingRatedProduct() {
    let userId = window.localStorage.getItem("userId");
    this.ratingService.productRated(userId).subscribe(resp => {
      this.ratedProductsResp = resp;
      this.hideSpinner1 = false;
    }, err => {
      this.errMessage = "data not available";
    })
  }

  rateProduct(rateproductReq) {
    this.ratingService.rateProduct(rateproductReq).subscribe(resp => {
      this.ratingProductsResp = resp;
      this.productRated();
      this.productToBeRatedComp();
    }, err => {
      this.errMessage = "unable to give rating";
    })
  }

  imageconvertor(data) {
    let objectURL = 'data:image/png;base64,' + data;
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  public searchCall(data, isclose) {
    this.cartService.searchCall(data, isclose, 'myratingid');
  }
}
