<div class="css-1bcgouf">
    <div class="container css-19iiji0" id="homeid">
        <section class="home-courisal">
            <ngb-carousel *ngIf="homebnrList" class="css-ngb-courisal">
                <ng-template ngbSlide *ngFor="let bnr of homebnrList">
                    <img [src]="bnr.imageURL" alt="Random slide" class="css-ngb-courisal-img" (click)="onClickHomeCourisal(bnr)" />
                </ng-template>
            </ngb-carousel>
        </section>
        <section class="dealoftheday-section">
            <div class="css-vz0s8c-dod">
                <div class="dod-header">
                    <div class="row">
                        <img src="assets/images/DODTag.png" class="respnsive-img" />
                        <h4 class="responsive-dodheader">DEAL OF THE DAY</h4>
                        <label class="responsive-dod"> ({{ dodHeaderMsg }}) </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 custom-product" *ngFor="let dodproduct of DODProducts">
                        <div class="content_div">
                            <div id="showAddToCartButton" class="css-qiwenx" *ngIf="dodproduct.selected_inventory_Id.cart_Quantity == 0" (click)="openAddtoCartDialogNew(dodproduct.selected_inventory_Id.inventory_Id,'dodp')">
                                <button class="active css-pncr0q" title="Add to Cart">
                                    <svg width="13" height="12" viewBox="0 0 13 12">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                            transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="{{ dodproduct.selected_inventory_Id.inventory_Id }}" class="css-shownumber" *ngIf="dodproduct.selected_inventory_Id.cart_Quantity > 0" (click)="openAddtoCartDialogNew(dodproduct.selected_inventory_Id.inventory_Id,'dodp')">
                                <button class="active css-pncr0q" style="background-color:black" title="Add to Cart">
                                    {{ dodproduct.selected_inventory_Id.cart_Quantity }}
                                </button>
                            </div>
                            <div #incrDecrBtn id="p{{ dodproduct.selected_inventory_Id.inventory_Id }}" class="css-xupwym">
                                <button title="Decrement" class="css-110od4n" (click)="onClickDecrementNew(dodproduct.selected_inventory_Id.inventory_Id,'dodp',dodproduct.selected_inventory_Id.cart_Quantity,dodproduct)">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                                <div class="css-vurnku">
                                    {{ dodproduct.selected_inventory_Id.cart_Quantity }}
                                </div>
                                <button title="Increment" class="css-110od4n" (click)="onDODIncrement()">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7 8.8 0 16-7.2 16-16V272h96.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                            </div>
                            <div class="">
                                <img [src]="dodproduct.product_Image" id="icon" class="feature-product-img" (click)="openProductDescView(dodproduct._id)" />
                                <div class="content css-vurnku">
                                    <div class="css-10nf7hq">
                                        <span class="css-jrxylc">{{dodproduct.farm_Short_Name}}</span>
                                    </div>
                                    <h4 class="css-6tq2gk">
                                        {{ dodproduct.product_Name }} {{ dodproduct.product_Qty_Details[0].quantity_Desc }}
                                    </h4>
                                    <h4 class="css-6tq2gk">
                                        <!-- <span style="text-decoration-line: line-through;text-decoration-color: red;font-weight: 400">Rs.{{ dodproduct.allfresh_Price }}</span>
                                        <span style="margin-left: 7px;"><b>Rs.{{ dodproduct.deal_Price }}</b></span> -->
                                        <span style="text-decoration-line: line-through;text-decoration-color: red;font-weight: 400">Rs.{{dodproduct.selected_inventory_Id.sell_Price | number: "1.2-2"}}</span>
                                        <!-- <span style="margin-left: 7px;"><b>Rs.{{dodproduct.selected_inventory_Id.allfresh_Price | number: "1.2-2"}}</b></span> -->
                                        <span style="margin-left: 7px;"><b>Rs.{{ dodproduct.deal_Price }}</b></span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-center">
                    <mat-spinner *ngIf="hideSpinner"></mat-spinner>
                </div>
            </div>
        </section>
        <section class="jumbotrun-section" id="allp">
            <div class="css-vz0s8c1">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 card-holder" (click)="onClickVegSubProduct('Vegetables', 'All-Vegetables')">
                        <div class="card-container">
                            <div class="card-image">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600260597/All_vegetables_uvofss.jpg" alt="All Vegetables" srcset="" style="height: 100%; width: 100%;" />
                            </div>
                            <div class="card-text">
                                <h5 class="split-title">All Vegetables</h5>
                                <div class="textBtn">
                                    <span>View Items </span>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M85 277.375h259.704L225.002 397.077 256 427l171-171L256 85l-29.922 29.924 118.626 119.701H85v42.75z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 card-holder" (click)="onClickFruitsSubProduct('Fruits', 'All-Fruits')">
                        <div class="card-container">
                            <div class="card-image">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600260597/All_Fruits_kyk5y3.jpg" alt="all fruits" srcset="" style="height: 100%; width: 100%;" />
                            </div>
                            <div class="card-text">
                                <h5 class="split-title">All Fruits</h5>
                                <div class="textBtn">
                                    <span>View Items </span>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M85 277.375h259.704L225.002 397.077 256 427l171-171L256 85l-29.922 29.924 118.626 119.701H85v42.75z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 card-holder" (click)="onClickOilsSubProduct('Olis', 'AllColdPressed-Oils')">
                        <div class="card-container">
                            <div class="card-image">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600260597/All_Cold_Pressed_Oils_bh4hhr.jpg" alt="all oils" srcset="" style="height: 100%; width: 100%;" />
                            </div>
                            <div class="card-text">
                                <h5 class="split-title">All Cold Pressed Oils</h5>
                                <div class="textBtn">
                                    <span>View Items </span>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M85 277.375h259.704L225.002 397.077 256 427l171-171L256 85l-29.922 29.924 118.626 119.701H85v42.75z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 card-holder" (click)="onClickDairyAndEggsSubProduct('Grocery&Eggs', 'All-Grocery&Eggs')">
                        <div class="card-container">
                            <div class="card-image">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600260597/All_Grocery_Eggs_coqcfr.jpg" alt="all Dairy and Eggs" srcset="" style="height: 100%; width: 100%;" />
                            </div>
                            <div class="card-text">
                                <h5 class="split-title">All Grocery & Eggs</h5>
                                <div class="textBtn">
                                    <span>View Items </span>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M85 277.375h259.704L225.002 397.077 256 427l171-171L256 85l-29.922 29.924 118.626 119.701H85v42.75z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 card-holder" (click)="onClickMNSSubProduct('Millets&Seeds', 'All-Millets&Seeds')">
                        <div class="card-container">
                            <div class="card-image">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600260597/All_Millets_Seeds_npq5k7.jpg" alt="Millets and Seeds" srcset="" style="height: 100%; width: 100%;" />
                            </div>
                            <div class="card-text">
                                <h5 class="split-title">All Millets & Seeds</h5>
                                <div class="textBtn">
                                    <span>View Items </span>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M85 277.375h259.704L225.002 397.077 256 427l171-171L256 85l-29.922 29.924 118.626 119.701H85v42.75z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 card-holder" (click)="onClickFlowersSubProduct('Flowers', 'All-Flowers&PotPlants')">
                        <div class="card-container">
                            <div class="card-image">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600260597/All_Flowers_Pot_Plants_isotx6.jpg
                                        " alt="All flowers" srcset="" style="height: 100%; width: 100%;" />
                            </div>
                            <div class="card-text">
                                <h5 class="split-title">All Flowers & Pot Plants</h5>
                                <div class="textBtn">
                                    <span>View Items </span>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M85 277.375h259.704L225.002 397.077 256 427l171-171L256 85l-29.922 29.924 118.626 119.701H85v42.75z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="responsive-jumbotrun" style="padding-top: 20px;">
            <div class="css-vz0s8c1">
                <div class="row">
                    <div class="col-4 col-sm-4 col-xs-4 resp-card-holder" (click)="onClickVegSubProduct('Vegetables', 'All-Vegetables')">
                        <div class="responsive-card-containter">
                            <div class="responsive-card-image">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600271007/Mobile_View_-_Vegetables_wvgbmv.jpg" class="resp-img" alt="All Vegetables" srcset="" />
                            </div>
                            <div class="resp-card-text">
                                <span>Vegetables</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-sm-4 col-xs-4 resp-card-holder" (click)="onClickFruitsSubProduct('Fruits', 'All-Fruits')">
                        <div class="responsive-card-containter">
                            <div class="responsive-card-image">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600271007/Mobile_View_-_Fruits_hv4dc3.jpg" class="resp-img" alt="All Fruits" srcset="" />
                            </div>
                            <div class="resp-card-text">
                                <span>Fruits</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-sm-4 col-xs-4 resp-card-holder" (click)="onClickOilsSubProduct('Olis', 'AllColdPressed-Oils')">
                        <div class="responsive-card-containter">
                            <div class="responsive-card-image">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600271007/Mobile_View_-_Oils_ubje2m.jpg" class="resp-img" alt="All Oils" srcset="" />
                            </div>
                            <div class="resp-card-text">
                                <span>Cold Pressed Oils</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-sm-4 col-xs-4 resp-card-holder" (click)="onClickDairyAndEggsSubProduct('Grocery&Eggs', 'All-Grocery&Eggs')">
                        <div class="responsive-card-containter">
                            <div class="responsive-card-image">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600271007/Mobile_View_-_Dairy_h9qh00.jpg" class="resp-img" alt="All Dairy" srcset="" />
                            </div>
                            <div class="resp-card-text">
                                <span>Grocery And Eggs</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-sm-4 col-xs-4 resp-card-holder" (click)="onClickMNSSubProduct('Millets&Seeds', 'All-Millets&Seeds')">
                        <div class="responsive-card-containter">
                            <div class="responsive-card-image">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600271007/Mobile_View_-_Millets_duqvzp.jpg" class="resp-img" alt="All Millets and Seeds" srcset="" />
                            </div>
                            <div class="resp-card-text">
                                <span>Millets & Seeds</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-sm-4 col-xs-4 resp-card-holder" (click)="onClickFlowersSubProduct('Flowers', 'All-Flowers&PotPlants')">
                        <div class="responsive-card-containter">
                            <div class="responsive-card-image">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600271007/Mobile_View_-_Flowers_scjbkl.jpg" class="resp-img" alt="All Flowers" srcset="" />
                            </div>
                            <div class="resp-card-text">
                                <span>Flowers & Pot Plants</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="featured-product-section" #feature id="fp">
            <div class="css-vz0s8c">
                <h4 class="css_section_header">Featured Product</h4>
                <div class="row row-cols-5">
                    <div class="col custom-product" *ngFor="let fproduct of featuredProducts">
                        <div class="content_div">
                            <div id="showAddToCartButton" class="css-qiwenx" *ngIf="fproduct.selected_inventory_Id.cart_Quantity == 0 && fproduct.selected_inventory_Id.stock_Avlbl" (click)=" openAddtoCartDialogNew(fproduct.selected_inventory_Id.inventory_Id, 'fp') ">
                                <button class="active css-pncr0q " title="Add to Cart ">
                                    <svg width="13 " height="12 " viewBox="0 0 13 12 ">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z "
                                            transform="translate(-55.269 -64) " fill="#5a5a5a "></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="showAddToCartButton " class="css-qiwenx " *ngIf="fproduct.selected_inventory_Id.cart_Quantity==0 && !fproduct.selected_inventory_Id.stock_Avlbl " (click)="openOutOfStockPopUp()">
                                <button class="active css-pncr0q " title="Add to Cart ">
                                    <svg width="13 " height="12 " viewBox="0 0 13 12 ">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z "
                                            transform="translate(-55.269 -64) " fill="#5a5a5a "></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="{{ fproduct.selected_inventory_Id.inventory_Id }} " class="css-shownumber " *ngIf="fproduct.selected_inventory_Id.cart_Quantity> 0" (click)="openAddtoCartDialogNew(fproduct.selected_inventory_Id.inventory_Id,'fp')">
                                <button class="active css-pncr0q" style="background-color:black" title="Add to Cart">
                                    {{ fproduct.selected_inventory_Id.cart_Quantity }}
                                </button>
                            </div>
                            <div #incrDecrBtn id="p{{ fproduct.selected_inventory_Id.inventory_Id }}" class="css-xupwym">
                                <button title="Decrement" class="css-110od4n" (click)="onClickDecrementNew(fproduct.selected_inventory_Id.inventory_Id,'fp',fproduct.selected_inventory_Id.cart_Quantity,fproduct)">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                                <div class="css-vurnku">
                                    {{ fproduct.selected_inventory_Id.cart_Quantity }}
                                </div>
                                <button title="Increment" class="css-110od4n" (click)="onClickIncrementNew(fproduct.selected_inventory_Id.inventory_Id,'fp')">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7 8.8 0 16-7.2 16-16V272h96.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                            </div>
                            <div class="" id="p{{ fproduct.selected_inventory_Id.inventory_Id }}">
                                <img [src]="fproduct.product_Image[0]" id="icon" class="feature-product-img" (click)="openProductDescView(fproduct._id)" />
                                <div class="content css-vurnku">
                                    <div class="css-10nf7hq">
                                        <span class="css-jrxylc">{{fproduct.farm_Short_Name}}</span>
                                    </div>
                                    <h4 class="css-6tq2gk" style="height: 45px;">
                                        {{ fproduct.product_Name }}
                                    </h4>
                                    <div>
                                        <select class="form-control price-dropdown" (change)="onChangeProductquantyDesc(fproduct.inventory_Id,$event.target.value,'fp')" style="padding: 0;">
                                            <option *ngFor="let tppricedesc of fproduct.product_Qty_Details;index as j" [value]="j" [selected]="tppricedesc.inventory_Id === fproduct.selected_inventory_Id.inventory_Id">
                                                {{ tppricedesc.quantity_Desc }} - Rs.{{tppricedesc.allfresh_Price | number: "1.2-2"}}
                                            </option>
                                        </select>
                                        <div class="form-control" style="margin-top: 10px;height: auto;padding: 0;">
                                            <span class="css-down-dropdown-price">
                                                Rs.{{fproduct.selected_inventory_Id.allfresh_Price | number: "1.2-2"}}</span>
                                            <span class="css-down-dropdown-line" *ngIf="fproduct.selected_inventory_Id.allfresh_Price !== fproduct.selected_inventory_Id.sell_Price">Rs.{{fproduct.selected_inventory_Id.sell_Price | number: "1.2-2"}}
                                            </span>
                                            <span class="css-down-dropdown-discount">{{fproduct.selected_inventory_Id.discount_Percentage}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-center">
                    <mat-spinner *ngIf="fphideSpinner"></mat-spinner>
                </div>
            </div>
        </section>
        <section class="responsive-featured-product-section" #feature id="fp">
            <div class="css-vz0s8c">
                <h4 class="css_section_header">Featured Product</h4>
                <div class="row">
                    <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3 custom-product" *ngFor="let fproduct of featuredProducts">
                        <div class="content_div">
                            <div id="showAddToCartButton" class="css-qiwenx" *ngIf="fproduct.selected_inventory_Id.cart_Quantity == 0 && fproduct.selected_inventory_Id.stock_Avlbl" (click)=" openAddtoCartDialogNew(fproduct.selected_inventory_Id.inventory_Id, 'fp') ">
                                <button class="active css-pncr0q " title="Add to Cart ">
                                    <svg width="13 " height="12 " viewBox="0 0 13 12 ">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z "
                                            transform="translate(-55.269 -64) " fill="#5a5a5a "></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="showAddToCartButton " class="css-qiwenx " *ngIf="fproduct.selected_inventory_Id.cart_Quantity==0 && !fproduct.selected_inventory_Id.stock_Avlbl " (click)="openOutOfStockPopUp()">
                                <button class="active css-pncr0q " title="Add to Cart ">
                                    <svg width="13 " height="12 " viewBox="0 0 13 12 ">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z "
                                            transform="translate(-55.269 -64) " fill="#5a5a5a "></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="{{ fproduct.selected_inventory_Id.inventory_Id }} " class="css-shownumber " *ngIf="fproduct.selected_inventory_Id.cart_Quantity> 0" (click)="openAddtoCartDialogNew(fproduct.selected_inventory_Id.inventory_Id,'fp')">
                                <button class="active css-pncr0q" style="background-color:black" title="Add to Cart">
                                    {{ fproduct.selected_inventory_Id.cart_Quantity }}
                                </button>
                            </div>
                            <div #incrDecrBtn id="p{{ fproduct.selected_inventory_Id.inventory_Id }}" class="css-xupwym">
                                <button title="Decrement" class="css-110od4n" (click)="onClickDecrementNew(fproduct.selected_inventory_Id.inventory_Id,'fp',fproduct.selected_inventory_Id.cart_Quantity,fproduct)">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                                <div class="css-vurnku">
                                    {{ fproduct.selected_inventory_Id.cart_Quantity }}
                                </div>
                                <button title="Increment" class="css-110od4n" (click)="onClickIncrementNew(fproduct.selected_inventory_Id.inventory_Id,'fp')">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7 8.8 0 16-7.2 16-16V272h96.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                            </div>
                            <div class="" id="p{{ fproduct.selected_inventory_Id.inventory_Id }}">
                                <img [src]="fproduct.product_Image[0]" id="icon" class="feature-product-img" (click)="openProductDescView(fproduct._id)" />
                                <div class="content css-vurnku">
                                    <div class="css-10nf7hq">
                                        <span class="css-jrxylc">{{fproduct.farm_Short_Name}}</span>
                                    </div>
                                    <h4 class="css-6tq2gk" style="height: 45px;">
                                        {{ fproduct.product_Name }}
                                    </h4>
                                    <div>
                                        <select class="form-control price-dropdown" (change)="onChangeProductquantyDesc(fproduct.inventory_Id,$event.target.value,'fp')" style="padding: 0;">
                                            <option *ngFor="let tppricedesc of fproduct.product_Qty_Details;index as j" [value]="j" [selected]="tppricedesc.inventory_Id === fproduct.selected_inventory_Id.inventory_Id">
                                                {{ tppricedesc.quantity_Desc }} - Rs.{{tppricedesc.allfresh_Price | number: "1.2-2"}}
                                            </option>
                                        </select>
                                        <div class="form-control" style="margin-top: 10px;height: auto;padding: 0;">
                                            <span class="css-down-dropdown-price">
                                                Rs.{{fproduct.selected_inventory_Id.allfresh_Price | number: "1.2-2"}}</span>
                                            <span class="css-down-dropdown-line" *ngIf="fproduct.selected_inventory_Id.allfresh_Price !== fproduct.selected_inventory_Id.sell_Price">Rs.{{fproduct.selected_inventory_Id.sell_Price | number: "1.2-2"}}
                                            </span>
                                            <span class="css-down-dropdown-discount">{{fproduct.selected_inventory_Id.discount_Percentage}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-center">
                    <mat-spinner *ngIf="fphideSpinner"></mat-spinner>
                </div>
            </div>
        </section>
        <section class="trending-product-section" id="tp">
            <div class="css-vz0s8c2">
                <h4 class="css_section_header">Trending Product</h4>
                <div class="row row-cols-5">
                    <div class="col custom-product" *ngFor="let tproduct of trendingProducts">
                        <div class="content_div">
                            <div id="showAddToCartButton" class="css-qiwenx" *ngIf="tproduct.selected_inventory_Id.cart_Quantity == 0 && tproduct.selected_inventory_Id.stock_Avlbl" (click)="openAddtoCartDialogNew(tproduct.selected_inventory_Id.inventory_Id,'tp')">
                                <button class="active css-pncr0q" title="Add to Cart">
                                    <svg width="13" height="12" viewBox="0 0 13 12">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                            transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="showAddToCartButton" class="css-qiwenx" *ngIf="tproduct.selected_inventory_Id.cart_Quantity == 0 && !tproduct.selected_inventory_Id.stock_Avlbl" (click)="openOutOfStockPopUp()">
                                <button class="active css-pncr0q" title="Add to Cart">
                                    <svg width="13" height="12" viewBox="0 0 13 12">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                            transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="{{ tproduct.selected_inventory_Id.inventory_Id }}" class="css-shownumber" *ngIf="tproduct.selected_inventory_Id.cart_Quantity > 0" (click)="openAddtoCartDialogNew(tproduct.selected_inventory_Id.inventory_Id,'tp')">
                                <button class="active css-pncr0q" style="background-color:black" title="Add to Cart">
                                    {{ tproduct.selected_inventory_Id.cart_Quantity }}
                                </button>
                            </div>
                            <div #incrDecrBtn id="p{{ tproduct.selected_inventory_Id.inventory_Id }}" class="css-xupwym">
                                <button title="Decrement" class="css-110od4n" (click)="onClickDecrementNew(tproduct.selected_inventory_Id.inventory_Id,'tp',tproduct.selected_inventory_Id.cart_Quantity,tproduct)">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                                <div class="css-vurnku">
                                    {{ tproduct.selected_inventory_Id.cart_Quantity }}
                                </div>
                                <button title="Increment" class="css-110od4n" (click)="onClickIncrementNew(tproduct.selected_inventory_Id.inventory_Id,'tp')">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7 8.8 0 16-7.2 16-16V272h96.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                            </div>
                            <div id="p{{ tproduct.selected_inventory_Id.inventory_Id }}">
                                <img [src]="tproduct.product_Image[0]" id="icon" class="feature-product-img" (click)="openProductDescView(tproduct._id)" />
                                <div class="content css-vurnku">
                                    <div class="css-10nf7hq">
                                        <span class="css-jrxylc">{{tproduct.farm_Short_Name}}</span>
                                    </div>
                                    <h4 class="css-6tq2gk" style="height: 45px;">
                                        {{ tproduct.product_Name }}
                                    </h4>
                                    <div>
                                        <select class="form-control price-dropdown" (change)="onChangeProductquantyDesc(tproduct.inventory_Id,$event.target.value,'tp')" style="padding: 0;">
                                            <option *ngFor="let tppricedesc of tproduct.product_Qty_Details;index as j" [value]="j" [selected]="tppricedesc.inventory_Id === tproduct.selected_inventory_Id.inventory_Id">
                                                {{ tppricedesc.quantity_Desc }} - Rs.{{tppricedesc.allfresh_Price | number: "1.2-2"}}
                                            </option>
                                        </select>
                                        <div class="form-control" style="margin-top: 10px;height: auto;padding: 0;">
                                            <span class="css-down-dropdown-price">
                                                Rs.{{tproduct.selected_inventory_Id.allfresh_Price | number: "1.2-2"}}</span>
                                            <span class="css-down-dropdown-line" *ngIf="tproduct.selected_inventory_Id.allfresh_Price !== tproduct.selected_inventory_Id.sell_Price">Rs.{{tproduct.selected_inventory_Id.sell_Price | number: "1.2-2"}}
                                            </span>
                                            <span class="css-down-dropdown-discount">{{tproduct.selected_inventory_Id.discount_Percentage}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-center">
                    <mat-spinner *ngIf="tphideSpinner"></mat-spinner>
                </div>
            </div>
        </section>
        <section class="responsive-trending-product-section" id="tp">
            <div class="css-vz0s8c2">
                <h4 class="css_section_header">Trending Product</h4>
                <div class="row">
                    <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3 custom-product" *ngFor="let tproduct of trendingProducts">
                        <div class="content_div">
                            <div id="showAddToCartButton" class="css-qiwenx" *ngIf="tproduct.selected_inventory_Id.cart_Quantity == 0 && tproduct.selected_inventory_Id.stock_Avlbl" (click)="openAddtoCartDialogNew(tproduct.selected_inventory_Id.inventory_Id,'tp')">
                                <button class="active css-pncr0q" title="Add to Cart">
                                    <svg width="13" height="12" viewBox="0 0 13 12">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                            transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="showAddToCartButton" class="css-qiwenx" *ngIf="tproduct.selected_inventory_Id.cart_Quantity == 0 && !tproduct.selected_inventory_Id.stock_Avlbl" (click)="openOutOfStockPopUp()">
                                <button class="active css-pncr0q" title="Add to Cart">
                                    <svg width="13" height="12" viewBox="0 0 13 12">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                            transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="{{ tproduct.selected_inventory_Id.inventory_Id }}" class="css-shownumber" *ngIf="tproduct.selected_inventory_Id.cart_Quantity > 0" (click)="openAddtoCartDialogNew(tproduct.selected_inventory_Id.inventory_Id,'tp')">
                                <button class="active css-pncr0q" style="background-color:black" title="Add to Cart">
                                    {{ tproduct.selected_inventory_Id.cart_Quantity }}
                                </button>
                            </div>
                            <div #incrDecrBtn id="p{{ tproduct.selected_inventory_Id.inventory_Id }}" class="css-xupwym">
                                <button title="Decrement" class="css-110od4n" (click)="onClickDecrementNew(tproduct.selected_inventory_Id.inventory_Id,'tp',tproduct.selected_inventory_Id.cart_Quantity,tproduct)">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                                <div class="css-vurnku">
                                    {{ tproduct.selected_inventory_Id.cart_Quantity }}
                                </div>
                                <button title="Increment" class="css-110od4n" (click)="onClickIncrementNew(tproduct.selected_inventory_Id.inventory_Id,'tp')">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7 8.8 0 16-7.2 16-16V272h96.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                            </div>
                            <div id="p{{ tproduct.selected_inventory_Id.inventory_Id }}">
                                <img [src]="tproduct.product_Image[0]" id="icon" class="feature-product-img" (click)="openProductDescView(tproduct._id)" />
                                <div class="content css-vurnku">
                                    <div class="css-10nf7hq">
                                        <span class="css-jrxylc">{{tproduct.farm_Short_Name}}</span>
                                    </div>
                                    <h4 class="css-6tq2gk" style="height: 45px;">
                                        {{ tproduct.product_Name }}
                                    </h4>
                                    <div>
                                        <select class="form-control price-dropdown" (change)="onChangeProductquantyDesc(tproduct.inventory_Id,$event.target.value,'tp')" style="padding: 0;">
                                            <option *ngFor="let tppricedesc of tproduct.product_Qty_Details;index as j" [value]="j" [selected]="tppricedesc.inventory_Id === tproduct.selected_inventory_Id.inventory_Id">
                                                {{ tppricedesc.quantity_Desc }} - Rs.{{tppricedesc.allfresh_Price | number: "1.2-2"}}
                                            </option>
                                        </select>
                                        <div class="form-control" style="margin-top: 10px;height: auto;padding: 0;">
                                            <span class="css-down-dropdown-price">
                                                Rs.{{tproduct.selected_inventory_Id.allfresh_Price | number: "1.2-2"}}</span>
                                            <span class="css-down-dropdown-line" *ngIf="tproduct.selected_inventory_Id.allfresh_Price !== tproduct.selected_inventory_Id.sell_Price">Rs.{{tproduct.selected_inventory_Id.sell_Price | number: "1.2-2"}}
                                            </span>
                                            <span class="css-down-dropdown-discount">{{tproduct.selected_inventory_Id.discount_Percentage}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-center">
                    <mat-spinner *ngIf="tphideSpinner"></mat-spinner>
                </div>
            </div>
        </section>
        <section class="howitworks-section">
            <div class="css-vz0s8c3 text-center">
                <h3 class="css_howitworks">Why Choose allfresh?</h3>
                <!-- <div class="css_howitworks_text">Luxury goods straight from the same manufacturers as your favorite brands.</div> -->
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-3">
                        <img src="assets/images/howitworks/seletorder.svg" id="icon" class="img-fluid p-5" />
                        <h4 class="css_howitworksitem">
                            Freshly Harvested
                        </h4>
                        <p class="css-vurnku howitworks-p">
                            Our Farmers deliver their fresh products directly to allfresh daily
                        </p>
                    </div>

                    <div class="col-12 col-sm-12 col-md-4 col-lg-3">
                        <img src="assets/images/howitworks/preparingcartitem.svg" id="icon" class="img-fluid p-5" />
                        <h4 class="css_howitworksitem">
                            Save Time & Money
                        </h4>
                        <p class="css-vurnku howitworks-p">
                            Now you can shop & save your time with one-click in www.allfresh.co.in/ Android / iOS apps
                        </p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-3">
                        <img src="assets/images/howitworks/savetime&money.png" id="icon" class="img-fluid p-5" />
                        <h4 class="css_howitworksitem">Sorting & Grading</h4>
                        <p class="css-vurnku howitworks-p">
                            Our quality team does the sorting & grading of the products to ensure the highest quality reaches our customers
                        </p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-3">
                        <img src="assets/images/howitworks/deliveryhome.svg" id="icon" class="img-fluid p-5" />
                        <h4 class="css_howitworksitem">On Time Delivery</h4>
                        <p class="css-vurnku howitworks-p">
                            Our delivery partners deliver your products on time as committed
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="latest-product-section">
            <div class="css-vz0s8c4">
                <h4 class="css_section_header">Latest Product</h4>
                <div class="row row-cols-5">
                    <div class="col custom-product" *ngFor="let lproduct of latestProducts">
                        <div class="content_div">
                            <div id="showAddToCartButton" class="css-qiwenx" *ngIf="lproduct.selected_inventory_Id.cart_Quantity == 0 && lproduct.selected_inventory_Id.stock_Avlbl" (click)="openAddtoCartDialogNew(lproduct.selected_inventory_Id.inventory_Id,'lp')">
                                <button class="active css-pncr0q" title="Add to Cart">
                                    <svg width="13" height="12" viewBox="0 0 13 12">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                            transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="showAddToCartButton" class="css-qiwenx" *ngIf="lproduct.selected_inventory_Id.cart_Quantity == 0 && !lproduct.selected_inventory_Id.stock_Avlbl" (click)="openOutOfStockPopUp()">
                                <button class="active css-pncr0q" title="Add to Cart">
                                    <svg width="13" height="12" viewBox="0 0 13 12">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                            transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="{{ lproduct.selected_inventory_Id.inventory_Id }}" class="css-shownumber" *ngIf="lproduct.selected_inventory_Id.cart_Quantity > 0" (click)="openAddtoCartDialogNew(lproduct.selected_inventory_Id.inventory_Id,'lp')">
                                <button class="active css-pncr0q" style="background-color:black" title="Add to Cart">
                                    {{ lproduct.selected_inventory_Id.cart_Quantity }}
                                </button>
                            </div>
                            <div #incrDecrBtn id="p{{lproduct.selected_inventory_Id.inventory_Id }}" class="css-xupwym">
                                <button title="Decrement" class="css-110od4n" (click)="onClickDecrementNew(lproduct.selected_inventory_Id.inventory_Id,'lp',lproduct.selected_inventory_Id.cart_Quantity,lproduct)">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                                <div class="css-vurnku">
                                    {{ lproduct.selected_inventory_Id.cart_Quantity }}
                                </div>
                                <button title="Increment" class="css-110od4n" (click)="onClickIncrementNew(lproduct.selected_inventory_Id.inventory_Id,'lp')">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7 8.8 0 16-7.2 16-16V272h96.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                            </div>
                            <div id="p{{ lproduct.selected_inventory_Id.inventory_Id }}">
                                <img [src]="lproduct.product_Image[0]" id="icon" class="feature-product-img" (click)="openProductDescView(lproduct._id)" />
                                <div class="content css-vurnku">
                                    <div class="css-10nf7hq">
                                        <span class="css-jrxylc">{{lproduct.farm_Short_Name}}</span>
                                    </div>
                                    <h4 class="css-6tq2gk" style="height: 45px;">
                                        {{ lproduct.product_Name }}
                                    </h4>
                                    <div>
                                        <select class="form-control price-dropdown" (change)="onChangeProductquantyDesc(lproduct.inventory_Id,$event.target.value,'lp')" style="padding: 0;">
                                            <option *ngFor="let tppricedesc of lproduct.product_Qty_Details;index as j" [value]="j" [selected]="tppricedesc.inventory_Id === lproduct.selected_inventory_Id.inventory_Id">
                                                {{ tppricedesc.quantity_Desc }} - Rs.{{tppricedesc.allfresh_Price | number: "1.2-2"}}
                                            </option>
                                        </select>
                                        <div class="form-control" style="margin-top: 10px;height: auto;padding: 0px;">
                                            <span class="css-down-dropdown-price">
                                                Rs.{{lproduct.selected_inventory_Id.allfresh_Price | number: "1.2-2"}}</span>
                                            <span class="css-down-dropdown-line" *ngIf="lproduct.selected_inventory_Id.allfresh_Price !== lproduct.selected_inventory_Id.sell_Price">Rs.{{lproduct.selected_inventory_Id.sell_Price | number: "1.2-2"}}
                                            </span>
                                            <span class="css-down-dropdown-discount">{{lproduct.selected_inventory_Id.discount_Percentage}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-center">
                    <mat-spinner *ngIf="lphideSpinner"></mat-spinner>
                </div>
            </div>
        </section>
        <section class="responsive-latest-product-section">
            <div class="css-vz0s8c4">
                <h4 class="css_section_header">Latest Product</h4>
                <div class="row">
                    <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3 custom-product" *ngFor="let lproduct of latestProducts">
                        <div class="content_div">
                            <div id="showAddToCartButton" class="css-qiwenx" *ngIf="lproduct.selected_inventory_Id.cart_Quantity == 0 && lproduct.selected_inventory_Id.stock_Avlbl" (click)="openAddtoCartDialogNew(lproduct.selected_inventory_Id.inventory_Id,'lp')">
                                <button class="active css-pncr0q" title="Add to Cart">
                                    <svg width="13" height="12" viewBox="0 0 13 12">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                            transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="showAddToCartButton" class="css-qiwenx" *ngIf="lproduct.selected_inventory_Id.cart_Quantity == 0 && !lproduct.selected_inventory_Id.stock_Avlbl" (click)="openOutOfStockPopUp()">
                                <button class="active css-pncr0q" title="Add to Cart">
                                    <svg width="13" height="12" viewBox="0 0 13 12">
                                        <path
                                            d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                            transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                    </svg>
                                </button>
                            </div>
                            <div id="{{ lproduct.selected_inventory_Id.inventory_Id }}" class="css-shownumber" *ngIf="lproduct.selected_inventory_Id.cart_Quantity > 0" (click)="openAddtoCartDialogNew(lproduct.selected_inventory_Id.inventory_Id,'lp')">
                                <button class="active css-pncr0q" style="background-color:black" title="Add to Cart">
                                    {{ lproduct.selected_inventory_Id.cart_Quantity }}
                                </button>
                            </div>
                            <div #incrDecrBtn id="p{{lproduct.selected_inventory_Id.inventory_Id }}" class="css-xupwym">
                                <button title="Decrement" class="css-110od4n" (click)="onClickDecrementNew(lproduct.selected_inventory_Id.inventory_Id,'lp',lproduct.selected_inventory_Id.cart_Quantity,lproduct)">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                                <div class="css-vurnku">
                                    {{ lproduct.selected_inventory_Id.cart_Quantity }}
                                </div>
                                <button title="Increment" class="css-110od4n" (click)="onClickIncrementNew(lproduct.selected_inventory_Id.inventory_Id,'lp')">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 512 512" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7 8.8 0 16-7.2 16-16V272h96.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z">
                                        </path>
                                    </svg>
                                </button>
                            </div>
                            <div id="p{{ lproduct.selected_inventory_Id.inventory_Id }}">
                                <img [src]="lproduct.product_Image[0]" id="icon" class="feature-product-img" (click)="openProductDescView(lproduct._id)" />
                                <div class="content css-vurnku">
                                    <div class="css-10nf7hq">
                                        <span class="css-jrxylc">{{lproduct.farm_Short_Name}}</span>
                                    </div>
                                    <h4 class="css-6tq2gk" style="height: 45px;">
                                        {{ lproduct.product_Name }}
                                    </h4>
                                    <div>
                                        <select class="form-control price-dropdown" (change)="onChangeProductquantyDesc(lproduct.inventory_Id,$event.target.value,'lp')" style="padding: 0;">
                                            <option *ngFor="let tppricedesc of lproduct.product_Qty_Details;index as j" [value]="j" [selected]="tppricedesc.inventory_Id === lproduct.selected_inventory_Id.inventory_Id">
                                                {{ tppricedesc.quantity_Desc }} - Rs.{{tppricedesc.allfresh_Price | number: "1.2-2"}}
                                            </option>
                                        </select>
                                        <div class="form-control" style="margin-top: 10px;height: auto;padding: 0px;">
                                            <span class="css-down-dropdown-price">
                                                Rs.{{lproduct.selected_inventory_Id.allfresh_Price | number: "1.2-2"}}</span>
                                            <span class="css-down-dropdown-line" *ngIf="lproduct.selected_inventory_Id.allfresh_Price !== lproduct.selected_inventory_Id.sell_Price">Rs.{{lproduct.selected_inventory_Id.sell_Price | number: "1.2-2"}}
                                            </span>
                                            <span class="css-down-dropdown-discount">{{lproduct.selected_inventory_Id.discount_Percentage}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-center">
                    <mat-spinner *ngIf="lphideSpinner"></mat-spinner>
                </div>
            </div>
        </section>
        <section class="contactless_order-section">
            <div class="contactless_container">
                <header class="css-hsntkc">
                    <h1>Fast, Free Shipping,<br />Contactless Delivery.<br /></h1>
                    <p>Try it now, risk free!</p>
                    <a style="text-decoration: none;">
                        <button class="css-j5c32g" (click)="scrollToElementUp(feature)">
                            <span class="css-vurnku">Shop Now</span>
                            <svg width="15.394" height="10" viewBox="0 0 15.394 10">
                                <path
                                    d="M13.674,6.343,12.427,7.6l2.89,2.877-12.025.012,0,1.768,11.992-.012L12.444,15.1,13.7,16.343l4.988-5.012Z"
                                    transform="translate(-3.292 -6.343)"></path>
                            </svg>
                        </button>
                    </a>
                </header>
            </div>
        </section>
    </div>
    <!-- div for add to cart -->
    <app-cartbar [allCartItems]="allCartItems" (onCartChange)="changedCartData()"></app-cartbar>
</div>