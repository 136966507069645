import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class ProductsBySubTypeService {

  currentNameSubject$ = new BehaviorSubject("");
  vegProductsByType:any;
  vegProductsBySubType:any;
  friutProductsByType:any;
  fruitProductsBySubType:any;
  oilProductsByType:any;
  oilProductsBySubType:any;
  milletsandSeedsProductsByType:any;
  milletsandSeedsProductsBySubType:any;
  dairyProductsByType:any;
  dairyProductsBySubType:any;
  flowerProductsByType:any;
  flowerProductsBySubType:any;
  searchedProducts:any;
  
  constructor(private httpClient: HttpClient) { }

//Vegetables
  allVegetableProductsByType(delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsByTypeLocationEnhanced/'+delloc+'/V', {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.vegProductsByType = Resp;
      this.currentNameSubject$.next(this.vegProductsByType);
   }))
  }
  allSubProductsByType(mainType,delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsByTypeLocationEnhanced/'+delloc+'/' + mainType, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.vegProductsByType = Resp;
      this.currentNameSubject$.next(this.vegProductsByType);
   }))
  }

  allSubProductsBySubType(mainTypeData,subTypeData,delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsBySubTypeLocationEnhanced/'+ delloc +'/'+ mainTypeData +'/'+subTypeData, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.vegProductsBySubType = Resp;
      this.currentNameSubject$.next(this.vegProductsBySubType);
   }))
  }

  allVegetableProductsBySubType(subTypeData,delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsBySubTypeLocationEnhanced/'+ delloc +'/V/'+subTypeData, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.vegProductsBySubType = Resp;
      this.currentNameSubject$.next(this.vegProductsBySubType);
   }))
  }
  //Fruitss
  allFruitProductsByType(delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsByTypeLocationEnhanced/'+delloc+'/F', {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.friutProductsByType = Resp;
      this.currentNameSubject$.next(this.friutProductsByType);
   }))
  }

  allFruitProductsBySubType(subTypeData,delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsBySubTypeLocationEnhanced/'+ delloc +'/F/'+subTypeData, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.fruitProductsBySubType = Resp;
      this.currentNameSubject$.next(this.fruitProductsBySubType);
   }))
  }
  //Oils
  allOilProductsByType(delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsByTypeLocationEnhanced/'+delloc+'/O', {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.oilProductsByType = Resp;
      this.currentNameSubject$.next(this.oilProductsByType);
   }))
  }

  allOilProductsBySubType(subTypeData,delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsBySubTypeLocationEnhanced/'+ delloc +'/O/'+subTypeData, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.oilProductsBySubType = Resp;
      this.currentNameSubject$.next(this.oilProductsBySubType);
   }))
  }
  //Millets And Seeds
  allMilletsAndSeedsProductsByType(delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsByTypeLocationEnhanced/'+delloc+'/M', {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.milletsandSeedsProductsByType = Resp;
      this.currentNameSubject$.next(this.milletsandSeedsProductsByType);
   }))
  }

  allMilletsAndSeedsProductsBySubType(subTypeData,delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsBySubTypeLocationEnhanced/'+ delloc +'/M/'+subTypeData, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.milletsandSeedsProductsBySubType = Resp;
      this.currentNameSubject$.next(this.milletsandSeedsProductsBySubType);
   }))
  }
  //Dairy
  allDairyProductsByType(delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsByTypeLocationEnhanced/'+delloc+'/D', {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.dairyProductsByType = Resp;
      this.currentNameSubject$.next(this.dairyProductsByType);
   }))
  }

  allDairyProductsBySubType(subTypeData,delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsBySubTypeLocationEnhanced/'+ delloc +'/D/'+subTypeData, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.dairyProductsBySubType = Resp;
      this.currentNameSubject$.next(this.dairyProductsBySubType);
   }))
  }
  //Flowers
  allFlowerProductsByType(delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsByTypeLocationEnhanced/'+delloc+'/FL', {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.flowerProductsByType = Resp;
      this.currentNameSubject$.next(this.flowerProductsByType);
   }))
  }

  allFlowerProductsBySubType(subTypeData,delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getAllProductsBySubTypeLocationEnhanced/'+ delloc +'/FL/'+subTypeData, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.flowerProductsBySubType = Resp;
      this.currentNameSubject$.next(this.flowerProductsBySubType);
   }))
  }

  // searchAllProducts(delloc,searchdata){
  //   return this.httpClient.get(AppComponent.API_ENDPOINT+'/searchproducts/'+ delloc +'/' + searchdata, {
  //      headers: new HttpHeaders()
  //        .set('content-type', `application/json`)
  //    }).pipe(tap(Resp => {
  //     this.searchedProducts = Resp;
  //     this.currentNameSubject$.next(this.searchedProducts);
  //  }))
  // }

  searchAllProducts(delloc,searchdata){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/searchproductsEnhanced/'+ delloc +'/' + searchdata, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.searchedProducts = Resp;
      this.currentNameSubject$.next(this.searchedProducts);
   }))
  }
}
