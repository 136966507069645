import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  
  currentNameSubject$ = new BehaviorSubject("");
  inventoryObj:any;
  orderConfirmed:any;
  orderConfirmeddta;any;
  myObservableArray: Observable<any> = new Observable<any>();

  private isLoaded = new BehaviorSubject<boolean>(false);
  loaded = this.isLoaded.asObservable();
  cancelOrderResp:any;

  preOrderResp:any;

  constructor(private httpClient: HttpClient,private authService: AuthService) { }

  blockInventory(blockInventoryObjItems){
    const headers = new HttpHeaders()
      .set('content-type', `application/json`)
      .set('Authorization',`Bearer ${this.authService.getBearerToken()}`)
      .set('userid',window.localStorage.getItem("userId"))
      .set('Access-Control-Allow-Origin', '*');
    return this.httpClient.post(AppComponent.INVENTORY_API_ENDPOINT+'/blockinventory', blockInventoryObjItems, {
      'headers':headers
     }).pipe(tap(Resp => {
      this.inventoryObj = Resp;
      this.currentNameSubject$.next(this.inventoryObj);
   }))
  }

  preOrder(blockInventoryObjItems){
    const headers = new HttpHeaders()
      .set('content-type', `application/json`)
      .set('Authorization',`Bearer ${this.authService.getBearerToken()}`)
      .set('userid',window.localStorage.getItem("userId"))
      .set('Access-Control-Allow-Origin', '*');
    return this.httpClient.post(AppComponent.INVENTORY_API_ENDPOINT+'/POrder', blockInventoryObjItems, {
      'headers':headers
     }).pipe(tap(Resp => {
      this.preOrderResp = Resp;
      this.currentNameSubject$.next(this.preOrderResp);
   }))
  }

  // orderConfirmation(orderdata):Observable<any>{
  //   const headers = new HttpHeaders()
  //     .set('content-type', `application/json`)
  //     .set('Authorization',`Bearer ${this.authService.getBearerToken()}`)
  //     .set('userid',window.localStorage.getItem("userId"))
  //     .set('Access-Control-Allow-Origin', '*');
  //   return this.httpClient.post(AppComponent.INVENTORY_API_ENDPOINT+'/newOrder',orderdata, {
  //     'headers':headers
  //    }).pipe(tap(Resp => {
  //     this.orderConfirmed = Resp;
  //     this.isLoaded.next(true);
  //     this.currentNameSubject$.next(this.orderConfirmed);
  //  }))
  // }

  confirmOrder(orderdata):Observable<any>{
    const headers = new HttpHeaders()
      .set('content-type', `application/json`)
      .set('Authorization',`Bearer ${this.authService.getBearerToken()}`)
      .set('userid',window.localStorage.getItem("userId"))
      .set('Access-Control-Allow-Origin', '*');
    return this.httpClient.post(AppComponent.INVENTORY_API_ENDPOINT+'/confirmOrder',orderdata, {
      'headers':headers
     }).pipe(tap(Resp => {
      this.orderConfirmed = Resp;
      this.isLoaded.next(true);
      this.currentNameSubject$.next(this.orderConfirmed);
   }))
  }

  cancelOrder(orderId):Observable<any> {
    let cancelOrderObj = {"orderId": orderId };
    const headers = new HttpHeaders()
      .set('content-type', `application/json`)
      .set('Authorization',`Bearer ${this.authService.getBearerToken()}`)
      .set('userid',window.localStorage.getItem("userId"))
      .set('Access-Control-Allow-Origin', '*');
    return this.httpClient.post(AppComponent.INVENTORY_API_ENDPOINT+'/cancelorder',cancelOrderObj, {
      'headers':headers
     }).pipe(tap(Resp => {
      this.cancelOrderResp = Resp;
      this.currentNameSubject$.next(this.cancelOrderResp);
   }))
  }
}
