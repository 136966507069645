import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  constructor(public router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  routeToLogin() {
    this.router.navigate(['login']);

  }

  routeToDashboard() {
    this.router.navigate(['dashboard']);
  }

  routeToHome() {
    this.router.navigate(['Home']);
  }

  routeToAllSubProduct(menutype,subType) {
    this.router.navigate(['Products',menutype, subType]);
  }

  routeToFruitsSubProduct(fsubTypeData) {
    this.router.navigate(['Fruits', fsubTypeData]);
  }

  routeToFruitsAllSubProduct(fsubTypeData) {
    this.router.navigate(['Fruits', fsubTypeData]);
  }

  routeToOlisSubProduct(osubTypeData) {
    this.router.navigate(['Olis', osubTypeData]);
  }

  routeToOlisAllSubProduct(osubTypeData) {
    this.router.navigate(['Olis', osubTypeData]);
  }

  routeToDailrySubProduct(dsubTypeData) {
    this.router.navigate(['Grocery&Eggs', dsubTypeData]);
  }

  routeToDailryAllSubProduct(dsubTypeData) {
    this.router.navigate(['Grocery&Eggs', dsubTypeData]);
  }

  routeToMilletsAndSeedsSubProduct(mnssubTypeData) {
    this.router.navigate(['Millets&Seeds', mnssubTypeData]);
  }

  routeToMilletsAndSeedsAllSubProduct(mnssubTypeData) {
    this.router.navigate(['Millets&Seeds', mnssubTypeData]);
  }

  routeToFlowersSubProduct(flsubtypedata) {
    this.router.navigate(['Flowers', flsubtypedata]);
  }

  routeToFlowersAllSubProduct(flsubtypedata) {
    this.router.navigate(['Flowers', flsubtypedata]);
  }
  routeToCheckOut() {
    this.router.navigate(['view/cartcheckout']);
  }

  routeToShowOrder() {
    this.router.navigate(['view/showOrder']);
  }

  routeToOutOfStockItems() {
    this.router.navigate(['view/outOfStock']);
  }

  routeToAddToCart() {
    this.router.navigate(['view/addToCart']);
  }

  routeToEditProfile() {
    this.router.navigate(['editProfile']);
  }
  routeToMyOrders() {
    this.router.navigate(['myOrders']);
  }
  routeToRating() {
    this.router.navigate(['rating']);
  }

  routeToSearch(searchdata) {
    this.router.navigate(['search', searchdata]);
  }

  routeToViewProductDesc(pid) {
    this.router.navigate(['viewProductDesc', pid]);
  }

  routeToViewInvoice(){
    this.router.navigate(['viewInvoice']);
  }
}
