<div *ngIf="!isalert">
    <div class="card">
        <div class="card-body">
            <h2 class="card-text text-center">
                {{msg}}
            </h2>
        </div>
    </div>
</div>

<div *ngIf="isalert">
        <mat-spinner *ngIf="hideSpinner"></mat-spinner>
    <div class="card">
        <div class="card-body">
            <h2 class="card-text text-center">
                {{msg}}
            </h2>
        </div>
    </div>
</div>