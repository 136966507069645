import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { RouterService } from '../services/router.service';
import { SharedmobService } from '../services/sharedmob.service';

@Component({
  selector: 'app-cartbar',
  templateUrl: './cartbar.component.html',
  styleUrls: ['./cartbar.component.css']
})
export class CartbarComponent implements OnInit {

  @Input() allCartItems: any[];
  @Output() onCartChange: EventEmitter<any> = new EventEmitter<any>();

  count: number;
  totalPrice: number;

  constructor(private routerService: RouterService, 
    private sharedService: SharedmobService,private cdref: ChangeDetectorRef
    ) {
    this.sharedService.currentCount.subscribe(c => this.count = c);
    this.sharedService.currentTotalPrice.subscribe(tp => this.totalPrice = tp);
  }

  ngOnInit(): void {
    if(this.allCartItems && this.allCartItems.length !==0){
      this.onCartChange.emit(this.allCartItems);
    }   
  }

  openAddtoCartPopup() {
    this.routerService.routeToAddToCart();
  }

  ngAfterContentChecked(){
    if(!this.allCartItems){
      this.allCartItems =[];
    }
    this.cdref.detectChanges();
  }

}
