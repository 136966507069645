<div class="css-11zk6ke" *ngIf="isorderHistory" id="myorderid">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="card">
                <h2 class="card-header">My Orders
                    <a href="#" class="d-block pull-right show">
                        Home
                    </a>
                </h2>
                <h3 class="css-cardheader">Total number of Orders placed : {{orderHistoryResp.length}}</h3>
                <div class="card-body">
                    <strong *ngIf="cancelOrderMSG" class="success-message">{{cancelOrderMSG}}</strong>
                    <div class="row">
                        <div class="col-12" *ngIf="orderHistoryResp.length === 0">
                            <h4 class="card-text text-center">No Order found</h4>
                        </div>
                        <div class="col-12 col-lg-3 col-md-6 col-sm-12 col-xs-12" *ngFor="let delvaddress of orderHistoryResp;let i = index;">
                            <div class="card-group">
                                <div class="card css-orderbox">
                                    <div class="card-body">
                                        <div class="card-text">
                                            Order No:{{delvaddress._id}}
                                        </div>
                                        <div class="card-text">
                                            Order Status : {{delvaddress.orderDeliveryStatus?.order_Delivery_Status}}
                                        </div>
                                        <div class="card-text">
                                            Ordered On : {{delvaddress.order_Date}}
                                        </div>
                                        <div class="card-text">
                                            Order Amount : {{delvaddress.total_Order_Amount}}
                                        </div>
                                        <div class="card-text">
                                            Order Items : {{(delvaddress.orderItemDetails).length}}
                                        </div>
                                    </div>
                                    <div style="padding: 10px;">
                                        <button class="btn css-cancelbutton" (click)="onClickcancelOrder(delvaddress._id)" *ngIf="delvaddress.orderDeliveryStatus?.order_Delivery_Status=='Payment Recieved'">
                                            Cancel Order
                                        </button>
                                        <button class="btn css-viewInvoices" (click)="viewInvoices(delvaddress._id)" *ngIf="delvaddress.orderDeliveryStatus?.order_Delivery_Status=='Order Invoiced'">
                                            View Invoices
                                        </button>
                                        <button class="btn css-viewOrderbutton" (click)="switchtoViewOrder(i)">
                                            View Order
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <mat-spinner *ngIf="hideSpinner"></mat-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- order details-->
<div class="css-11zk6ke" *ngIf="isorderDetails">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="card">
                <h2 class="card-header">View Order</h2>
                <div class="card" style="margin: 0 auto;width:99%">
                    <h4 class="css-cardheader"> Order Details </h4>
                    <div id="collapse-personaldetails" class="collapse show" aria-labelledby="heading-personaldetails">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div class="card css-orderboxdetails">
                                        <div class="card-body">
                                            <div class="card-text">
                                                Order No: {{orderHistoryObj._id}}
                                            </div>
                                            <div class="card-text">
                                                Ordered On : {{orderHistoryObj.order_Date}}
                                            </div>
                                            <div class="card-text">
                                                Order Status : {{orderHistoryObj.orderDeliveryStatus?.order_Delivery_Status}}
                                            </div>

                                            <div class="card-text">
                                                Order Amount : {{orderHistoryObj.total_Order_Amount | number:'1.2-2'}}
                                            </div>
                                            <div class="card-text">
                                                Order Items : {{(orderHistoryObj.orderItemDetails)?.length}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div class="card css-orderboxdetails">
                                        <div class="card-body">
                                            <div class="card-text">
                                                House Number : {{orderHistoryObj.shippingDetails.house_No}}
                                            </div>
                                            <div class="card-text">
                                                Building Name : {{orderHistoryObj.shippingDetails.apartment_Name}}
                                            </div>
                                            <div class="card-text">
                                                Address1: {{orderHistoryObj.shippingDetails.address1}}
                                            </div>

                                            <div class="card-text">
                                                City: {{orderHistoryObj.shippingDetails.city}}
                                            </div>
                                            <div class="card-text">
                                                State : {{orderHistoryObj.shippingDetails.state}}, Pin Code : {{orderHistoryObj.shippingDetails.pinCode}}
                                            </div>
                                            <!-- <div class="card-text">
                                                Country : India
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div class="card css-orderboxdetails">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="card-text">
                                                        Delivered By :
                                                    </div>
                                                </div>
                                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="card-text">
                                                        Not yet delivered
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="card-text">
                                                        Delivered On :
                                                    </div>
                                                </div>
                                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="orderHistoryObj.orderDeliveryStatus?.order_Delivery_Status != 'Order Delivered'">
                                                    <div class="card-text">
                                                        Not yet delivered
                                                    </div>
                                                </div>
                                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="card-text" *ngIf="orderHistoryObj.orderDeliveryStatus?.order_Delivery_Status === 'Order Delivered'">
                                                        {{orderHistoryObj.orderDeliveryStatus?.order_Status_Date | date: 'dd/MM/yyyy'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="card-text">
                                                        Amount Paid :
                                                    </div>
                                                </div>
                                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="card-text">
                                                        {{orderHistoryObj.total_Order_Amount | number:'1.2-2'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="card-text">
                                                        Mode of Payment :
                                                    </div>
                                                </div>
                                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="card-text">
                                                        {{orderHistoryObj.paymentDetails?.payment_Gateway_Name}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="card-text">
                                                        Payment Date :
                                                    </div>
                                                </div>
                                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div class="card-text">
                                                        {{orderHistoryObj.paymentDetails?.payment_Date}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" style="margin: 0 auto;width:99%;margin-top: 10px;margin-bottom: 20px;">
                    <h4 class="card-header">
                        <a data-toggle="collapse" href="#collapse-da" aria-expanded="true" aria-controls="collapse-da" id="heading-da" class="d-block">
                            <i class="fa fa-chevron-down pull-right"></i> Order Items
                        </a>
                    </h4>
                    <div id="collapse-da" class="collapse show" aria-labelledby="heading-da">
                        <div class="card-body table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Sr no</th>
                                        <th>Item Description</th>
                                        <th>Unit Type</th>
                                        <th>Unit Price</th>
                                        <th class="text-center">Quantity</th>
                                        <th>Item Total</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let items of selectedorderItems;let cnt=index">
                                    <tr>
                                        <td>{{cnt+1}}</td>
                                        <td>{{items.farm_Short_Name}} {{items.product_Name}} {{items.quantity_Desc}}</td>
                                        <td>{{items.units}}</td>
                                        <td>Rs.{{items.item_Price | number:'1.2-2'}}</td>
                                        <td class="text-center">{{items.item_Quantity}}</td>
                                        <td>Rs.{{items.item_Amount | number:'1.2-2'}}</td>
                                    </tr>

                                </tbody>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Item Total</td>
                                        <td colspan="2">Rs.{{orderHistoryObj.total_Item_Amount | number:'1.2-2'}}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Delivery Charges</td>
                                        <td>Rs.{{orderHistoryObj.delivery_Charges | number:'1.2-2'}}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Total</td>
                                        <td>Rs.{{orderHistoryObj.total_Order_Amount | number:'1.2-2'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div style="margin-bottom: 20px;">
                    <button class="btn css-mybutton pull-right" style="margin-right: 6px;" (click)="cancelEditAddress()">Go
                        Back</button>
                </div>
            </div>
        </div>
    </div>
</div>