import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DealOfTheDayService {
  
  dodproductResp:any;
  currentNameSubject$ = new BehaviorSubject("");
  
  constructor(private httpClient: HttpClient) { }

  allDODProducts(delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getDealOfTheDayEnhanced/'+delloc, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.dodproductResp = Resp;
      this.currentNameSubject$.next(this.dodproductResp);
   }))
  }
}
