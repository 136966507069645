<header class="header-section">
    <!-- <nav class="navbar navbar-expand-lg navbar-light bg-light navbar-default fixed-top"> -->
    <nav class="navbar-expand-lg navbar-light bg-light navbar-default fixed-top">
        <div class="row" style="width: 100%;">
            <div class="col-md-2 col-lg-2">
                <button class="navbar-toggler pull-left custom-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <a class="navbar-brand logo-rightmargin" href="#" style="margin-left: 0.5rem;">
                    <!-- <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600260597/All_fresh_HD_LOGO_m4brqi.png"
                        height="70px" width="200px"> -->
                    <img src="assets/images/AllfreshBrandLogo.png" height="70px" width="200px">
                </a>
            </div>
            <div class="col-md-10 col-lg-10">
                <div class="row css-offerbar">
                    <div class="col-lg-10 col-md-10">
                        <div class=" text-center">
                            {{headerCampaign}}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2">
                        <ul class="navbar-nav mr-auto" style="cursor: pointer;float: right;">
                            <li class="dropdown-toggle css-dlvloc" (click)="changeLocation()" *ngIf="!isLocationSelected">
                                Choose Location
                            </li>
                            <li class="dropdown-toggle css-dlvloc" (click)="changeLocation()" *ngIf="isLocationSelected">
                                {{selectedLocation}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row" style="margin-left: 0;">
                    <div style="width: 62%;">
                        <div class="collapse navbar-collapse" id="navbarSupportedContent" style="flex-grow: 0;background-color: #6db500;margin-right: 5px;border-radius: 5px;">
                            <ul class="navbar-nav mr-auto" style="align-items: center;height: 40px;">
                                <li class="nav-item dropdown cust-dropdown" style="margin-right: 15px;margin-left: 6px;">
                                    <a class="header-a" [routerLink]="['/Home']">Home</a>
                                </li>
                                <li class="nav-item dropdown cust-dropdown">
                                    <a class="dropdown-toggle header-a" href="#" id="navbardrop" data-toggle="dropdown">
                                        Vegetables
                                    </a>
                                    <div class="dropdown-menu cust-dropdown-menu">
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Vegetables','All-Vegetables']" routerLinkActive="active-link">All
                                                Vegetables</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Vegetables','Fresh-Vegetables']">Fresh
                                            Vegetables</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Vegetables','Exotic-Vegetables']">Exotic
                                            Vegetables</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Vegetables','Herbs&Seasoning-Vegetables']">Herbs
                                            & Seasoning</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Vegetables','Organic-Vegetables']">Organic
                                            Vegetables</a>
                                    </div>
                                </li>
                                <li class="nav-item dropdown cust-dropdown">
                                    <a class="dropdown-toggle header-a" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Fruits
                                    </a>
                                    <div class="dropdown-menu cust-dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Fruits','All-Fruits']" routerLinkActive="active-link">All Fruits</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Fruits','Fresh-Fruits']">Fresh
                                            Fruits</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Fruits','Exotic-Fruits']">Exotic
                                            Fruits</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Fruits','Cut-Fruits']">Cut
                                            Fruits</a>
                                    </div>
                                </li>
                                <li class="nav-item dropdown cust-dropdown">
                                    <a class="dropdown-toggle header-a" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Oils
                                    </a>
                                    <div class="dropdown-menu cust-dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Oils','AllColdPressed-Oils']" routerLinkActive="active-link">All
                                            Cold Pressed Oils</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Oils','ColdPressed-Oils']">Cold
                                            Pressed
                                            Oils</a>
                                    </div>
                                </li>
                                <li class="nav-item dropdown cust-dropdown">
                                    <a class="dropdown-toggle header-a" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Grocery & Eggs
                                    </a>
                                    <div class="dropdown-menu cust-dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Grocery&Eggs','All-Grocery&Eggs']" routerLinkActive="active-link">All Grocery & Eggs</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Grocery&Eggs','Grocery']">Grocery</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Grocery&Eggs','Dairy&Eggs']">Dairy
                                            &
                                            Eggs</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Grocery&Eggs','Biodegradable']">Biodegradable</a>
                                    </div>
                                </li>
                                <li class="nav-item dropdown cust-dropdown">
                                    <a class="dropdown-toggle header-a" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Millets & Seeds
                                    </a>
                                    <div class="dropdown-menu cust-dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Millets&Seeds','All-Millets&Seeds']" routerLinkActive="active-link">All
                                            Millets & Seeds</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Millets&Seeds','ImmuneBoosterMillets']">Immune
                                            Booster Millets</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Millets&Seeds','Seeds']">Seeds</a>
                                    </div>
                                </li>
                                <li class="nav-item dropdown cust-dropdown">
                                    <a class="dropdown-toggle header-a" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Flowers
                                    </a>
                                    <div class="dropdown-menu cust-dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Flowers','All-Flowers&PotPlants']" routerLinkActive="active-link">All Flowers & Pot Plants</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Flowers','Roses,Marigolds&Gerberas']">Roses,
                                            Marigolds & Gerberas</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Flowers','Lilies&Caronations']">Lilies
                                            & Caronations</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Flowers','Exotic-Flowers']">Exotic
                                            Flowers</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Flowers','Premium-Flowers']">Premium
                                            Flowers</a>
                                        <a class="dropdown-item cust-dropdown-item" [routerLink]="['/Products','Flowers','Pot-Plants']">Pot
                                            Plants</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div style="width: 21%;">
                        <div class="topbar-search css-vurnku ml-auto">
                            <div class="picksySearch css-vurnku">
                                <label class="search css-5w9m11" style="border-style: solid;border-width: 1px;border-color: #6db500;border-radius: 7px;">
                                    <svg class="search-icon" width="16" height="16" viewBox="0 0 16 16">
                                        <path d="M16.122,12.452a6.772,6.772,0,1,0-1.195,1.2l.036.038,3.592,3.592a.847.847,0,0,0,1.2-1.2L16.16,12.488ZM14.364,4.706a5.079,5.079,0,1,1-7.183,0A5.079,5.079,0,0,1,14.364,4.706Z"
                                            transform="translate(-4 -1.525)" fill-rule="evenodd"></path>
                                    </svg>
                                    <input type="text" [disabled]="disableSearch" name="search" [(ngModel)]="searchKey"
                                        (input)="onChangeSearchEvent($event)" placeholder="Search All Fresh Products .."
                                        autocomplete="off" value="">
                                    <svg class="close-icon" (click)="onSearchClose()" width="14" height="14" viewBox="0 0 14 14">
                                        <path d="M6.115,4.792A.936.936,0,0,0,4.792,6.115l5.4,5.4-5.4,5.4a.936.936,0,0,0,1.323,1.323l5.4-5.4,5.4,5.4a.936.936,0,0,0,1.323-1.323l-5.4-5.4,5.4-5.4a.936.936,0,1,0-1.323-1.323l-5.4,5.4Z"
                                            transform="translate(-4.518 -4.518)"></path>
                                    </svg>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div style="width: 17%;">
                        <div style="background-color: #6db500;height: 40px;align-items: center;margin-left: 3%;border-radius: 5px;">
                            <a class="cart-leftmargin text-center" (click)="goToCart()" style="cursor: pointer">
                                <img src="assets/images/BasketIcon.png" class="css-cart-image">
                                <span *ngIf="count != 0" class="cart-span">{{count}}</span>
                            </a>
                            <div class="dropdown" style="cursor: pointer;">
                                <a title="login" class="css-login-botton" *ngIf="!isUserLogined" (click)="openLoginDialog()">
                                    <svg class="bi bi-person-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    </svg>
                                    <span class="">Log In/Sign Up</span>
                                </a>
                                <a title="login" class="css-login-botton" *ngIf="isUserLogined">
                                    <svg class="bi bi-person-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    </svg>
                                </a>
                                <span *ngIf="isUserLogined" style="color: white;">
                                    {{mobileNumber.substring(0,10)}}
                                </span>
                                <div class="dropdown-content" *ngIf="isUserLogined">
                                    <a class="dropdown-item cust-dropdown-item" [routerLink]="['/editProfile']" routerLinkActive="active-link">
                                        My Profile</a>
                                    <a class="dropdown-item cust-dropdown-item" [routerLink]="['/myOrders']">My Orders</a>
                                    <a class="dropdown-item cust-dropdown-item" [routerLink]="['/rating']">My Rating</a>
                                    <a class="dropdown-item cust-dropdown-item" (click)="logout()" style="cursor: pointer;">Logout</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</header>
<!-- header for responsive -->
<header class="header-responsive">
    <div>
        <div class="resp-css-offerbar text-center fixed-top bg-light">
            {{headerCampaign}}
        </div>
    </div>
    <div>
        <nav class=" navbar navbar-expand-lg navbar-light bg-light navbar-default fixed-top cust-navbar">
            <button class="navbar-toggler pull-left custom-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a href="#">
                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600260597/All_fresh_HD_LOGO_m4brqi.png" height="65px" width="180px">
            </a>
            <a title="cart" class="cart-leftmargin-responsive text-center" (click)="goToCart()" style="cursor: pointer">
                <img src="assets/images/BasketIcon.png" height="20px" width="20px">
                <span *ngIf="count != 0" class="cart-span-responsive">{{count}}</span>
            </a>
            <ul class="navbar-nav">
                <li class="nav-item dropdown">
                    <a title="login" class="css-login-botton nav-link" *ngIf="!isUserLogined" (click)="openLoginDialog()" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg class="bi bi-person-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                    </a>
                    <a title="login" class="css-login-botton nav-link header-a" *ngIf="isUserLogined" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg class="bi bi-person-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                    </a>
                    <div class="dropdown-content" *ngIf="isUserLogined">
                        <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/editProfile']" routerLinkActive="active-link">
                            My Profile</a>
                        <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/myOrders']">My Orders</a>
                        <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/rating']">My Rating</a>
                        <a class="dropdown-item resp-cust-dropdown-item" (click)="logout()" style="cursor: pointer;">Logout</a>
                    </div>
                </li>
            </ul>
            <div class="collapse navbar-collapse" id="navbarSupportedContent" style="flex-grow: 0;">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle header-a" href="#" id="navbarDropdownMenuLink1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Vegetables
                        </a>
                        <div class="dropdown-menu resp-cust-dropdown-manu" aria-labelledby="navbarDropdownMenuLink1">
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Vegetables','All-Vegetables']" routerLinkActive="active-link">All
                                Vegetables</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Vegetables','Fresh-Vegetables']">Fresh
                                Vegetables</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Vegetables','Exotic-Vegetables']">Exotic
                                Vegetables</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Vegetables','Herbs&Seasoning-Vegetables']">Herbs
                                &
                                Seasoning</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Vegetables','Organic-Vegetables']">Organic
                                Vegetables</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle header-a" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Fruits
                        </a>
                        <div class="dropdown-menu resp-cust-dropdown-manu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Fruits','All-Fruits']" routerLinkActive="active-link">All
                                Fruits</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Fruits','Fresh-Fruits']">Fresh
                                Fruits</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Fruits','Exotic-Fruits']">Exotic
                                Fruits</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle header-a" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Oils
                        </a>
                        <div class="dropdown-menu resp-cust-dropdown-manu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Olis','AllColdPressed-Oils']" routerLinkActive="active-link">All
                                Cold Pressed Oils</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Olis','ColdPressed-Oils']">Cold
                                Pressed Oils</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle header-a" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Flowers
                        </a>
                        <div class="dropdown-menu resp-cust-dropdown-manu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Flowers','All-Flowers&PotPlants']" routerLinkActive="active-link">All Flowers & Pot Plants</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Flowers','Roses,Marigolds&Gerberas']">Roses,
                                Marigolds & Gerberas</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Flowers','Lilies&Caronations']">Lilies
                                & Caronations</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Flowers','Exotic-Flowers']">Exotic
                                Flowers</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Flowers','Premium-Flowers']">Premium
                                Flowers</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Flowers','Pot-Plants']">Pot
                                Plants</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle header-a" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Millets & Seeds
                        </a>
                        <div class="dropdown-menu resp-cust-dropdown-manu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Millets&Seeds','All-Millets&Seeds']" routerLinkActive="active-link">All
                                Millets & Seeds</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Millets&Seeds','ImmuneBoosterMillets']">Immune
                                Booster Millets</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Millets&Seeds','Seeds']">Seeds</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle header-a" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Grocery & Eggs
                        </a>
                        <div class="dropdown-menu resp-cust-dropdown-manu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Grocery&Eggs','All-Grocery&Eggs']" routerLinkActive="active-link">All Grocery & Eggs</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Grocery&Eggs','Grocery']">Grocery</a>
                            <a class="dropdown-item resp-cust-dropdown-item" [routerLink]="['/Products','Grocery&Eggs','Dairy&Eggs']">Dairy
                                & Eggs</a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="css-searchrow" style="width: 100%">
                <div class="row">
                    <div class="col-8 col-md-8 col-sm-8 col-xs-8" style="padding-left: 15px;">
                        <div class="f2hSearchbutton" *ngIf="!isSeacrhBar">
                            <button class="css-seacrhbtn" (click)="onClickSeacrhButton()">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                                    <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                </svg>
                            </button>
                        </div>
                        <div class="topbar-search css-vurnku" *ngIf="isSeacrhBar">
                            <div class="picksySearch css-vurnku">
                                <label class="search css-5w9m11">
                                    <svg class="search-icon" width="16" height="16" viewBox="0 0 16 16">
                                        <path d="M16.122,12.452a6.772,6.772,0,1,0-1.195,1.2l.036.038,3.592,3.592a.847.847,0,0,0,1.2-1.2L16.16,12.488ZM14.364,4.706a5.079,5.079,0,1,1-7.183,0A5.079,5.079,0,0,1,14.364,4.706Z"
                                            transform="translate(-4 -1.525)" fill-rule="evenodd"></path>
                                    </svg>
                                    <input type="text" name="search" [disabled]="disableSearch" [(ngModel)]="searchKey"
                                        (input)="onChangeSearchEvent($event)" placeholder="Search All Fresh Products .."
                                        autocomplete="off" value="" class="form-control">
                                    <svg class="close-icon" (click)="onSearchClose()" width="14" height="14" viewBox="0 0 14 14">
                                        <path d="M6.115,4.792A.936.936,0,0,0,4.792,6.115l5.4,5.4-5.4,5.4a.936.936,0,0,0,1.323,1.323l5.4-5.4,5.4,5.4a.936.936,0,0,0,1.323-1.323l-5.4-5.4,5.4-5.4a.936.936,0,1,0-1.323-1.323l-5.4,5.4Z"
                                            transform="translate(-4.518 -4.518)"></path>
                                    </svg>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-md-4 col-sm-4 col-xs-4">
                        <ul class="navbar-nav mr-auto" style="cursor: pointer;float: right;color: white;">
                            <li class="dropdown-toggle css-dlvloc" (click)="changeLocation()" *ngIf="!isLocationSelected">
                                Choose Location
                            </li>
                            <li class="dropdown-toggle css-dlvloc" (click)="changeLocation()" *ngIf="isLocationSelected">
                                {{selectedLocation}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</header>

<!-- for search -->
<div *ngIf="searchDiv">
    <app-seacrh [filteredAllProducts]="filteredAllProducts"></app-seacrh>
</div>