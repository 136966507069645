<div class="css-maincontainer">
    <div class="row accordion-gradient-bcg d-flex justify-content-center" style="margin-right: 0px;margin-left: 0px;">
        <div class="col-md-10 col-xl-8 py-7">
            <div class="accordion md-accordion accordion-2" id="accordionEx7" role="tablist" aria-multiselectable="true">
                <div class="card">
                    <h1 class="card-header">Cart
                        <button title="Close" class="css-k5881g pull-right" (click)="closeAddToCartPopUp()">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                                height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z"></path>
                            </svg>
                        </button>
                    </h1>
                    <h4 class="css-cardheader">You have chosen {{count}} items </h4>
                    <div class="collapse show" role="tabpanel" aria-labelledby="heading2" data-parent="#accordionEx7" style="padding-left: 5px;padding-right: 5px;">
                        <div class="card-body mb-1 rgba-grey-light white-text css-cardbodypading">
                            <div>
                                <div class="css-mjrs0s">
                                    <div class="css-vurnku">
                                        <div class="row" *ngFor="let cartProduct of allCartItemsFromSession">
                                            <div class=" col-4 col-xs-4 col-sm-6 col-md-3 col-lg-3 css-kgvnaj" style="padding-right: 0;">
                                                <div class="css-1kxr47f">
                                                    <div class="gatsby-image-wrapper" style="position: relative; overflow: hidden;">
                                                        <div aria-hidden="true" style="width: 100%; padding-bottom: 100%;"></div>
                                                        <img aria-hidden="true" [src]="cartProduct.product_Image" alt="" class="" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; object-fit: cover; object-position: center center;">
                                                    </div>
                                                    <button title="Click to delete" class="css-k5881g" (click)="removeItemFromCart(cartProduct)">
                                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                            viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-4 col-xs-4 col-sm-6 col-md-6 col-lg-6" style="padding-left: 0;">
                                                <div class="css-vurnku">
                                                    <div class="white-text font-thin css-1q9j665">{{cartProduct.farm_Short_Name}}</div>
                                                    <div class="white-text font-thin css-1facnq0">{{cartProduct.product_Name}}</div>
                                                    <div class="white-text font-thin css-1facnq0" *ngIf="!cartProduct.deal_Of_Day_Item">{{cartProduct.quantity_Desc}} Rs.{{cartProduct.allfresh_Price}}
                                                    </div>
                                                    <div class="white-text font-thin css-1facnq0" *ngIf="cartProduct.deal_Of_Day_Item">{{cartProduct.quantity_Desc}} Rs.{{cartProduct.deal_Price}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4 col-xs-4 col-sm-4 col-md-3 col-lg-3">
                                                <div class="css-1e8fo1e">
                                                    <div class="css-5ucbon">
                                                        <button title="Decrement" class="css-110od4n" (click)="onClickDecrement(cartProduct.inventory_Id,'cp')">
                                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                                viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z"></path>
                                                            </svg>
                                                        </button>
                                                        <div class="css-vurnku">{{cartProduct.cart_Quantity}}</div>
                                                        <button title="Increment" class="css-110od4n" (click)="onClickIncrement(cartProduct.inventory_Id,'cp', cartProduct)" *ngIf="!cartProduct.deal_Of_Day_Item">
                                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                                viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7 8.8 0 16-7.2 16-16V272h96.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z"></path>
                                                            </svg>
                                                        </button>
                                                        <button title="Increment" class="css-110od4n" (click)="onClickIncrementDOD()" *ngIf="cartProduct.deal_Of_Day_Item">
                                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                                viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7 8.8 0 16-7.2 16-16V272h96.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="white-text font-thin css-sellp" *ngIf="!cartProduct.deal_Of_Day_Item">
                                                    Rs. {{cartProduct.cart_Quantity *cartProduct.allfresh_Price | number:'1.2-2' }}
                                                </div>
                                                <div class="white-text font-thin css-sellp" *ngIf="cartProduct.deal_Of_Day_Item">
                                                    Rs. {{cartProduct.cart_Quantity *cartProduct.deal_Price | number:'1.2-2' }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="css-mjrs0s-hd">
                                    <div class="css-vurnku-hd">
                                        <div class="row">
                                            <div class="col-7 col-xs-7 col-sm-7 col-md-9 col-lg-9">
                                                <div class="css-downhead">
                                                    <div class="css-text1">Total Amount</div>
                                                </div>
                                            </div>

                                            <div class="col-5 col-xs-5 col-sm-5 col-md-3 col-lg-3">
                                                <div class="" style="font-weight: 600;">
                                                    Rs. {{totalPrice | number:'1.2-2' }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="css-1tid0zn form-group">
                                <button class="css-110od4n" (click)="proceedToCheckout()" [disabled]="isProceedToCheckoutDisable">Proceed to checkout
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>