<div class="css-maincontainer" id="print-invoiceId">
    <div class="row accordion-gradient-bcg d-flex justify-content-center css-print-main">
        <div class="accordion md-accordion accordion-2" id="accordionEx7" role="tablist" aria-multiselectable="true">
            <div class="card css-printinvborder">
                <div class="row css-print-row">
                    <div class="col-6 css-print-col">
                        <div class="row css-print-img-row">
                            <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600514717/All_fresh_logo_bw_sw6set.png" height="60px" width="200px">
                        </div>
                        <div class="row text-center css-dlv-row">
                            <div class="css-dlv">
                                DELIVERY STATEMENT
                            </div>
                        </div>

                    </div>
                    <div class="col-6">
                        <div class="row css-prnt-border">
                            <label class="css-labelfont css-row-right-lbl">
                                Name: {{allInvoicesList.orderDeliveryDetails.first_Name}}
                                {{allInvoicesList.orderDeliveryDetails.last_Name}}
                            </label>
                        </div>
                        <div class="row css-prnt-border">
                            <label class="css-labelfont css-row-right-lbl">
                                Mobile No. : {{allInvoicesList.orderDeliveryDetails.phone_Number}}
                            </label>
                        </div>
                        <div class="row">
                            <label class="css-labelfont css-row-right-lbl">
                                Address: {{allInvoicesList.orderDeliveryDetails.address1}}
                                {{allInvoicesList.orderDeliveryDetails.address2}},
                                {{allInvoicesList.orderDeliveryDetails.house_No}},
                                {{allInvoicesList.orderDeliveryDetails.apartment_Name}},
                                {{allInvoicesList.orderDeliveryDetails.city}},
                                {{allInvoicesList.orderDeliveryDetails.state}} -
                                {{allInvoicesList.orderDeliveryDetails.pinCode}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row css-prnt-border">
                    <div class="col-6 css-col-inv">
                        <div class="row css-row-inv">
                            <label class="css-labelfont" style="padding-left: 25px;">
                                Inv. No. : {{allInvoicesList._id}}
                            </label>
                        </div>
                        <div class="row">
                            <label class="css-labelfont" style="padding-left: 25px;">
                                Ord. Id. : {{allInvoicesList.order_Id}}
                            </label>
                        </div>
                    </div>
                    <div class="col-3 css-inv-col-left">
                        <div class="row css-inv-row-left">
                            <label class="css-labelfont" style="padding-left: 10px;padding-right: 10px;">
                                Inv. Date : {{allInvoicesList.invoice_Date | date: 'dd/MM/yyyy'}}
                            </label>
                        </div>
                        <div class="row">
                            <label class="css-labelfont" style="padding-left: 25px;padding-right: 10px;">
                                Payment By : {{allInvoicesList.payment_Mode}}
                            </label>
                        </div>
                    </div>
                    <div class="col-3 css-print-col">
                        <label class="css-labelfont">
                            Delivery Date : {{allInvoicesList.invoice_Date | date: 'dd/MM/yyyy'}} </label>
                    </div>
                </div>
                <table class="css-prnt-border">
                    <tr class="aligncenter">
                        <th class="css-tbl-heading">SI.No</th>
                        <th class="css-tbl-heading">HSN Code</th>
                        <th class="css-tbl-heading">Item Description</th>
                        <th class="css-tbl-heading">Quantity</th>
                        <th class="css-tbl-heading">Unit Price Rs.</th>
                        <th class="css-tbl-heading">Taxable Value Rs.</th>
                        <th class="css-tbl-heading text-center">GST %</th>
                        <th class="css-prnt-border">Gross Amount Rs.</th>
                    </tr>
                    <tr class="tablecontentfont aligncenter" *ngFor="let invitem of allinvoiceItemDetails;let i=index">
                        <td class="css-tbl-heading css-tblfont">{{i+1}}</td>
                        <td class="css-tbl-heading css-tblfont">{{invitem.hsn_Code}}</td>
                        <td class="css-tbl-heading css-tblfont" style="text-align: left;">{{invitem.product_Name}} {{invitem.quantity_Desc}}</td>
                        <td class="css-tbl-heading css-tblfont text-center">{{invitem.item_Quantity}}</td>
                        <td class="css-tbl-heading css-tblfont text-center">{{invitem.unit_Price | number:'1.2-2'}}</td>
                        <td class="css-tbl-heading css-tblfont text-center">{{invitem.taxable_Price}}</td>
                        <td class="css-tbl-heading css-tblfont text-center">{{invitem.gst}}%</td>
                        <td class="css-tblright css-tbl-heading css-tblfont">{{invitem.item_Amount | number:'1.2-2'}}</td>
                    </tr>
                    <tr>
                        <td class="blank_row css-labelfont css-print-col" colspan="6">
                            Rupees: {{totalOrderAmountInWords | titlecase}} only.</td>
                        <td class="bg-color css-lblleft css-tbl-heading">
                            Sub Total</td>
                        <td class="css-labelfont css-tblright">{{allInvoicesList.sub_Total | number:'1.2-2'}}</td>

                    </tr>
                    <!-- <tr>
                        <td class="blank_row css-print-col" colspan="6"></td>
                        <td class="bg-color css-lblleft css-tbl-heading">Delivery Charges
                        </td>
                        <td class="css-labelfont css-tblright text-center">
                            {{allInvoicesList.delivery_Charges | number:'1.2-2'}}</td>
                    </tr> -->
                    <tr>
                        <td class="blank_row css-print-col" colspan="6"></td>
                        <td class="bg-color css-lblleft css-tbl-heading">SGST</td>
                        <td class="css-labelfont css-tblright">
                            {{allInvoicesList.sgst | number:'1.2-2'}}</td>
                    </tr>
                    <tr>
                        <td class="blank_row css-print-col" colspan="6"></td>
                        <td class="bg-color css-lblleft css-tbl-heading">CGST</td>
                        <td class="css-labelfont css-tblright">
                            {{allInvoicesList.cgst | number:'1.2-2'}}</td>
                    </tr>
                    <tr>
                        <td class="blank_row css-print-col" colspan="6"></td>
                        <td class="bg-color css-lblleft css-print-col">Grand Total</td>
                        <td class="css-labelfont css-tot">{{allInvoicesList.grand_Total | number:'1.2-2'}}</td>
                    </tr>
                </table>
                <div class="row css-blank-row">

                </div>
                <table class="css-prnt-border">
                    <tr class="css-prnt-border">
                        <th colspan="6">
                            Item Cancelled / Not Delivered ‐ Modification Information
                        </th>
                    </tr>
                    <tr class="aligncenter">
                        <th class="css-tbl-heading">SI.No</th>
                        <th class="css-tbl-heading">Item Description</th>
                        <th class="css-tbl-heading">Original Quantity</th>
                        <th class="css-tbl-heading">Actual Quantity</th>
                        <th class="css-tbl-heading">Item Status</th>
                        <th class="css-prnt-border">Item Value</th>
                    </tr>
                    <tr class="tablecontentfont aligncenter" *ngFor="let modItems of allModItemDetails;let i=index;">
                        <td class="css-tbl-heading css-tblfont" style="padding-left: 5px;">{{i+1}}</td>
                        <td class="css-tbl-heading css-tblfont" style="text-align: left;">{{modItems.product_Name}}</td>
                        <td class="css-tbl-heading css-tblfont">{{modItems.quantity_Desc}}</td>
                        <td class="css-tbl-heading css-tblfont">NA</td>
                        <td class="css-tbl-heading css-tblfont">Un Delivered</td>
                        <td class="css-prnt-border css-tblfont">NA</td>
                    </tr>
                </table>
                <div style="height: 60px;">

                </div>
                <div style="height: 60px;">
                </div>
                <div class="row" style="height: 60px;">
                    <div class="col-lg-12">
                        <label class="css-labelfont" style="float: right;padding-right: 27px;">For allfresh</label>
                    </div>
                    <div class="col-lg-12">
                        <label class="css-labelfont" style="float: right;padding-right: 10px;">Seal & Signature</label>
                    </div>
                </div>
                <div style="height: 30px;">

                </div>
                <label class="css-fstline">Any queries regarding product quality has to be intimated within 12 hours of
                    delivery</label>
                <label class="css-labelfont" style="text-align: center">As per Section 31 of CGST Act read with Rules,
                    invoice is issued at the point of delivering the goods.
                    Registered Office: Farm2Homes (allfresh),<br />
                    No.8, 14th Main, 14th Cross, HSR 4th Sector, Bengaluru-560102. GSTIN: 29AAHFF2151J1ZD</label>
                <label class="css-labelfont" style="text-align: center">Thanks for your purchase</label>
            </div>
        </div>
    </div>
</div>