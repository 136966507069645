<div class="css-maincontainer">
    <div class="row accordion-gradient-bcg d-flex justify-content-center" style="margin-right: 0px;margin-left: 0px;">
        <div class="col-md-10 col-xl-8 py-7">
            <div class="accordion md-accordion accordion-2" id="accordionEx7" role="tablist" aria-multiselectable="true">

                <div class="card">
                    <div class="card-header" style="padding: 0;">
                        <div class="mb-0 white-text font-thin">
                            <div class="css-vurnku-hd">
                                <img src="https://res.cloudinary.com/dignjitwf/image/upload/v1600260597/All_fresh_HD_LOGO_m4brqi.png
                  " height="80px" width="200px" style="padding-bottom: 10px;">
                                <a title="Close" class="css-k5881g" style="float: right;" (click)="showCustOrderPopupDismis()">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z"></path>
                  </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="css-cardheader">
                        <div class="mb-0 white-text font-thin">
                            <div class="css-mjrs0s-hd">
                                <div class="css-vurnku-hd" *ngIf="!isOrderSuccessPage">
                                    <div class="row">
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 css-message-show">
                                            <p class="card-text">{{successmsg1}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="css-vurnku-hd" *ngIf="isOrderSuccessPage">
                                    <div class="row">
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 card-text">
                                            Congratulations {{custName}} Your Order Placed Successfully
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 card-text">
                                            <div> Your Order Id is : {{ myorderId }}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 card-text">
                                            <div>Order Date : {{orderedDate | date: 'dd/MM/yyyy' }}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 card-text">
                                            <div>Expected delivery Date & time : {{expectedDelvDate | date: 'dd/MM/yyyy'}} & 9am to 2pm</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isOrderSuccessPage" class="card-header-1 rgba-stylish-strong z-depth-1 mb-1" role="tab" id="heading3">
                        <div class="mb-0 white-text font-thin">
                            <div class="css-mjrs0s-hd">
                                <div class="css-vurnku-hd">
                                    <div class="row">
                                        <div class="col-7 col-xs-7 col-sm-9 col-md-9 col-lg-9 card-text">
                                            Total Order Amount
                                        </div>
                                        <div class="col-5 col-xs-5 col-sm-3 col-md-3 col-lg-3 card-text css-left-padding">
                                            Rs. {{subTotal | number:'1.2-2' }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-7 col-xs-7 col-sm-9 col-md-9 col-lg-9 card-text">
                                            Shipping Charges
                                        </div>
                                        <div class="col-5 col-xs-5 col-sm-3 col-md-3 col-lg-3 card-text css-left-padding">
                                            Rs. {{shippingCharge | number:'1.2-2' }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-7 col-xs-7 col-sm-9 col-md-9 col-lg-9 card-text">
                                            Total Charges
                                        </div>
                                        <div class="col-5 col-xs-5 col-sm-3 col-md-3 col-lg-3 card-text css-left-padding">
                                            <!-- Rs. {{subTotal + shippingCharge | number:'1.2-2' }} -->
                                            Rs. {{totalCharges | number:'1.2-2' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isOrderSuccessPage" class="collapse show" role="tabpanel" aria-labelledby="heading2" data-parent="#accordionEx7" style="padding-left: 5px;padding-right: 5px;">
                        <div class="card-body mb-1 rgba-grey-light white-text  css-cardbodypading">
                            <div>
                                <div class="css-mjrs0s">
                                    <div class="css-vurnku">
                                        <div class="row" style="margin-left: 0;margin-right: 0;">
                                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                                                <div class="col d-flex justify-content-center">
                                                    <mat-spinner *ngIf="hideSpinner"></mat-spinner>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="isOrderSuccessPage" class="collapse show" role="tabpanel" aria-labelledby="heading2" data-parent="#accordionEx7" style="padding-left: 5px;padding-right: 5px;">
                        <div class="card-body mb-1 rgba-grey-light white-text  css-cardbodypading">
                            <div>
                                <div class="css-mjrs0s">
                                    <div class="css-vurnku">
                                        <div class="row" *ngFor="let sessionCartItem of allOrderedItems" style="margin-left: 0;margin-right: 0;">
                                            <div class=" col-4 col-xs-4 col-sm-3 col-md-3 col-lg-3 css-cartitemmdl" style="padding-right: 0;padding-left: 0;">
                                                <div class="css-1kxr47f">
                                                    <div class=" gatsby-image-wrapper" style="position: relative; overflow: hidden;">
                                                        <div aria-hidden="true" style="width: 100%; padding-bottom: 100%;"></div>
                                                        <img aria-hidden="true" [src]="sessionCartItem.product_Image" alt="" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; object-fit: cover; object-position: center center;">
                                                    </div>
                                                    <div class="css-shownumber" *ngIf='sessionCartItem.cart_Quantity>0'>
                                                        <button class="css-pncr0q" title="Order Quantity">
                              {{sessionCartItem.cart_Quantity }}
                            </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4 col-xs-4 col-sm-6 col-md-6 col-lg-6 css-cartitemmdl" style="padding-left: 0;padding-right: 0;">
                                                <div class="css-vurnku">
                                                    <div class="white-text font-thin css-1q9j665">{{sessionCartItem.farm_Short_Name}}</div>
                                                    <div class="white-text font-thin css-1facnq0">{{sessionCartItem.product_Name}}</div>
                                                    <div class="white-text font-thin css-1facnq0" *ngIf="!sessionCartItem.deal_Of_Day_Item">{{sessionCartItem.quantity_Desc}} Rs.{{sessionCartItem.allfresh_Price}}
                                                    </div>
                                                    <div class="white-text font-thin css-1facnq0" *ngIf="sessionCartItem.deal_Of_Day_Item">{{sessionCartItem.quantity_Desc}} Rs.{{sessionCartItem.deal_Price}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4 col-xs-4 col-sm-3 col-md-3 col-lg-3 css-cartitemmdl" style="padding-left: 0;padding-right: 0;">
                                                <div class="white-text font-thin" *ngIf="!sessionCartItem.deal_Of_Day_Item">
                                                    Rs. {{sessionCartItem.cart_Quantity *sessionCartItem.allfresh_Price | number:'1.2-2' }}
                                                </div>
                                                <div class="white-text font-thin" *ngIf="sessionCartItem.deal_Of_Day_Item">
                                                    Rs. {{sessionCartItem.cart_Quantity *sessionCartItem.deal_Price | number:'1.2-2' }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="isOrderSuccessPage" class="text-center buttom-text">
                        Thanks for shopping ! your order will be delivered shortly...
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>