<!-- <div class="login-container"> -->
        <div style="cursor: pointer;">
                <h1 class="text-center">Login / Sign Up
                    <span class="material-icons pull-right" (click)="closeDialog('close')">
                        clear
                    </span>
                </h1>
            </div>
            <div style="cursor: pointer;">
                <div mat-dialog-content>
                    <form [formGroup]="loginForm">
                        <strong *ngIf="submitMessage" class="error-message">{{submitMessage}}</strong>
                        <div class="form-group">
                            <input type="text" class="form-control" maxlength="10" autocomplete="disabled" formControlName="mobileNumber"
                                placeholder="Enter mobile Number" name="mobileNumber" *ngIf="!showOtp" [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }">
                            <div *ngIf="submitted && f.mobileNumber.errors && f.mobileNumber.touched" class="invalid-feedback">
                                <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required</div>
                                <div *ngIf="f.mobileNumber.errors?.minlength">Number should be 10 digit</div>
                                <div *ngIf="f.mobileNumber.errors.pattern"> Please enter a valid Mobile Number</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" #otpfield tabindex="1" autocomplete="disabled" formControlName="otp" class="form-control"
                                placeholder="Enter OTP" name="otp" *ngIf="showOtp">
                        </div>
                        <div class="row" style="margin-top: 20px;">
                            <div class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
                                <i class="fa fa-balance-scale" aria-hidden="true"></i>
                                <label class="css-loginlebel">Quality Assured</label>
                            </div>
                            <div class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
                                <svg class="bi bi-truck" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5v7h-1v-7a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5v1A1.5 1.5 0 0 1 0 10.5v-7zM4.5 11h6v1h-6v-1z" />
                                    <path fill-rule="evenodd" d="M11 5h2.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5h-1v-1h1a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4.5h-1V5zm-8 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                    <path fill-rule="evenodd" d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                </svg>
                                <label class="css-loginlebel">On Time Delivery</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class=" col-12 col-lg-12 col-md-12 text-center">
                                <i class="fa fa-user-plus" aria-hidden="true"></i>
                                <label class="css-loginlebel">Doubling Farmer's Income</label>
                            </div>
                        </div>
                        <div class="text-center" style="margin-top: 20px;">
                            <button mat-raised-button class="btn" (click)="getOtp()" *ngIf="!showOtp">
                                Login Using OTP
                            </button>
                            <button mat-raised-button class="btn" (click)="validateOtp()" *ngIf="showOtp">
                                Validate OTP
                            </button>
                            <button mat-raised-button class="btn" style="margin-left: 8px;" (click)="resendOtp()" *ngIf="showOtp">
                                Resend OTP
                            </button>
                        </div>
                    </form>
                </div>
            </div>
<!-- </div> -->
