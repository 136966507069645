import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class LatestproductService {

  latestproduct:any;
  currentNameSubject$ = new BehaviorSubject("");

  constructor(private httpClient: HttpClient) { }
  
  allLatestProducts(delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getLatestProductsLocationEnhanced/'+delloc, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.latestproduct = Resp;
      this.currentNameSubject$.next(this.latestproduct);
   }))
  }
}
