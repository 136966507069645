<div style="cursor: pointer;">
    <div class="card">
        <div class="card-header">
            Information
        </div>
        <div class="card-body">
            <h2 class="card-text">
                Add Rs.{{restAmountToplaceOrderWithDod}} more to place the order along with Deal of the Day items
            </h2>
            <div class="text-center" style="margin-top: 20px;">
                <button mat-raised-button class="btn" (click)="OnCLickOk()">
                    OK
                </button>
            </div>
        </div>
    </div>
</div>