import { Component, OnInit } from '@angular/core';
import { toWords as numToWord } from 'number-to-words';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css']
})
export class ViewInvoiceComponent implements OnInit {

  allInvoicesList: any;
  allinvoiceItemDetails: any;
  allModItemDetails: any;
  totalOrderAmountInWords: string;

  constructor() { }

  ngOnInit(): void {
    this.allInvoicesList = JSON.parse(localStorage.getItem("viewInvoiceData"));
    if (this.allInvoicesList) {
      this.allinvoiceItemDetails = this.allInvoicesList["invoiceItemDetails"];
      this.allModItemDetails = this.allInvoicesList["orderDeliveryModificationDetails"];

      let totCharges = this.allInvoicesList['grand_Total'];
      if (totCharges % 1 != 0) {
        var splittedNum = totCharges.toString().split('.');
        var nonDecimal = splittedNum[0]
        var decimal = splittedNum[1]
        var value = numToWord(Number(nonDecimal)) + " and " + numToWord(Number(decimal)) + " paisa";
        this.totalOrderAmountInWords = value
      }
      else {
        this.totalOrderAmountInWords = numToWord(totCharges);
      }
    }
  }
}
