import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MyRatingService {

  currentNameSubject$ = new BehaviorSubject("");
  productToBeRatedResp: any;
  productRatedResp: any;
  rateProductResp:any;

  constructor(private httpClient: HttpClient,private authService:AuthService) { }

  productToBeRated(userId) {
    const headers = new HttpHeaders()
      .set('content-type', `application/json`)
      .set('Authorization', `Bearer ${this.authService.getBearerToken()}`)
      .set('userid',window.localStorage.getItem("userId"))
      .set('Access-Control-Allow-Origin', '*');
    return this.httpClient.get(AppComponent.RATING_API_ENDPOINT + 'getProductReviewToBeRated/' + userId, {
      'headers':headers
    }).pipe(tap(Resp => {
      this.productToBeRatedResp = Resp;
      this.currentNameSubject$.next(this.productToBeRatedResp);
    }))
  }

  productRated(userId) {
    const headers = new HttpHeaders()
  .set('content-type', `application/json`)
  .set('Authorization',`Bearer ${this.authService.getBearerToken()}`)
  .set('userid',window.localStorage.getItem("userId"))
  .set('Access-Control-Allow-Origin', '*');
    return this.httpClient.get(AppComponent.RATING_API_ENDPOINT + 'getProductReviewRated/' + userId, {
      'headers':headers
    }).pipe(tap(Resp => {
      this.productRatedResp = Resp;
      this.currentNameSubject$.next(this.productRatedResp);
    }))
  }

  rateProduct(rateRequestObj) {
    const headers = new HttpHeaders()
  .set('content-type', `application/json`)
  .set('Authorization',`Bearer ${this.authService.getBearerToken()}`)
  .set('userid',window.localStorage.getItem("userId"))
  .set('Access-Control-Allow-Origin', '*');
    return this.httpClient.post(AppComponent.RATING_API_ENDPOINT + 'rate', rateRequestObj, {
      'headers':headers
    }).pipe(tap(Resp => {
      this.rateProductResp = Resp;
      
      this.currentNameSubject$.next(this.rateProductResp);
    }))
  }
}
