import { Injectable } from "@angular/core";
import { SharedmobService } from "./sharedmob.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MaxQuantityAllowedPopUpComponent } from "../max-quantity-allowed-pop-up/max-quantity-allowed-pop-up.component";

@Injectable({
  providedIn: "root"
})
export class CartService {
  allCartItems: any = [];
  farmObj: any;
  count: number = 0;
  totalPrice: number = 0;

  constructor(
    private sharedService: SharedmobService,
    private modalService: NgbModal
  ) {
    let allCartItemFromSessionCommon = JSON.parse(
      window.localStorage.getItem("cartItemObj")
    );
    if (allCartItemFromSessionCommon) {
      this.allCartItems = allCartItemFromSessionCommon;
      this.getCartItemCountandPrice(allCartItemFromSessionCommon);
    }
  }

  getQuantityForInvId(invID) {
    const resultItem = this.allCartItems.filter(
      item => item.inventory_Id === invID
    );
    if (resultItem && resultItem.length > 0) {
      // first time
      return resultItem[0].cart_Quantity;
    } else {
      return 0;
    }
  }
  addtoCart(fmObj) {
    const resultItem = this.allCartItems.filter(item => item.inventory_Id === fmObj.inventory_Id);
    if (resultItem && resultItem.length === 0) {
      // first time
      fmObj.cart_Quantity += 1;
      this.allCartItems.push(fmObj);
    } else {
      //2nd time onward
      this.updatecartItems(fmObj.inventory_Id, "add");
    }

    window.localStorage.setItem("cartItemObj",JSON.stringify(this.allCartItems));
    let allCartItemFromSessionCommon = JSON.parse(window.localStorage.getItem("cartItemObj"));
    this.getCartItemCountandPrice(allCartItemFromSessionCommon);
  }

  addSearchItemtoCart(fmObj) {
    let carti = window.localStorage.getItem("cartItemObj");
    if (carti) {
      this.allCartItems = JSON.parse(
        window.localStorage.getItem("cartItemObj")
      );
    } else {
      this.allCartItems = [];
    }

    console.log(this.allCartItems);
    const resultItem = this.allCartItems.filter(
      item => item.inventory_Id === fmObj.inventory_Id
    );
    if (resultItem && resultItem.length === 0) {
      // first time
      fmObj.cart_Quantity += 1;
      this.allCartItems.push(fmObj);
    } else {
      //2nd time onward
      this.updatecartItems(fmObj.inventory_Id, "add");
    }

    window.localStorage.setItem(
      "cartItemObj",
      JSON.stringify(this.allCartItems)
    );
    let allCartItemFromSessionCommon = JSON.parse(
      window.localStorage.getItem("cartItemObj")
    );
    this.getCartItemCountandPrice(allCartItemFromSessionCommon);
  }

  onClickIncrement(uniqueId, type, item) {
    let farmObj = this.getObjFromCartList(uniqueId);
    let rulesObj = JSON.parse(window.localStorage.getItem("sCharges"));
    let maxorderQuantity = rulesObj["maximum_allowed_qty"];
    if (item.cart_Quantity < maxorderQuantity) {
      if (farmObj) {
        if (farmObj.inventory_Id === uniqueId) {
          this.addtoCart(farmObj);
        }
      }
    } else {
      this.openMaxQuantityAddedPopUp(10);
    }
  }
  onClickDecrement(uniqueId, type) {
    let farmObj = this.getObjFromCartList(uniqueId);
    if (farmObj && farmObj.inventory_Id === uniqueId) {
      this.removefromCart(farmObj);
    }
  }

  getObjFromCartList(uniqeid) {
    let farmObj;
    //added by balaram  for remove issue
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
    for (let citem of this.allCartItems) {
      if (citem.inventory_Id === uniqeid) {
        farmObj = citem;
      }
    }
    return farmObj;
  }
  updatecartItems(itemid, type) {
    for (let cartItem of this.allCartItems) {
      if (cartItem.inventory_Id === itemid && type === "add") {
        let rulesObj = JSON.parse(window.localStorage.getItem("sCharges"));
        let maxorderQuantity = rulesObj["maximum_allowed_qty"];
          if(cartItem.cart_Quantity<maxorderQuantity){
            cartItem.cart_Quantity += 1;
          } 
      }
      if (cartItem.inventory_Id === itemid && type === "remove") {
        if (cartItem.cart_Quantity > 0) {
          cartItem.cart_Quantity -= 1;
          if (cartItem.cart_Quantity === 0) {
            const indexFmObj: number = this.allCartItems.indexOf(cartItem);
            if (indexFmObj !== -1) {
              this.allCartItems.splice(indexFmObj, 1);
            }
          }
        }
      }
    }
    window.localStorage.setItem("cartItemObj",JSON.stringify(this.allCartItems));
  }

  getCartItemCountandPrice(allItems) {
    this.count = 0;
    this.totalPrice = 0;
    for (let cartItem of allItems) {
      this.count += 1;
      let totItemQuantityPrice = 0;
      if (cartItem.deal_Of_Day_Item) {
        totItemQuantityPrice = cartItem.cart_Quantity * cartItem.deal_Price;
      } else {
        totItemQuantityPrice = cartItem.cart_Quantity * cartItem.allfresh_Price;
      }
      this.totalPrice += totItemQuantityPrice;
    }
    this.sharedService.changeCount(this.count);
    this.sharedService.changeTotalPrice(this.totalPrice);
  }

  updatecartQuantity(productresp, item) {    
    for (let csitem of productresp) {
      if (item) {
        for(let pqtd of csitem["product_Qty_Details"]){
          if (pqtd["inventory_Id"] === item.inventory_Id) {
            pqtd["cart_Quantity"] = item.cart_Quantity;
          } 
          else {
            pqtd["cart_Quantity"] = 0;
          }
        }
    }
  }
    return productresp;
  }

  updateSubcartQuantity(productresp) {
    let productrespnew = [];

    for (var i = 0; i < productresp.length; i++) {
      let prod = productresp[i];
      let upItm = this.updateCartQuantityByFarmID(prod.inventory_Id, prod);
      if (upItm) {
        productrespnew.push(upItm);
      } else {
        productrespnew.push(prod);
      }
    }
    return productrespnew;
  }

  updateSubcartQuantityByDesc(productresp,selItem) {

    for (let prod of productresp) {
      if(prod){
        for (let itm of prod["product_Qty_Details"]) {
          if (itm.inventory_Id === selItem.inventory_Id) {

            itm.cart_Quantity = selItem.cart_Quantity;            
          }
        }
      }
    }
    return productresp;
  }

  updateVegcartQuantity(productresp, item) {
    for (let csitem of productresp) {
      if (item) {
        if (csitem.inventory_Id === item.inventory_Id) {
          csitem.cart_Quantity = item.cart_Quantity;
        }
      } else {
        csitem.cart_Quantity = 0;
      }
    }
    return productresp;
  }

  updateCartQuantityByFarmID(invId, prod) {
    this.clearAllData();
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
    if (!this.allCartItems) {
      this.allCartItems = [];
    }

    for (let csitem of this.allCartItems) {
      let updFlag = false;
      const ps = prod["product_Qty_Details"];
      if(prod && ps && ps.length>0){
        for (let itm of prod["product_Qty_Details"]) {
          if (itm.inventory_Id === csitem.inventory_Id) {
            itm.cart_Quantity = csitem.cart_Quantity;
            if (prod.selected_inventory_Id && !updFlag) {
              prod.selected_inventory_Id.cart_Quantity = csitem.cart_Quantity;
              prod.selected_inventory_Id.inventory_Id = csitem.inventory_Id;
              prod.selected_inventory_Id.sell_Price = csitem.sell_Price;
              prod.selected_inventory_Id.allfresh_Price = csitem.allfresh_Price;
              prod.selected_inventory_Id.discount_Percentage =
                csitem.discount_Percentage;
              updFlag = true;
            }
          }
        }
      }
      
    }
    return prod;
  }

  updateCardQtBySelectedDesc(prod,selItem){
    let ps = prod["product_Qty_Details"];
      if(prod && ps && ps.length>0){
        for (let itm of prod["product_Qty_Details"]) {
          if (itm.inventory_Id === selItem.inventory_Id) {
            itm.cart_Quantity = selItem.cart_Quantity;
            
            }
          }
        }
      return prod;
  }

  fbitemRemoveVisibility(itemid) {
    let remele: Element = document.getElementById(itemid);
    if (remele) {
      remele.classList.remove("css-xupwym-visible");
    }
  }
  cditemRemoveOpac(itemId) {
    let remele: Element = document.getElementById(itemId);
    if (remele) {
      remele.classList.remove("css-content-opac");
    }
  }
  removefromCart(fmObj) {
    this.updatecartItems(fmObj.inventory_Id, "remove");
    let allCartItemFromSessionCommon = JSON.parse(window.localStorage.getItem("cartItemObj"));
    this.getCartItemCountandPrice(allCartItemFromSessionCommon);

    this.sharedService.changeCount(this.count);
    this.sharedService.changeTotalPrice(this.totalPrice);
  }

  addVisibilityToItem(itemid) {
    let elemop: Element = document.getElementById(itemid);
    if (elemop) {
      elemop.classList.add("css-xupwym-visible");
    }
  }

  hideItem(itemId, currentItemId) {
    if (itemId && itemId !== currentItemId) {
      let remele: Element = document.getElementById(itemId);
      if (remele) {
        remele.classList.remove("css-xupwym-visible");
      }
    }
  }
  hidecurrentItem(itemId) {
    if (itemId) {
      let remele: Element = document.getElementById(itemId);
      if (remele) {
        remele.classList.remove("css-xupwym-visible");
      }
    }
  }

  removeItemFromCart(indexFmObj) {
    if (indexFmObj !== -1) {
      this.allCartItems.splice(indexFmObj, 1);
    }
    //again update session after delete item from cart
    window.localStorage.setItem(
      "cartItemObj",
      JSON.stringify(this.allCartItems)
    );
  }

  public searchCall(data, isclose, hid) {
    let maindiv: Element = document.getElementById(hid);
    if (isclose) {
      if (maindiv) {
        maindiv.classList.remove("css-home-hide");
      }
    } else if (data === "") {
      if (maindiv) {
        maindiv.classList.remove("css-home-hide");
      }
    } else if (!isclose) {
      if (maindiv) {
        maindiv.classList.add("css-home-hide");
      }
    }
  }

  public HomeSearchCall(data, isclose, hid, searchcartbarid) {
    let maindiv: Element = document.getElementById(hid);
    let seacrhdiv: Element = document.getElementById(searchcartbarid);
    if (isclose) {
      if (maindiv) {
        maindiv.classList.remove("css-home-hide");
      }
    } else if (data === "") {
      if (maindiv) {
        maindiv.classList.remove("css-home-hide");
      }
    } else if (!isclose) {
      if (maindiv) {
        maindiv.classList.add("css-home-hide");
      }
    }
  }

  clearAllData() {
    this.allCartItems = [];
    this.totalPrice = 0;
    this.farmObj = {};
    this.count = 0;
  }
  options = {
    autoClose: false,
    keepAfterRouteChange: false
  };

  openMaxQuantityAddedPopUp(maxQuantity) {
    const modalRef = this.modalService.open(MaxQuantityAllowedPopUpComponent, {
      size: "md",
      backdrop: "static",
      centered: true
    });
    modalRef.componentInstance.maxQuantity = maxQuantity;
    modalRef.componentInstance.maxQuantitypopup = "modalRef.componentInstance";
  }

  //new changes
  updateCartQuantityNew(selectedobj, type) {
    if (type === "add") {
      let rulesObj = JSON.parse(window.localStorage.getItem("sCharges"));
      let maxorderQuantity = rulesObj["maximum_allowed_qty"];
      if (selectedobj.cart_Quantity < maxorderQuantity) {
        selectedobj.cart_Quantity += 1;
      } else {
        this.openMaxQuantityAddedPopUp(10);
      }
    } else {
      if (selectedobj.cart_Quantity > 0) {
        selectedobj.cart_Quantity -= 1;
      }
    }
    return selectedobj;
  }

  onClickIncrementNew(uniqueId, item) {
    let farmObj = this.getObjFromCartList(uniqueId);
      if (farmObj) {
        if (farmObj.inventory_Id === uniqueId) {
          this.addtoCart(farmObj);
        }
      }
  }

  onClickDecrementInProdDesc(objtoDeceseQtt){
    this.removefromCart(objtoDeceseQtt);
  }
}
