import { Component, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import { ProductsBySubTypeService } from "../services/products-by-sub-type.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "../services/router.service";
import { CartService } from "../services/cart.service";
import { SharedmobService } from "../services/sharedmob.service";
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from '../app.component';
import { AlertComponent } from '../alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-sub-product",
  templateUrl: "./sub-product.component.html",
  styleUrls: ["./sub-product.component.css", "./sub-product.component1.css"]
})
export class SubProductComponent implements OnInit {
  errMessage: string;
  productsBySubType: any = [];
  selectedProductInfo: any;
  count: number;
  mobileNumber: any;
  isChecked: boolean;
  mainType: any;
  subTypeData: any;
  productsByFarmId: any;
  farmList: any = [];
  uniqueFarmList: any = [];
  alluniqueFarmCheckboxList: any = [];
  allSelectedList: any = [];
  subtypeheading: string;
  subproduct_banner_Img: any;
  farmByFarmId: any = [];
  totalPrice: number;
  @Input() allCartItems: any[];
  previouscartdiv: string = "na";
  hidezeroQnty_Invid: any = "";

  previousitemdiv: string;
  delLoc: string;
  hideSpinner: boolean = true;

  productQtyDetails: any;
  selectedItemInvId: any;
  productQtyDetailsShow: any;

  newCartQuantity = 0;

  constructor(
    private productsbysubtypeService: ProductsBySubTypeService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private routerService: RouterService,
    private cartService: CartService,
    private sharedService: SharedmobService,
    private deviceService: DeviceDetectorService, private modalService: NgbModal
  ) {
    this.sharedService.currentCount.subscribe(c => (this.count = c));
    this.sharedService.currentTotalPrice.subscribe(
      tp => (this.totalPrice = tp)
    );
    this.sharedService.currentMobile.subscribe(
      message => (this.mobileNumber = message)
    );
  }

  ngOnInit() {
    this.allCartItems = [];
    this.sharedService.currentMobile.subscribe(
      message => (this.mobileNumber = message)
    );
    let allCartItemFromSessionInHome = JSON.parse(window.localStorage.getItem("cartItemObj"));
    if (allCartItemFromSessionInHome) {
      this.sharedService.currentCount.subscribe(c => (this.count = c));
      this.sharedService.currentTotalPrice.subscribe(tp => (this.totalPrice = tp));

      this.allCartItems = allCartItemFromSessionInHome;
      this.cartService.getCartItemCountandPrice(allCartItemFromSessionInHome);
    } else {
      allCartItemFromSessionInHome = [];
    }

    this.isChecked = false;
    let mainMenuTypeParamData = "";
    let subTypeParamData = "";
    this.route.params.subscribe(params => {
      mainMenuTypeParamData = params.mainmenutype;
      subTypeParamData = params.subtype;
    });

    if (mainMenuTypeParamData == "Vegetables" && subTypeParamData == "All-Vegetables") {
      this.subTypeData = "AV";
      this.mainType = "V";
      this.subtypeheading = "All Vegetables";
      this.subproduct_banner_Img = "assets/images/Vegetables/Vegetables.jpg";
    }
    if (mainMenuTypeParamData == "Vegetables" && subTypeParamData == "Exotic-Vegetables") {
      this.subTypeData = "EV";
      this.mainType = "V";
      this.subtypeheading = "Exotic Vegetable";
      this.subproduct_banner_Img = "assets/images/Vegetables/Vegetables.jpg";
    }
    if (mainMenuTypeParamData == "Vegetables" && subTypeParamData == "Fresh-Vegetables") {
      this.subTypeData = "FV";
      this.mainType = "V";
      this.subtypeheading = "Fresh Vegetables";
      this.subproduct_banner_Img = "assets/images/Vegetables/Vegetables.jpg";
    }
    if (mainMenuTypeParamData == "Vegetables" && subTypeParamData == "Herbs&Seasoning-Vegetables") {
      this.subTypeData = "HS";
      this.mainType = "V";
      this.subtypeheading = "Herbs & Seasoning";
      this.subproduct_banner_Img = "assets/images/Vegetables/Vegetables.jpg";
    }
    if (mainMenuTypeParamData == "Vegetables" && subTypeParamData == "Organic-Vegetables") {
      this.subTypeData = "OV";
      this.mainType = "V";
      this.subtypeheading = "Organic Vegetables";
      this.subproduct_banner_Img = "assets/images/Vegetables/Vegetables.jpg";
    }
    if (mainMenuTypeParamData == "Fruits" && subTypeParamData == "All-Fruits") {
      this.subTypeData = 'AF';
      this.mainType = "F";
      this.subtypeheading = "All Fruits";
      this.subproduct_banner_Img = "assets/images/Fruits/Fruits.jpg";
    }
    if (mainMenuTypeParamData == "Fruits" && subTypeParamData == "Fresh-Fruits") {
      this.subTypeData = 'FF';
      this.mainType = "F";
      this.subtypeheading = "Fresh Fruits";
      this.subproduct_banner_Img = "assets/images/Fruits/Fruits.jpg";
    }
    if (mainMenuTypeParamData == "Fruits" && subTypeParamData == "Exotic-Fruits") {
      this.subTypeData = 'EF';
      this.mainType = "F";
      this.subtypeheading = "Exotic Fruits";
      this.subproduct_banner_Img = "assets/images/Fruits/Fruits.jpg";
    }
    if (mainMenuTypeParamData == "Fruits" && subTypeParamData == "Cut-Fruits") {
      this.subTypeData = 'CF';
      this.mainType = "F";
      this.subtypeheading = "Cut Fruits";
      this.subproduct_banner_Img = "assets/images/Fruits/Fruits.jpg";
    }
    if (mainMenuTypeParamData == "Oils" && subTypeParamData == "AllColdPressed-Oils") {
      this.subTypeData = 'AO';
      this.mainType = "O";
      this.subtypeheading = "All Cold Pressed Oils";
      this.subproduct_banner_Img = "assets/images/Oils/Oils.jpg";
    }
    if (mainMenuTypeParamData == "Oils" && subTypeParamData == "ColdPressed-Oils") {
      this.subTypeData = 'CO';
      this.mainType = "O";
      this.subtypeheading = "Cold Pressed Oil";
      this.subproduct_banner_Img = "assets/images/Oils/Oils.jpg";
    }
    if (mainMenuTypeParamData == "Grocery&Eggs" && subTypeParamData == "All-Grocery&Eggs") {
      this.subTypeData = 'AD';
      this.mainType = "D";
      this.subtypeheading = "All Grocery & Eggs";
      this.subproduct_banner_Img = "https://res.cloudinary.com/dignjitwf/image/upload/v1609762950/HEADER_GROCERY_ipu67b.jpg";
    }
    if (mainMenuTypeParamData == "Grocery&Eggs" && subTypeParamData == "Grocery") {
      this.subTypeData = 'DY';
      this.mainType = "D";
      this.subtypeheading = "Grocery";
      this.subproduct_banner_Img = "https://res.cloudinary.com/dignjitwf/image/upload/v1609762950/HEADER_GROCERY_ipu67b.jpg";
    }
    if (mainMenuTypeParamData == "Grocery&Eggs" && subTypeParamData == "Dairy&Eggs") {
      this.subTypeData = 'EG';
      this.mainType = "D";
      this.subtypeheading = "Dairy & Eggs";
      this.subproduct_banner_Img = "https://res.cloudinary.com/dignjitwf/image/upload/v1609762950/HEADER_GROCERY_ipu67b.jpg";
    }
    if (mainMenuTypeParamData == "Grocery&Eggs" && subTypeParamData == "Biodegradable") {
      this.subTypeData = 'BD';
      this.mainType = "D";
      this.subtypeheading = "Biodegradable";
      this.subproduct_banner_Img = "https://res.cloudinary.com/dignjitwf/image/upload/v1609762950/HEADER_GROCERY_ipu67b.jpg";
    }
    if (mainMenuTypeParamData == "Millets&Seeds" && subTypeParamData == "All-Millets&Seeds") {
      this.subTypeData = 'AM'
      this.mainType = "M";
      this.subtypeheading = "All Millets And Seeds";
      this.subproduct_banner_Img = "assets/images/MilletsAndSeeds/Millets.jpg";
    }
    if (mainMenuTypeParamData == "Millets&Seeds" && subTypeParamData == "ImmuneBoosterMillets") {
      this.subTypeData = 'IB';
      this.mainType = "M";
      this.subtypeheading = "Immune Booster Millets";
      this.subproduct_banner_Img = "assets/images/MilletsAndSeeds/Millets.jpg";
    }
    if (mainMenuTypeParamData == "Millets&Seeds" && subTypeParamData == "Seeds") {
      this.subTypeData = 'SD';
      this.mainType = "M";
      this.subtypeheading = "Seeds";
      this.subproduct_banner_Img = "assets/images/MilletsAndSeeds/Millets.jpg";
    }
    if (mainMenuTypeParamData == "Flowers" && subTypeParamData == "All-Flowers&PotPlants") {
      this.subTypeData = 'AFL';
      this.mainType = "FL";
      this.subtypeheading = "All Flowers & Pot Plants";
      this.subproduct_banner_Img = "assets/images/Flowers/Flowers.jpg";
    }
    if (mainMenuTypeParamData == "Flowers" && subTypeParamData == "Roses,Marigolds&Gerberas") {
      this.subTypeData = 'RS';
      this.mainType = "FL";
      this.subtypeheading = "Roses, Marigolds & Gerberas";
      this.subproduct_banner_Img = "assets/images/Flowers/Flowers.jpg";
    }
    if (mainMenuTypeParamData == "Flowers" && subTypeParamData == "Lilies&Caronations") {
      this.subTypeData = 'MG';
      this.mainType = "FL";
      this.subtypeheading = "Lilies & Caronations";
      this.subproduct_banner_Img = "assets/images/Flowers/Flowers.jpg";
    }
    if (mainMenuTypeParamData == "Flowers" && subTypeParamData == "Exotic-Flowers") {
      this.subTypeData = 'GB';
      this.mainType = "FL";
      this.subtypeheading = "Exotic Flowers";
      this.subproduct_banner_Img = "assets/images/Flowers/Flowers.jpg";
    }
    if (mainMenuTypeParamData == "Flowers" && subTypeParamData == "Premium-Flowers") {
      this.subTypeData = 'CH';
      this.mainType = "FL";
      this.subtypeheading = "Premium Flowers";
      this.subproduct_banner_Img = "assets/images/Flowers/Flowers.jpg";
    }
    if (mainMenuTypeParamData == "Flowers" && subTypeParamData == "Pot-Plants") {
      this.subTypeData = 'PT';
      this.mainType = "FL";
      this.subtypeheading = "Pot Plants";
      this.subproduct_banner_Img = "assets/images/Flowers/Flowers.jpg";
    }

    this.delLoc = localStorage.getItem("delLoc");
    if (this.subTypeData !== "AV" && this.subTypeData !== "AF" && this.subTypeData !== "AO" && this.subTypeData !== "AD" && this.subTypeData !== "AM" && this.subTypeData !== "AFL") {
      this.getVegProductsBySubType(allCartItemFromSessionInHome);
    }
    else {
      this.getVegProductsByAllType(allCartItemFromSessionInHome);
    }

  }

  ngOnChanges() {
  }

  ngAfterViewChecked() {
    if (this.hidezeroQnty_Invid) {
      let remele: Element = document.getElementById(this.hidezeroQnty_Invid);
      if (remele) {
        remele.classList.remove("css-xupwym-visible");
      }
    }
    this.hidezeroQnty_Invid = "";
  }

  getVegProductsBySubType(cartItems) {
    this.productsbysubtypeService.allSubProductsBySubType(this.mainType, this.subTypeData, this.delLoc)
      .subscribe(resp => {
        this.productsBySubType = resp;
        this.hideSpinner = false;
        if (this.productsBySubType && this.productsBySubType.length > 0) {
          this.updateSelectedProduct(0);
        }
        this.uniqueFarmList = this.removeDuplicatesByFarmId(x => x.farm_Id, this.productsBySubType);
        this.getAllCheckBoxList(this.uniqueFarmList);

        if (cartItems && cartItems.length > 0) {
          this.updateQtForAll("subp");
          this.farmByFarmId = this.updatecartQuantityList(
            this.productsBySubType
          );

          this.filterFarmProduct(this.farmByFarmId, "All", "All");
        } else {
          this.filterFarmProduct(this.productsBySubType, "All", "All");
        }
      },
        err => {
          this.errMessage = err.error.message;
        }
      );
  }

  getVegProductsByAllType(cartItems) {
    this.productsbysubtypeService.allSubProductsByType(this.mainType, this.delLoc)
      .subscribe(resp => {
        if (resp) {
          this.productsBySubType = resp;
          if (this.productsBySubType && this.productsBySubType.length > 0) {
            this.updateSelectedProduct(0);
          }
          this.hideSpinner = false;
          this.uniqueFarmList = this.removeDuplicatesByFarmId(x => x.farm_Id, this.productsBySubType);
          this.getAllCheckBoxList(this.uniqueFarmList);
          if (cartItems && cartItems.length > 0) {
            this.updateQtForAll("all");
            this.farmByFarmId = this.updatecartQuantityList(this.productsBySubType);

            this.filterFarmProduct(this.farmByFarmId, "All", "All");
          } else {
            this.filterFarmProduct(this.productsBySubType, "All", "All");
          }
        }
      },
        err => {
          this.errMessage = " vegetable product does not loaded";
        }
      );
  }

  updateQtForAll(type) {
    for (let item of this.allCartItems) {
      if (type === "all") {
        this.updateAllVegCartQuantity(item);
      } else {
        this.updateWithoutAllVegcartQuantity(item);
      }
    }
  }



  updateCartQuantity(item) {
    return this.cartService.updateVegcartQuantity(this.productsBySubType, item);
  }

  updateWithoutAllVegcartQuantity(item) {
    this.productsBySubType = this.cartService.updateVegcartQuantity(
      this.productsBySubType,
      item
    );
  }

  updateAllVegCartQuantity(item) {
    this.productsBySubType = this.cartService.updateVegcartQuantity(
      this.productsBySubType,
      item
    );
  }

  updatecartQuantityList(pLIst) {
    return this.cartService.updateSubcartQuantity(pLIst);
  }

  filterFarmProduct(farmResp, farmId, fmName) {
    this.farmByFarmId = [];
    this.farmList = [];
    let myfromProducts = [];

    if (farmId !== "All") {
      for (let productsByFarm1 of farmResp) {
        myfromProducts.push(productsByFarm1);
        this.farmList.push(productsByFarm1);
      }
    } else {
      myfromProducts = farmResp;
      this.farmList = farmResp;
    }
    let myfromProductsMapping = { farmName: fmName };
    myfromProductsMapping[fmName] = myfromProducts;
    this.farmByFarmId.push(myfromProductsMapping);
  }

  removeDuplicatesByFarmId(keyFn, array) {
    var mySet = new Set();
    return array.filter(function (x) {
      var key = keyFn(x),
        isNew = !mySet.has(key);
      if (isNew) mySet.add(key);
      return isNew;
    });
  }

  getProductsByFarmId(farmId) {
    this.filterFarmProduct(this.productsBySubType, farmId, farmId);
  }

  imageconvertor(data) {
    let objectURL = "data:image/png;base64," + data;
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  openSideFilter() {
    document.getElementById("responsive_open").style.display = "block";
  }
  closeNav() {
    document.getElementById("responsive_open").style.display = "none";
  }

  farmcheckValue(event: any) {
    if (event) {
      let selectAll = false;
      let farmidArray = event.split("-");
      if (farmidArray[0] && farmidArray[0] !== "All") {
        if (farmidArray[1] && farmidArray[1] === "true") {
          this.allSelectedList.push(farmidArray[0]);
        } else {
          const index: number = this.allSelectedList.indexOf(farmidArray[0]);
          if (index !== -1) {
            this.allSelectedList.splice(index, 1);
          }
          if (this.allSelectedList.length == 0) {
            //all checkbox unselected
            selectAll = true;
          }
        }
        this.fetchSelectedProducts(this.allSelectedList);
        if (selectAll) {
          // after all on selected ,make
          this.addProducts(this.productsBySubType, "All", "All");
        }
      } else {
        this.farmByFarmId = this.productsBySubType;
      }
    }
  }
  getAllCheckBoxList(uformlist) {
    for (let productsByFarm1 of uformlist) {
      this.alluniqueFarmCheckboxList.push({
        farm_Id: productsByFarm1.farm_Id,
        farm_Name: productsByFarm1.farm_Name,
        farm_Short_Name: productsByFarm1.farm_Short_Name,
        isChecked: false
      });
    }
  }

  fetchSelectedProducts(selectedList) {
    this.farmByFarmId = [];
    for (let farmid of selectedList) {
      let res = this.productsBySubType.filter(it => it.farm_Id === farmid);
      let uniqformType = this.alluniqueFarmCheckboxList.filter(
        it => it.farm_Id === farmid
      );
      this.addProducts(
        res,
        uniqformType[0].farm_Short_Name,
        uniqformType[0].farm_Name
      );
    }
  }
  addProducts(prodList, fmName, fmFullName) {
    let myfromProductsMapping = { farmName: fmName, farmFullName: fmFullName };
    myfromProductsMapping[fmName] = prodList;

    this.farmByFarmId.push(myfromProductsMapping);
  }

  fbiteamAddvisible(itemid) {
    let elemop: Element = document.getElementById(itemid);
    if (elemop) {
      elemop.classList.add("css-xupwym-visible");
    }
  }

  fbitemRemoveVisibility(itemid) {
    let remele: Element = document.getElementById(itemid);
    if (remele) {
      remele.classList.remove("css-xupwym-visible");
    }
  }

  cditemAddOpac(itemid) {
    let elem: Element = document.getElementById(itemid);
    if (elem) {
      elem.classList.add("css-content-opac");
    }
  }

  cditemRemoveOpac(itemId) {
    let remele: Element = document.getElementById(itemId);
    if (remele) {
      remele.classList.remove("css-content-opac");
    }
  }
  contentDivHasOpac(divid) {
    let elemop: Element = document.getElementById("p" + divid);
    if (elemop) {
      if (elemop.classList.contains("css-content-opac")) {
        return true;
      }
    }
    return false;
  }

  updatecartItems(itemid, type) {
    this.cartService.updatecartItems(itemid, type);
  }

  openAddtoCartPopup() {
    this.routerService.routeToAddToCart();
  }

  public searchCall(data, isclose) {
    this.cartService.searchCall(data, isclose, "subpid");
  }

  changedCartData() {
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  }

  //new Changes
  updateSelectedProduct(index) {
    for (let obj of this.productsBySubType) {
      if (typeof obj.selected_inventory_Id === "undefined") {
        let cartQtt = obj.product_Qty_Details[index].cart_Quantity;
        obj.product_Qty_Details[index]["selected"] = true;
        let selectedP = {
          inventory_Id: obj.product_Qty_Details[index].inventory_Id,
          sell_Price: obj.product_Qty_Details[index].sell_Price,
          allfresh_Price: obj.product_Qty_Details[index].allfresh_Price,
          discount_Percentage: obj.product_Qty_Details[index].discount_Percentage,
          cart_Quantity: cartQtt ? cartQtt : 0,
          stock_Avlbl: obj.product_Qty_Details[index].stock_Avlbl
        };
        obj["selected_inventory_Id"] = selectedP;
      }

    }
  }

  onChangeSUbProductquantyDesc(invid, index) {

    for (let obj of this.productsBySubType) {
      let allchildIdstohide = [];
      if (obj.inventory_Id === invid) {
        let cartQtt = obj.product_Qty_Details[index].cart_Quantity;
        let selectedInventoryId = obj.product_Qty_Details[index].inventory_Id;
        for (let childP of obj.product_Qty_Details) {
          if (selectedInventoryId == childP.inventory_Id && childP.cart_Quantity === 0) {
            this.hidezeroQnty_Invid = 'p' + childP.inventory_Id;
          }
        }
        let selectedP = {
          inventory_Id: obj.product_Qty_Details[index].inventory_Id,
          sell_Price: obj.product_Qty_Details[index].sell_Price,
          allfresh_Price: obj.product_Qty_Details[index].allfresh_Price,
          discount_Percentage: obj.product_Qty_Details[index].discount_Percentage,
          cart_Quantity: cartQtt ? cartQtt : 0,
          stock_Avlbl: obj.product_Qty_Details[index].stock_Avlbl
        };
        obj["selected_inventory_Id"] = selectedP;
      }

      this.removeVisibilityItem(invid);
    }

  }

  openProductDescView(productid): void {
    this.routerService.routeToViewProductDesc(productid);
    // let currenturl = window.location.href;
    // let url = this.RemoveLastDirectoryPartOf(currenturl);
    // const finalurl = url + this.router.serializeUrl(
    //   this.router.createUrlTree(['/printInvoice'])
    // );
    // window.open(finalurl);
  }

  selectDiv(divid) {
    this.addVisibilityToItem("p" + divid);
  }

  addVisibilityToItem(itemid) {
    // this.cartService.hideItem(this.previousitemdiv, itemid);
    this.cartService.addVisibilityToItem(itemid);
    this.previousitemdiv = itemid;
  }
  removeVisibilityItem(itemid) {
    this.cartService.hideItem(this.previousitemdiv, itemid);
    // this.cartService.addVisibilityToItem(itemid);
    this.previousitemdiv = itemid;
  }

  openAddtoCartDialog(uniqueId) {
    let sitem;
    for (let fpp of this.productsBySubType) {
      for (let pqt of fpp["product_Qty_Details"]) {
        if (uniqueId === pqt["inventory_Id"]) {
          sitem = pqt;
          sitem["farm_Short_Name"] = fpp['farm_Short_Name'];
          sitem["product_Name"] = fpp['product_Name'];
          sitem["product_Image"] = fpp['product_Image'][0];
          sitem["farm_Id"] = fpp["farm_Id"];
          if (fpp.selected_inventory_Id.cart_Quantity == 0) {
            this.cartService.addtoCart(sitem);
            this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "add");
          }
        }
      }
    }
    this.selectDiv(uniqueId);
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  }

  onClickIncrement(uniqueId, type, formName) {
    for (let fpp of this.productsBySubType) {
      for (let pqt of fpp["product_Qty_Details"]) {
        if (uniqueId === pqt["inventory_Id"]) {
          this.cartService.onClickIncrementNew(uniqueId, pqt);
          this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "add");
          this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
          const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === pqt.inventory_Id);
          if (resultItem && resultItem.length == 1 && type === 'subp') {
            this.productsBySubType = this.cartService.updateSubcartQuantityByDesc(
              this.productsBySubType, resultItem[0]
            );
          }
          else if (formName === 'All') {
            let idx = this.farmByFarmId[0][formName].indexOf(resultItem[0]);
            this.farmByFarmId[0][formName][idx].cart_Quantity = resultItem[0].cart_Quantity;
          }
        }
      }
    }
  }

  onClickDecrement(uniqueId, type, qtt, formName) {

    for (let fpp of this.productsBySubType) {
      for (let pqt of fpp["product_Qty_Details"]) {
        if (uniqueId === pqt["inventory_Id"]) {
          this.cartService.onClickDecrement(uniqueId, type);
          this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "remove");
          this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
          const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === pqt.inventory_Id);
          if (resultItem && resultItem.length == 1 && type === "subp") {
            this.productsBySubType = this.cartService.updateSubcartQuantityByDesc(
              this.productsBySubType, resultItem[0]
            );
          }
          if (qtt == 1) {
            this.cartService.hidecurrentItem("p" + uniqueId);
          }
        }
      }
    }
  }

  openOutOfStockPopUp() {
    const modalRef = this.modalService.open(AlertComponent,
      { size: 'sm', backdrop: 'static', centered: true });
    modalRef.componentInstance.msg = "This Product is Out-of-Stock";
  }
  
}
