import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class FeaturedProductService {

  currentNameSubject$ = new BehaviorSubject("");
  featuredproduct:any;
  prodctDescResp:any;

  constructor(private httpClient: HttpClient) { }
  
  allFeaturedProducts(delloc){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getFeatureProductsLocationEnhanced/'+ delloc, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.featuredproduct = Resp;
      this.currentNameSubject$.next(this.featuredproduct);
   }))
  }  

  allProductsById(delloc, pId){
    return this.httpClient.get(AppComponent.API_ENDPOINT+'/getProductsById/'+ delloc + "/" + pId, {
       headers: new HttpHeaders()
         .set('content-type', `application/json`)
     }).pipe(tap(Resp => {
      this.prodctDescResp = Resp;
      this.currentNameSubject$.next(this.prodctDescResp);
   }))
  }  
}
