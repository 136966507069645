<div class="css-11zk6ke" id="myratingid">
    <div class="card">
        <h2 class="card-header">My Ratings</h2>
        <div class="card-body">
            <div class="card">
                <div class="row">
                    <div class="col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <h3 class="css-cardheader">To Be Rated</h3>
                        <div class="card-body mb-1 rgba-grey-light white-text">
                            <div class="css-mjrs0s">
                                <div class="css-vurnku">
                                    <div class="row" *ngFor="let ratetoberoducts of ratingToBeProducts; let index = i;">
                                        <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <span class="css-datespan"> Bought on {{ratetoberoducts.bought_Date | date:
                                                'dd/MM/yyyy'}}</span>
                                        </div>
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngFor="let rtbProduct of ratetoberoducts['productReviewItemDetails'];">
                                            <div class="row">
                                                <div class=" col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 css-kgvnaj">
                                                    <div class="css-1kxr47f">
                                                        <div class="gatsby-image-wrapper" style="position: relative; overflow: hidden;">
                                                            <div aria-hidden="true" style="width: 100%; padding-bottom: 100%;"></div>
                                                            <img aria-hidden="true" [src]="rtbProduct.product_Image" alt="" class="" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; object-fit: cover; object-position: center center;">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5">
                                                    <div class="css-vurnku">
                                                        <div class="white-text font-thin css-1q9j665"><b>{{rtbProduct.farm_Name}}</b></div>
                                                        <div class="white-text font-thin css-1facnq0">{{rtbProduct.product_Name}}</div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                    <h1 class="text-primary star-responsive">
                                                        <ngb-rating [max]="5" [(ngModel)]="rtbProduct.review_Stars" (click)="rateCount(rtbProduct,ratetoberoducts._id)" [readonly]="false" style="color: #6db500;"></ngb-rating>
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col d-flex justify-content-center">
                                        <mat-spinner *ngIf="hideSpinner"></mat-spinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <h3 class="css-cardheader">Rated</h3>
                        <div class="card-body mb-1 rgba-grey-light white-text">
                            <div class="css-mjrs0s">
                                <div class="css-vurnku">
                                    <div class="row" *ngFor="let ratedProduct of ratedProductsResp">
                                        <div class=" col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <span class="css-datespan"> Bought on {{ratedProduct.bought_Date | date:
                                                'dd/MM/yyyy'}}</span>
                                        </div>
                                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngFor="let rProduct of ratedProduct['productReviewItemDetails'];">
                                            <div class="row">
                                                <div class=" col-6 col-xs-3 col-sm-3 col-md-3 col-lg-3 css-kgvnaj">
                                                    <div class="css-1kxr47f">
                                                        <div class="gatsby-image-wrapper" style="position: relative; overflow: hidden;">
                                                            <div aria-hidden="true" style="width: 100%; padding-bottom: 100%;"></div>
                                                            <img aria-hidden="true" [src]="rProduct.product_Image" alt="" class="" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; object-fit: cover; object-position: center center;">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-xs-3 col-sm-3 col-md-5 col-lg-5">
                                                    <div class="css-vurnku">
                                                        <div class="white-text font-thin css-1q9j665"><b>{{rProduct.farm_Name}}</b></div>
                                                        <div class="white-text font-thin css-1facnq0">{{rProduct.product_Name}}</div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-4">
                                                    <h1 class="text-primary">
                                                        <ngb-rating [max]="5" [(rate)]="rProduct.review_Stars" [readonly]="true" style="color: #6db500;"></ngb-rating>
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col d-flex justify-content-center">
                                        <mat-spinner *ngIf="hideSpinner1"></mat-spinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>