import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cancel-order-modal',
  templateUrl: './cancel-order-modal.component.html',
  styleUrls: ['./cancel-order-modal.component.css']
})
export class CancelOrderModalComponent implements OnInit {

  @Input() name;
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onClickYes(){
    this.activeModal.close('Yes');

  }
  onClickNo(){
    this.activeModal.close('No');
  }
}
