import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  currentNameSubject$ = new BehaviorSubject("");
  getUserByIdResp: any;

  updateUserByIdResp: any;
  updateDelvAddressResp: any;
  deleteDelvAddressResp: any;

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getUserById(userId) {
    const headers = new HttpHeaders()
      .set('content-type', `application/json`)
      .set('Authorization', `Bearer ${this.authService.getBearerToken()}`)
      .set('userid', window.localStorage.getItem("userId"))
      .set('Access-Control-Allow-Origin', '*');

    return this.httpClient.get(AppComponent.USER_API_ENDPOINT + 'getUserById/' + userId, {
      headers: headers
    }).pipe(tap(Resp => {
      this.getUserByIdResp = Resp;
      this.currentNameSubject$.next(this.getUserByIdResp);
    }))
  }
  updateUserById(userInfoObj) {
    const headers = new HttpHeaders()
      .set('content-type', `application/json`)
      .set('Authorization', `Bearer ${this.authService.getBearerToken()}`)
      .set('userid', window.localStorage.getItem("userId"))
      .set('Access-Control-Allow-Origin', '*');

    return this.httpClient.post(AppComponent.USER_API_ENDPOINT + 'updateuser/', userInfoObj, {
      headers: headers
    }).pipe(tap(Resp => {
      this.updateUserByIdResp = Resp;
      this.currentNameSubject$.next(this.updateUserByIdResp);
    }))
  }
  updateDeliveryAddress(userAddressObj) {
    const headers = new HttpHeaders()
      .set('content-type', `application/json`)
      .set('Authorization', `Bearer ${this.authService.getBearerToken()}`)
      .set('userid', window.localStorage.getItem("userId"))
      .set('Access-Control-Allow-Origin', '*');

    return this.httpClient.post(AppComponent.USER_API_ENDPOINT + 'updateDelAddress/', userAddressObj, {
      headers: headers
    }).pipe(tap(Resp => {
      this.updateDelvAddressResp = Resp;
      this.currentNameSubject$.next(this.updateDelvAddressResp);
    }))
  }
  deleteDeliveryAddress(addressObj) {
    const headers = new HttpHeaders()
      .set('content-type', `application/json`)
      .set('Authorization', `Bearer ${this.authService.getBearerToken()}`)
      .set('userid', window.localStorage.getItem("userId"))
      .set('Access-Control-Allow-Origin', '*');

    return this.httpClient.post(AppComponent.USER_API_ENDPOINT + 'deleteDelAddress/', addressObj, {
      headers: headers
    }).pipe(tap(Resp => {
      this.deleteDelvAddressResp = Resp;
      this.currentNameSubject$.next(this.deleteDelvAddressResp);
    }))
  }
}
