import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dod-dialog-msg',
  templateUrl: './dod-dialog-msg.component.html',
  styleUrls: ['./dod-dialog-msg.component.css']
})
export class DodDialogMSGComponent implements OnInit {
  
  @Input() public dodVal;
  @Input() public totalchagesWithOutDod;

  restAmountToplaceOrderWithDod:number;

  constructor(public activeModal: NgbActiveModal) {  }

  ngOnInit(): void {
    this.restAmountToplaceOrderWithDod = this.dodVal - this.totalchagesWithOutDod;
  }

  OnCLickOk() {
    this.activeModal.close("Success");
  }
}
