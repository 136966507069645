import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  Input,
  NgZone
} from "@angular/core";
import { FeaturedProductService } from "../services/featured-product.service";
import { DomSanitizer } from "@angular/platform-browser";
import { TrendingProductService } from "../services/trending-product.service";
import { LatestproductService } from "../services/latestproduct.service";
import { RouterService } from "../services/router.service";
import { CartService } from "../services/cart.service";
import { OrderService } from "../services/order.service";
import { SharedmobService } from "../services/sharedmob.service";
import { ProductsBySubTypeService } from "../services/products-by-sub-type.service";
import { DealOfTheDayService } from "../services/deal-of-the-day.service";
import { LocationService } from "../services/location.service";
import { MatDialog } from "@angular/material/dialog";
import { RulesService } from "../services/rules.service";
import { ProductDescViewComponent } from "../product-desc-view/product-desc-view.component";
import { AppComponent } from '../app.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AlertComponent } from '../alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: [
    "./home.component.css",
    "./home.component1.css",
    "./home.component2.css",
    "./home.component3.css"
  ]
})
export class HomeComponent implements OnInit {
  placeOrder: string = "Place to Order";
  bodyText: string;
  hideSpinner = true;
  fphideSpinner = true;
  tphideSpinner = true;
  lphideSpinner = true;
  searchKey: any;

  count: number;
  divshow: boolean;
  cartdiv: boolean;
  subTypeData: any;

  errMessage: string;
  featuredProducts: any;
  trendingProducts: any;
  latestProducts: any;
  DODProducts: any;
  productListById: any = [];

  addToCartArray: any = [];
  farmObj: any;
  totalPrice: number = 0;
  @Input() allCartItems: any[];
  productCartObj: any;
  openedcartdiv: string = "na";
  previouscartdiv: string = "na";

  clickedcddiv: string = "na";
  previouscddiv: string = "na";

  previousitemdiv: string;
  public cartCheckoutWindow: any;
  allVegProducts: any = [];
  allFruitsProducts: any = [];
  allOilsProducts: any = [];
  allDairyAndEggsProducts: any = [];
  allMilletsAndSeedsProducts: any = [];
  allFlowersProducts: any = [];

  dodHeaderMsg: string;

  locationsList: any;
  delLoc: string;
  homebnrList: any;

  sellPrice: number = 0;
  allfreshPrice: number = 0;
  discountPercentage: string;

  selectedItemInvId: any;
  productQtyDetails: any;
  productQtyDetailsShow: any;

  hidezeroQnty_Invid: any = "";

  constructor(
    private el: ElementRef,
    private featureproductservice: FeaturedProductService,
    private trendingproductservice: TrendingProductService,
    private latestproductservice: LatestproductService,
    private ruleService: RulesService,
    private sanitizer: DomSanitizer,
    private routerService: RouterService,
    private cartService: CartService,
    private sharedService: SharedmobService,
    private dodService: DealOfTheDayService,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService, private modalService: NgbModal) {
    this.sharedService.currentCount.subscribe(c => (this.count = c));
    this.sharedService.currentTotalPrice.subscribe(
      tp => (this.totalPrice = tp)
    );
  }

  ngOnInit() {
    this.sharedService.currentSearch.subscribe(c => (this.searchKey = c));
    let allCartItemFromSessionInHome = JSON.parse(
      window.localStorage.getItem("cartItemObj")
    );

    if (allCartItemFromSessionInHome) {
      this.sharedService.currentCount.subscribe(c => (this.count = c));
      this.sharedService.currentTotalPrice.subscribe(
        tp => (this.totalPrice = tp)
      );

      this.allCartItems = allCartItemFromSessionInHome;

      this.cartService.getCartItemCountandPrice(this.allCartItems);
    } else {
      allCartItemFromSessionInHome = [];
    }
    this.bodyText = "1";

    this.delLoc = window.localStorage.getItem("delLoc");
    if (this.delLoc) {
      this.AllTheProrducts(this.delLoc);
    }

    //all charges
    let allrules = JSON.parse(window.localStorage.getItem("sCharges"));
    if (allrules) {
      this.dodHeaderMsg = allrules["dod_condition_message"];
    }
  }

  ngAfterViewChecked() {
    if (this.hidezeroQnty_Invid) {
      let remele: Element = document.getElementById(this.hidezeroQnty_Invid);
      if (remele) {
        remele.classList.remove("css-xupwym-visible");
      }
    }
    this.hidezeroQnty_Invid = "";
  }

  public AllTheProrducts(delLoc) {
    //Feature Product
    this.getAllFeaturedProducts(delLoc);

    //Trending Product
    this.getAllTrendingProducts(delLoc);

    //Latest Product
    this.getAllLatestProducts(delLoc);

    //Deal of the day products
    this.getAllDealOfTheDayProducts(delLoc);

    this.getBannerList();
  }

  getBannerList() {
    this.homebnrList = [];
    this.ruleService.hoemBannerList().subscribe(
      resp => {
        this.homebnrList = resp["header"];
      },
      err => {
        this.errMessage = err.error.message;
      }
    );
  }
  onClickHomeCourisal(obj) {
    if (obj.product_Type && obj.product_Sub_Type) {
      let ptype = this.getcourisalProdTyep(obj.product_Type);
      let subptype = this.getcourisalSubProdTyep(obj.product_Sub_Type);
      this.routerService.routeToAllSubProduct(ptype, subptype);
    }

  }

  getcourisalProdTyep(pType) {
    let pt = "";
    switch (pType) {
      case 'V':
        pt = "Vegetables";
        break;
      case 'F':
        pt = "Fruits";
        break;
      case 'O':
        pt = "Oils";
        break;

      case 'D':
        pt = "Grocery&Eggs";
        break;

      case 'M':
        pt = "Millets&Seeds";
        break;

      case 'FL':
        pt = "Flowers";
        break;

    }
    return pt;
  }



  getcourisalSubProdTyep(subPType) {
    let spt = "";
    switch (subPType) {
      case 'AV':
        spt = "All-Vegetables";
        break;

      case 'EV':
        spt = "Exotic-Vegetables";
        break;

      case 'FV':
        spt = "Fresh-Vegetables";
        break;

      case 'HS':
        spt = "Herbs&Seasoning-Vegetables";
        break;

      case 'OV':
        spt = "Organic-Vegetables";
        break;

      case 'AF':
        spt = "All-Fruits";
        break;

      case 'FF':
        spt = "Fresh-Fruits";
        break;

      case 'EF':
        spt = "Exotic-Fruits";
        break;

      case 'CF':
        spt = "Cut-Fruits";
        break;

      case 'AO':
        spt = "AllColdPressed-Oils";
        break;

      case 'CO':
        spt = "ColdPressed-Oils";
        break;

      case 'AD':
        spt = "All-Grocery&Eggs";
        break;

      case 'DY':
        spt = "Grocery";
        break;

      case 'EG':
        spt = "Dairy&Eggs";
        break;

      case 'BD':
        spt = "Biodegradable";
        break;
      case 'AM':
        spt = "All-Millets&Seeds";
        break;

      case 'IB':
        spt = "ImmuneBoosterMillets";
        break;

      case 'SD':
        spt = "Seeds";
        break;

      case 'AFL':
        spt = "All-Flowers&PotPlants";
        break;

      case 'RS':
        spt = "Roses,Marigolds&Gerberas";
        break;

      case 'MG':
        spt = "Lilies&Caronations";
        break;

      case 'GB':
        spt = "Exotic-Flowers";
        break;

      case 'CH':
        spt = "Premium-Flowers";
        break;

      case 'PT':
        spt = "Pot-Plants";
        break;

    }
    return spt;
  }


  getAllFeaturedProducts(delLoc) {
    this.featuredProducts = [];
    this.featureproductservice.allFeaturedProducts(delLoc).subscribe(
      resp => {
        this.featuredProducts = resp;
        this.fphideSpinner = false;
        if (this.featuredProducts && this.featuredProducts.length > 0) {
          this.updateSelectedProduct("fp", 0);
        }
        if (this.allCartItems && this.allCartItems.length > 0) {
          this.updateQtForAll("fp");
          this.featuredProducts = this.updatecartQuantityList(this.featuredProducts);
        }
      },
      err => {
        this.errMessage = err.error.message;
      }
    );
  }
  getAllTrendingProducts(delLoc) {
    this.trendingProducts = [];
    this.trendingproductservice.allTrendingProducts(delLoc).subscribe(
      resp => {
        this.trendingProducts = resp;
        this.tphideSpinner = false;
        if (this.trendingProducts && this.trendingProducts.length > 0) {
          this.updateSelectedProduct("tp", 0);
        }
        if (this.allCartItems && this.allCartItems.length > 0) {
          this.updateQtForAll("tp");
          this.trendingProducts = this.updatecartQuantityList(this.trendingProducts);
        }
      },
      err => {
        this.errMessage = err.error.message;
      }
    );
  }

  getAllLatestProducts(delLoc) {
    this.latestProducts = [];
    this.latestproductservice.allLatestProducts(delLoc).subscribe(
      resp => {
        this.latestProducts = resp;
        this.lphideSpinner = false;
        if (this.latestProducts && this.latestProducts.length > 0) {
          this.updateSelectedProduct("lp", 0);
        }
        if (this.allCartItems && this.allCartItems.length > 0) {
          this.updateQtForAll("lp");
          this.latestProducts = this.updatecartQuantityList(this.latestProducts);
        }
      },
      err => {
        this.errMessage = err.error.message;
      }
    );
  }

  getAllDealOfTheDayProducts(delLoc) {
    this.DODProducts = [];
    this.dodService.allDODProducts(delLoc).subscribe(
      resp => {
        this.DODProducts = resp;
        this.hideSpinner = false;
        if (this.DODProducts && this.DODProducts.length > 0) {
          this.updateSelectedProduct("dod", 0);
        }
        if (this.allCartItems && this.allCartItems.length > 0) {
          this.updateQtForAll("dodp");
          this.DODProducts = this.updatecartQuantityList(this.DODProducts);
        }
      },
      err => {
        this.errMessage = "Dod product does not loaded";
      }
    );
  }

  updateQtForAll(type) {
    for (let item of this.allCartItems) {
      if (type === "tp") {
        this.updateTPcartQuantity(item);
      } else if (type === "fp") {
        this.updateFPcartQuantity(item);
      } else if (type === "lp") {
        this.updateLPcartQuantity(item);
      } else if (type === "dodp") {
        this.updateDODPcartQuantity(item);
      }
    }
  }

  updateFPcartQuantity(item) {
    this.featuredProducts = this.cartService.updatecartQuantity(this.featuredProducts, item);
  }

  updateTPcartQuantity(item) {
    // this.trendingProducts = this.cartService.updatecartQuantity(this.trendingProducts, item);
    this.trendingProducts = this.cartService.updatecartQuantity(this.trendingProducts, item);
  }

  updateLPcartQuantity(item) {
    this.latestProducts = this.cartService.updatecartQuantity(this.latestProducts, item);
  }

  updateDODPcartQuantity(item) {
    this.DODProducts = this.cartService.updatecartQuantity(this.DODProducts, item);
  }

  imageconvertor(data) {
    let objectURL = "data:image/png;base64," + data;
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  // onClickVegSubProduct(menutype,subType) {
  //   this.routerService.routeToVegAllSubProduct(menutype,subType);
  // }

  onClickVegSubProduct(menutype, subType) {
    this.routerService.routeToAllSubProduct(menutype, subType);
  }

  onClickFruitsSubProduct(menutype, subType) {
    this.routerService.routeToAllSubProduct(menutype, subType);
  }

  onClickOilsSubProduct(menutype, subType) {
    this.routerService.routeToAllSubProduct(menutype, subType);
  }

  onClickDairyAndEggsSubProduct(menutype, subType) {
    this.routerService.routeToAllSubProduct(menutype, subType);
  }
  onClickMNSSubProduct(menutype, subType) {
    this.routerService.routeToAllSubProduct(menutype, subType);
  }

  onClickFlowersSubProduct(menutype, subType) {
    this.routerService.routeToAllSubProduct(menutype, subType);
  }

  onDODIncrement() {
    this.cartService.openMaxQuantityAddedPopUp(1);
  }

  openAddtoCartPopup() {
    this.routerService.routeToAddToCart();
  }

  getFarmObjByFarmId(type, uniqeid) {
    this.farmObj = {};
    let productresp = [];
    if (type === "fp") {
      for (let fpp of this.featuredProducts) {
        for (let pqt of fpp["product_Qty_Details"]) {
          if (uniqeid === pqt["inventory_Id"]) {
            this.farmObj = pqt;
          }
        }
      }
    }
    if (type === "tp") {
      for (let tpp of this.trendingProducts) {
        for (let pqt of tpp["product_Qty_Details"]) {
          if (uniqeid === pqt["inventory_Id"]) {
            this.farmObj = pqt;
            this.farmObj["product_Image"] = tpp.product_Image;
            this.farmObj["farm_Short_Name"] = tpp.farm_Short_Name;
            this.farmObj["product_Name"] = tpp.product_Name;
          }
        }
      }
    }
    if (type === "lp") {
      for (let lpp of this.latestProducts) {
        for (let pqt of lpp["product_Qty_Details"]) {
          if (uniqeid === pqt["inventory_Id"]) {
            this.farmObj = pqt;
          }
        }
      }
    }
    if (type === "dodp") {
      productresp = this.DODProducts;
    }
  }

  scrollToElement($element): void {
    var element = document.getElementById("fp");
    var headerOffset = 120;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  scrollToElementUp(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }
  public searchCall(data, isclose) {
    this.cartService.searchCall(data, isclose, "homeid");
  }

  changedCartData() {
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  }

  // new changes

  updatecartQuantityList(pLIst) {
    return this.cartService.updateSubcartQuantity(pLIst);
  }

  openProductDescView(productid): void {
    this.routerService.routeToViewProductDesc(productid);
  }

  updateSelectedProduct(type, index) {
    if (type === "tp") {
      for (let obj of this.trendingProducts) {
        if (typeof obj.selected_inventory_Id === "undefined") {
          let cartQtt = obj.product_Qty_Details[index].cart_Quantity;
          obj.product_Qty_Details[index]["selected"] = true;
          let selectedP = {
            inventory_Id: obj.product_Qty_Details[index].inventory_Id,
            sell_Price: obj.product_Qty_Details[index].sell_Price,
            allfresh_Price: obj.product_Qty_Details[index].allfresh_Price,
            discount_Percentage:
              obj.product_Qty_Details[index].discount_Percentage,
            cart_Quantity: cartQtt ? cartQtt : 0,
            stock_Avlbl: obj.product_Qty_Details[index].stock_Avlbl
          };
          obj["selected_inventory_Id"] = selectedP;
        }
      }
    }
    else if (type === "fp") {
      for (let obj of this.featuredProducts) {
        if (typeof obj.selected_inventory_Id === "undefined") {
          let cartQtt = obj.product_Qty_Details[index].cart_Quantity;
          obj.product_Qty_Details[index]["selected"] = true;
          let selectedP = {
            inventory_Id: obj.product_Qty_Details[index].inventory_Id,
            sell_Price: obj.product_Qty_Details[index].sell_Price,
            allfresh_Price: obj.product_Qty_Details[index].allfresh_Price,
            discount_Percentage:
              obj.product_Qty_Details[index].discount_Percentage,
            cart_Quantity: cartQtt ? cartQtt : 0,
            stock_Avlbl: obj.product_Qty_Details[index].stock_Avlbl
          };
          obj["selected_inventory_Id"] = selectedP;
        }
      }
    }
    else if (type === "lp") {
      for (let obj of this.latestProducts) {
        if (typeof obj.selected_inventory_Id === "undefined") {
          let cartQtt = obj.product_Qty_Details[index].cart_Quantity;
          obj.product_Qty_Details[index]["selected"] = true;
          let selectedP = {
            inventory_Id: obj.product_Qty_Details[index].inventory_Id,
            sell_Price: obj.product_Qty_Details[index].sell_Price,
            allfresh_Price: obj.product_Qty_Details[index].allfresh_Price,
            discount_Percentage:
              obj.product_Qty_Details[index].discount_Percentage,
            cart_Quantity: cartQtt ? cartQtt : 0,
            stock_Avlbl: obj.product_Qty_Details[index].stock_Avlbl
          };
          obj["selected_inventory_Id"] = selectedP;
        }
      }
    }
    else if (type === "dod") {
      for (let obj of this.DODProducts) {
        if (typeof obj.selected_inventory_Id === "undefined") {
          let cartQtt = obj.product_Qty_Details[index].cart_Quantity;
          obj.product_Qty_Details[index]["selected"] = true;
          let selectedP = {
            inventory_Id: obj.product_Qty_Details[index].inventory_Id,
            sell_Price: obj.product_Qty_Details[index].sell_Price,
            allfresh_Price: obj.product_Qty_Details[index].allfresh_Price,
            discount_Percentage: obj.product_Qty_Details[index].discount_Percentage,
            cart_Quantity: cartQtt ? cartQtt : 0,
            stock_Avlbl: obj.product_Qty_Details[index].stock_Avlbl
          };
          obj["selected_inventory_Id"] = selectedP;
        }
      }
    }
  }

  onChangeProductquantyDesc(invid, index, type) {
    if (type === "fp") {
      for (let obj of this.featuredProducts) {
        if (obj.inventory_Id === invid) {
          let cartQtt = obj.product_Qty_Details[index].cart_Quantity;
          let selectedInventoryId = obj.product_Qty_Details[index].inventory_Id;
          for (let childP of obj.product_Qty_Details) {
            if (selectedInventoryId == childP.inventory_Id && childP.cart_Quantity === 0) {
              this.hidezeroQnty_Invid = 'p' + childP.inventory_Id;
            }
          }
          let selectedP = {
            inventory_Id: obj.product_Qty_Details[index].inventory_Id,
            sell_Price: obj.product_Qty_Details[index].sell_Price,
            allfresh_Price: obj.product_Qty_Details[index].allfresh_Price,
            discount_Percentage: obj.product_Qty_Details[index].discount_Percentage,
            cart_Quantity: cartQtt ? cartQtt : 0,
            stock_Avlbl: obj.product_Qty_Details[index].stock_Avlbl
          };
          obj["selected_inventory_Id"] = selectedP;
        }
        this.removeVisibilityItem(invid);
      }
    }
    else if (type === "tp") {
      for (let obj of this.trendingProducts) {
        if (obj.inventory_Id === invid) {
          let cartQtt = obj.product_Qty_Details[index].cart_Quantity;
          let selectedInventoryId = obj.product_Qty_Details[index].inventory_Id;
          for (let childP of obj.product_Qty_Details) {
            if (selectedInventoryId == childP.inventory_Id && childP.cart_Quantity === 0) {
              this.hidezeroQnty_Invid = 'p' + childP.inventory_Id;
            }
          }
          let selectedP = {
            inventory_Id: obj.product_Qty_Details[index].inventory_Id,
            sell_Price: obj.product_Qty_Details[index].sell_Price,
            allfresh_Price: obj.product_Qty_Details[index].allfresh_Price,
            discount_Percentage: obj.product_Qty_Details[index].discount_Percentage,
            cart_Quantity: cartQtt ? cartQtt : 0,
            stock_Avlbl: obj.product_Qty_Details[index].stock_Avlbl
          };
          obj["selected_inventory_Id"] = selectedP;
        }
        this.removeVisibilityItem(invid);
      }
    }
    else if (type === "lp") {
      for (let obj of this.latestProducts) {
        if (obj.inventory_Id === invid) {
          let cartQtt = obj.product_Qty_Details[index].cart_Quantity;
          let selectedInventoryId = obj.product_Qty_Details[index].inventory_Id;
          for (let childP of obj.product_Qty_Details) {
            if (selectedInventoryId == childP.inventory_Id && childP.cart_Quantity === 0) {
              this.hidezeroQnty_Invid = 'p' + childP.inventory_Id;
            }
          }
          let selectedP = {
            inventory_Id: obj.product_Qty_Details[index].inventory_Id,
            sell_Price: obj.product_Qty_Details[index].sell_Price,
            allfresh_Price: obj.product_Qty_Details[index].allfresh_Price,
            discount_Percentage: obj.product_Qty_Details[index].discount_Percentage,
            cart_Quantity: cartQtt ? cartQtt : 0,
            stock_Avlbl: obj.product_Qty_Details[index].stock_Avlbl
          };
          obj["selected_inventory_Id"] = selectedP;
        }
        this.removeVisibilityItem(invid);
      }
    }
  }

  selectDiv(divid) {
    this.addVisibilityToItem("p" + divid);
  }
  addVisibilityToItem(itemid) {
    // this.cartService.hideItem(this.previousitemdiv, itemid);
    this.cartService.addVisibilityToItem(itemid);
    this.previousitemdiv = itemid;
  }
  removeVisibilityItem(itemid) {
    this.cartService.hideItem(this.previousitemdiv, itemid);
    // this.cartService.addVisibilityToItem(itemid);
    this.previousitemdiv = itemid;
  }

  openAddtoCartDialogNew(uniqueId, type) {
    let sitem;
    if (type === "tp") {
      for (let fpp of this.trendingProducts) {
        for (let pqt of fpp["product_Qty_Details"]) {
          if (uniqueId === pqt["inventory_Id"]) {
            if (pqt["stock_Avlbl"]) {
              sitem = pqt;
              sitem["farm_Short_Name"] = fpp["farm_Short_Name"];
              sitem["product_Name"] = fpp["product_Name"];
              sitem["product_Image"] = fpp["product_Image"][0];
              sitem["farm_Id"] = fpp["farm_Id"];
              if (fpp.selected_inventory_Id.cart_Quantity == 0) {
                this.cartService.addtoCart(sitem);
                this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "add");
              }
            }
          }
        }
      }
      this.selectDiv(uniqueId);
    }
    else if (type === "fp") {
      for (let fpp of this.featuredProducts) {
        for (let pqt of fpp["product_Qty_Details"]) {
          if (uniqueId === pqt["inventory_Id"]) {
            if (pqt["stock_Avlbl"]) {
              sitem = pqt;
              sitem["farm_Short_Name"] = fpp["farm_Short_Name"];
              sitem["product_Name"] = fpp["product_Name"];
              sitem["product_Image"] = fpp["product_Image"][0];
              sitem["farm_Id"] = fpp["farm_Id"];
              if (fpp.selected_inventory_Id.cart_Quantity == 0) {
                this.cartService.addtoCart(sitem);
                this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "add");
              }
            }
          }
        }
      }
      this.selectDiv(uniqueId);
    }
    else if (type === "lp") {
      for (let fpp of this.latestProducts) {
        for (let pqt of fpp["product_Qty_Details"]) {
          if (uniqueId === pqt["inventory_Id"]) {
            if (pqt["stock_Avlbl"]) {
              sitem = pqt;
              sitem["farm_Short_Name"] = fpp["farm_Short_Name"];
              sitem["product_Name"] = fpp["product_Name"];
              sitem["product_Image"] = fpp["product_Image"][0];
              sitem["farm_Id"] = fpp["farm_Id"];
              if (fpp.selected_inventory_Id.cart_Quantity == 0) {
                this.cartService.addtoCart(sitem);
                this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "add");
              }
            }
          }
        }
      }
      this.selectDiv(uniqueId);
    }
    else if (type === "dodp") {
      for (let fpp of this.DODProducts) {
        for (let pqt of fpp["product_Qty_Details"]) {
          if (uniqueId === pqt["inventory_Id"]) {
            if (pqt["stock_Avlbl"]) {
              sitem = pqt;
              sitem["farm_Short_Name"] = fpp["farm_Short_Name"];
              sitem["product_Name"] = fpp["product_Name"];
              sitem["product_Image"] = fpp["product_Image"];
              sitem["deal_Price"] = fpp["deal_Price"];
              sitem["farm_Id"] = fpp["farm_Id"];
              if (fpp.selected_inventory_Id.cart_Quantity == 0) {
                this.cartService.addtoCart(sitem);
                this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "add");
              }
            }
          }
        }
      }
      this.selectDiv(uniqueId);
    }
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  }

  onClickIncrementNew(uniqueId, type) {
    if (type === "fp") {
      for (let fpp of this.featuredProducts) {
        for (let pqt of fpp["product_Qty_Details"]) {
          if (uniqueId === pqt["inventory_Id"]) {
            this.cartService.onClickIncrementNew(uniqueId, fpp.selected_inventory_Id);
            this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "add");
            this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
            const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === pqt.inventory_Id);
            if (resultItem && resultItem.length == 1) {
              this.featuredProducts = this.cartService.updateSubcartQuantityByDesc(
                this.featuredProducts, resultItem[0]
              );
            }
          }
        }
      }
    }
    else if (type === "tp") {
      for (let fpp of this.trendingProducts) {
        for (let pqt of fpp["product_Qty_Details"]) {
          if (uniqueId === pqt["inventory_Id"]) {
            this.cartService.onClickIncrementNew(uniqueId, pqt);
            this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "add");
            this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
            const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === pqt.inventory_Id);
            if (resultItem && resultItem.length == 1) {
              this.trendingProducts = this.cartService.updateSubcartQuantityByDesc(
                this.trendingProducts, resultItem[0]
              );
            }
          }
        }
      }
    }
    else if (type === "lp") {
      for (let fpp of this.latestProducts) {
        for (let pqt of fpp["product_Qty_Details"]) {
          if (uniqueId === pqt["inventory_Id"]) {
            this.cartService.onClickIncrementNew(uniqueId, pqt);
            this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "add");
            this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
            const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === pqt.inventory_Id);
            if (resultItem && resultItem.length == 1) {
              this.latestProducts = this.cartService.updateSubcartQuantityByDesc(
                this.latestProducts, resultItem[0]
              );
            }
          }
        }
      }
    }
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  }

  onClickDecrementNew(uniqueId, type, qtt, item) {
    if (type == "fp") {
      for (let fpp of this.featuredProducts) {
        for (let pqt of fpp["product_Qty_Details"]) {
          if (uniqueId === pqt["inventory_Id"]) {
            this.cartService.onClickDecrement(uniqueId, type);
            this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "remove");
            this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
            const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === pqt.inventory_Id);
            if (resultItem && resultItem.length == 1) {
              this.featuredProducts = this.cartService.updateSubcartQuantityByDesc(
                this.featuredProducts, resultItem[0]
              );
            }
            if (qtt == 1) {
              this.cartService.hidecurrentItem("p" + uniqueId);
            }
          }
        }
      }
    }
    if (type == "tp") {
      for (let fpp of this.trendingProducts) {
        for (let pqt of fpp["product_Qty_Details"]) {
          if (uniqueId === pqt["inventory_Id"]) {
            this.cartService.onClickDecrement(uniqueId, type);
            this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "remove");
            this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
            const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === pqt.inventory_Id);
            if (resultItem && resultItem.length == 1) {
              this.trendingProducts = this.cartService.updateSubcartQuantityByDesc(
                this.trendingProducts, resultItem[0]
              );
            }
            if (qtt == 1) {
              this.cartService.hidecurrentItem("p" + uniqueId);
            }
          }
        }
      }
    }
    if (type == "lp") {
      for (let fpp of this.latestProducts) {
        for (let pqt of fpp["product_Qty_Details"]) {
          if (uniqueId === pqt["inventory_Id"]) {
            this.cartService.onClickDecrement(uniqueId, type);
            this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "remove");
            this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
            const resultItem = this.allCartItems.filter(sitem => sitem.inventory_Id === pqt.inventory_Id);
            if (resultItem && resultItem.length == 1) {
              this.latestProducts = this.cartService.updateSubcartQuantityByDesc(
                this.latestProducts, resultItem[0]
              );
            }
            if (qtt == 1) {
              this.cartService.hidecurrentItem("p" + uniqueId);
            }
          }
        }
      }
    }
    if (type == "dodp") {
      for (let fpp of this.DODProducts) {
        for (let pqt of fpp["product_Qty_Details"]) {
          if (uniqueId === pqt["inventory_Id"]) {
            this.cartService.onClickDecrement(uniqueId, type);
            this.cartService.updateCartQuantityNew(fpp.selected_inventory_Id, "remove");
            this.updateDODPcartQuantity(fpp.selected_inventory_Id);
            if (qtt == 1) {
              this.cartService.hidecurrentItem("p" + uniqueId);
            }
          }
        }
      }
    }
    this.allCartItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
  }

  openOutOfStockPopUp() {
    const modalRef = this.modalService.open(AlertComponent,
      { size: 'sm', backdrop: 'static', centered: true });
    modalRef.componentInstance.msg = "This Product is Out-of-Stock";
  }

}
