<!-- for search -->
<div class="css-searchmain">
    <div>
        <h2>Searched Products
            <button type="button" class="btn btn-link" (click)="claerSearchData()">Clear</button>
        </h2>
    </div>
    <section class="search-section">
        <div class="css-vz0s8c">
            <div class="row row-cols-5">
                <div class="col custom-product" *ngFor="let product of filteredAllProducts">
                    <div class="content_div">
                        <div id="showAddToCartButton" class="css-qiwenx" *ngIf="product.selected_inventory_Id.cart_Quantity === 0 && product.selected_inventory_Id.stock_Avlbl" (click)="openAddtoCartDialogNew(product.selected_inventory_Id.inventory_Id)">
                            <button class="active css-pncr0q" title="Add to Cart">
                                <svg width="13" height="12" viewBox="0 0 13 12">
                                    <path d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                        transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                </svg>
                            </button>
                        </div>
                        <div id="showAddToCartButton" class="css-qiwenx" *ngIf="product.selected_inventory_Id.cart_Quantity === 0 && !product.selected_inventory_Id.stock_Avlbl">
                            <button class="active css-pncr0q " title="Add to Cart ">
                                <svg width="13 " height="12 " viewBox="0 0 13 12 ">
                                    <path d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z "
                                        transform="translate(-55.269 -64) " fill="#5a5a5a "></path>
                                </svg>
                            </button>
                        </div>
                        <div id="{{product.selected_inventory_Id.inventory_Id}}" class="css-shownumber " *ngIf='product.selected_inventory_Id.cart_Quantity >0' (click)="openAddtoCartDialogNew(product.selected_inventory_Id.inventory_Id) ">
                            <button class="active css-pncr0q " style="background-color:black " title="Add to Cart ">
                                {{product.selected_inventory_Id.cart_Quantity}}
                            </button>
                        </div>
                        <div #incrDecrBtn id="p{{product.selected_inventory_Id.inventory_Id}}" class="css-xupwym ">
                            <button title="Decrement " class="css-110od4n " (click)="onClickDecrement(product.selected_inventory_Id.inventory_Id, 'sp',product.selected_inventory_Id.cart_Quantity, product) ">
                                <svg stroke="currentColor " fill="currentColor " stroke-width="0 " viewBox="0 0 512 512 "
                                    height="1em " width="1em " xmlns="http://www.w3.org/2000/svg ">
                                    <path d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z "></path>
                                </svg>
                            </button>
                            <div class="css-vurnku ">{{product.selected_inventory_Id.cart_Quantity}}</div>
                            <button title="Increment " class="css-110od4n " (click)="onClickIncrement(product.selected_inventory_Id.inventory_Id, 'sp', product) ">
                                <svg stroke="currentColor " fill="currentColor " stroke-width="0 " viewBox="0 0 512 512 "
                                    height="1em " width="1em " xmlns="http://www.w3.org/2000/svg ">
                                    <path d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7 8.8 0 16-7.2 16-16V272h96.5c8.8
                            0 16-7.2 16-16s-7.2-16-16-16z "></path>
                                </svg>
                            </button>
                        </div>
                        <div class=" " id="p{{product.selected_inventory_Id.inventory_Id}}">
                            <img [src]="product.product_Image " id="icon" class="feature-product-img " (click)="openProductDescView(product._id) ">
                            <div class="content css-vurnku ">
                                <div class="css-10nf7hq ">
                                    <span class="css-jrxylc ">{{product.farm_Short_Name}}</span>
                                </div>
                                <h4 class="css-6tq2gk " style="height: 45px; "> {{product.product_Name}}</h4>
                                <div>
                                    <select class="form-control price-dropdown " (change)="onChangeProductquantyDesc(product.inventory_Id,$event.target.value) " style="padding: 0; ">
                                        <option *ngFor="let pspricedesc of product.product_Qty_Details; index as j " [value]="j" [selected]="pspricedesc.inventory_Id === product.selected_inventory_Id.inventory_Id">
                                            {{ pspricedesc.quantity_Desc }} - Rs.{{ pspricedesc.allfresh_Price | number:'1.2-2'}}
                                        </option>
                                    </select>
                                    <div class="form-control " style="margin-top: 10px;height: auto;padding: 0; ">
                                        <span class="css-down-dropdown-price "> Rs.{{product.selected_inventory_Id.allfresh_Price | number:'1.2-2'}}</span>
                                        <span class="css-down-dropdown-line" *ngIf="product.selected_inventory_Id.allfresh_Price !== product.selected_inventory_Id.sell_Price">Rs.{{product.selected_inventory_Id.sell_Price | number:'1.2-2'}} </span>
                                        <span class="css-down-dropdown-discount ">{{product.selected_inventory_Id.discount_Percentage}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <section class="responsive-search-section">
        <div class="css-vz0s8c">
            <div class="row">
                <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-3 custom-product" *ngFor="let product of filteredAllProducts">
                    <div class="content_div">
                        <div id="showAddToCartButton" class="css-qiwenx" *ngIf="product.selected_inventory_Id.cart_Quantity === 0 && product.selected_inventory_Id.stock_Avlbl" (click)="openAddtoCartDialogNew(product.selected_inventory_Id.inventory_Id)">
                            <button class="active css-pncr0q" title="Add to Cart">
                                <svg width="13" height="12" viewBox="0 0 13 12">
                                    <path d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
                                        transform="translate(-55.269 -64)" fill="#5a5a5a"></path>
                                </svg>
                            </button>
                        </div>
                        <div id="showAddToCartButton" class="css-qiwenx" *ngIf="product.selected_inventory_Id.cart_Quantity === 0 && !product.selected_inventory_Id.stock_Avlbl">
                            <button class="active css-pncr0q " title="Add to Cart ">
                                <svg width="13 " height="12 " viewBox="0 0 13 12 ">
                                    <path d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z "
                                        transform="translate(-55.269 -64) " fill="#5a5a5a "></path>
                                </svg>
                            </button>
                        </div>
                        <div id="{{product.selected_inventory_Id.inventory_Id}}" class="css-shownumber " *ngIf='product.selected_inventory_Id.cart_Quantity >0' (click)="openAddtoCartDialogNew(product.selected_inventory_Id.inventory_Id) ">
                            <button class="active css-pncr0q " style="background-color:black " title="Add to Cart ">
                                {{product.selected_inventory_Id.cart_Quantity}}
                            </button>
                        </div>
                        <div #incrDecrBtn id="p{{product.selected_inventory_Id.inventory_Id}}" class="css-xupwym ">
                            <button title="Decrement " class="css-110od4n " (click)="onClickDecrement(product.selected_inventory_Id.inventory_Id, 'sp',product.selected_inventory_Id.cart_Quantity, product) ">
                                <svg stroke="currentColor " fill="currentColor " stroke-width="0 " viewBox="0 0 512 512 "
                                    height="1em " width="1em " xmlns="http://www.w3.org/2000/svg ">
                                    <path d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z "></path>
                                </svg>
                            </button>
                            <div class="css-vurnku ">{{product.selected_inventory_Id.cart_Quantity}}</div>
                            <button title="Increment " class="css-110od4n " (click)="onClickIncrement(product.selected_inventory_Id.inventory_Id, 'sp', product) ">
                                <svg stroke="currentColor " fill="currentColor " stroke-width="0 " viewBox="0 0 512 512 "
                                    height="1em " width="1em " xmlns="http://www.w3.org/2000/svg ">
                                    <path d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7 8.8 0 16-7.2 16-16V272h96.5c8.8
                            0 16-7.2 16-16s-7.2-16-16-16z "></path>
                                </svg>
                            </button>
                        </div>
                        <div class=" " id="p{{product.selected_inventory_Id.inventory_Id}}">
                            <img [src]="product.product_Image " id="icon" class="feature-product-img " (click)="openProductDescView(product._id) ">
                            <div class="content css-vurnku ">
                                <div class="css-10nf7hq ">
                                    <span class="css-jrxylc ">{{product.farm_Short_Name}}</span>
                                </div>
                                <h4 class="css-6tq2gk " style="height: 45px; "> {{product.product_Name}}</h4>
                                <div>
                                    <select class="form-control price-dropdown " (change)="onChangeProductquantyDesc(product.inventory_Id,$event.target.value) " style="padding: 0; ">
                                        <option *ngFor="let pspricedesc of product.product_Qty_Details; index as j " [value]="j" [selected]="pspricedesc.inventory_Id === product.selected_inventory_Id.inventory_Id">
                                            {{ pspricedesc.quantity_Desc }} - Rs.{{ pspricedesc.allfresh_Price | number:'1.2-2'}}
                                        </option>
                                    </select>
                                    <div class="form-control " style="margin-top: 10px;height: auto;padding: 0; ">
                                        <span class="css-down-dropdown-price "> Rs.{{product.selected_inventory_Id.allfresh_Price | number:'1.2-2'}}</span>
                                        <span class="css-down-dropdown-line ">Rs.{{product.selected_inventory_Id.sell_Price | number:'1.2-2'}} </span>
                                        <span class="css-down-dropdown-discount ">{{product.selected_inventory_Id.discount_Percentage}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
<app-cartbar [allCartItems]="allCartItems " (onCartChange)="changedCartData() "></app-cartbar>