<section class="footer-section myfooter">
    <div class="container text-center">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xs-12">
                <div class="dropdown-items" aria-labelledby="navbarDropdown">
                    <div class="footer-header">Shop</div>
                    <a [routerLink]="['/Products','Vegetables','All-Vegetables']">Vegetables</a>
                    <a [routerLink]="['/Products','Fruits','All-Fruits']">Fruits</a>
                    <a [routerLink]="['/Products','Olis','AllColdPressed-Oils']">Oils</a>
                    <a [routerLink]="['/Products','Grocery&Eggs','All-Grocery&Eggs']">Grocery & Eggs</a>
                    <a [routerLink]="['/Products','Millets&Seeds','All-Millets&Seeds']">Millets & Seeds</a>
                    <a [routerLink]="['/Products','Flowers','All-Flowers&PotPlants']">Flowers</a>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xs-12">
                <div class="dropdown-items" aria-labelledby="navbarDropdown">
                    <div class="footer-header">About</div>
                    <a target="_blank" (click)="onClickBlog()" style="cursor: pointer;">Blog</a>
                    <a target="_blank" (click)="onClickFAQ()" style="cursor: pointer;">FAQ</a>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xs-12">
                <div class="dropdown-items" aria-labelledby="navbarDropdown">
                    <div class="footer-header">Policy</div>
                    <a target="_blank" (click)="onClickPrivacyPolicy()" style="cursor: pointer;">Privacy Policy</a>
                    <a target="_blank" (click)="onClicyReturnPolicy()" style="cursor: pointer;">Return Policy</a>
                    <a target="_blank" (click)="onClickTermsandCond()" style="cursor: pointer;">Terms and Conditions</a>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xs-12">
                <div class="dropdown-items" aria-labelledby="navbarDropdown">
                    <div class="footer-header">Grievance Contact</div>
                    <!-- <a>
                        <img src="assets/images/footerImages/CustomerCare.png" width="15px" height="15px;">
                        <span style="padding-left: 10px;">
                            Customer Care Team
                        </span>
                    </a> -->
                    <a>
                        <img src="assets/images/footerImages/Phone.png" width="15px" height="15px;">
                        <span style="padding-left: 10px;">
                            +91 81974 46652
                        </span>
                    </a>
                    <a>
                        <img src="assets/images/footerImages/Email.png" width="15px" height="10px;">
                        <span style="padding-left: 10px;">support@farm2homes.co.in</span>
                    </a>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xs-12">
                <div class="dropdown-items" aria-labelledby="navbarDropdown">
                    <div class="footer-header">Follow Us</div>
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xs-6">
                            <div class="socialLinks">
                                <a class="facebook css_a" href="https://www.facebook.com/Allfresh-114276040417399/" data-tip="Facebook" title="Facebook" target="_blank" rel="noopener noreferrer">
                                    <img src="assets/images/footerImages/facebook.png">
                                </a>

                                <a class="youtube css_a" href="https://www.instagram.com/allfreshf2h/" data-tip="Youtube" title="Youtube" target="_blank" rel="noopener noreferrer">
                                    <img src="assets/images/footerImages/instagram.jpeg">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="padding-bottom: 30px;padding-top: 5px;">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xs-12 css-copyright">
                Copyright @ 2020, All rights reserved allfresh
            </div>
        </div>
    </div>
</section>