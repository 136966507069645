import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

    @Input() msg;
    isalert:boolean = false;
    @Input() uniqueval;
    hideSpinner:boolean = true;

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {

        if(this.uniqueval){
            this.isalert = true;
            this.hideSpinner = false;
        } else{
            setTimeout(() => {            
                this.activeModal.close();
            }, 2000);
        }

    }
}