import { Component, OnInit } from '@angular/core';
import { OrderHistoryService } from '../services/order-history.service';
import { CartService } from '../services/cart.service';
import { OrderService } from '../services/order.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CancelOrderModalComponent } from '../cancel-order-modal/cancel-order-modal.component';
import { AppComponent } from '../app.component';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { SharedmobService } from '../services/sharedmob.service';
import { RouterService } from '../services/router.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})
export class MyOrdersComponent implements OnInit {

  // Spinner
  hideSpinner = true;

  errMessage: string;

  isorderDetails: boolean = false;
  isorderHistory: boolean = true;
  userIdFromSession = window.localStorage.getItem("userId");

  orderNo: any;
  orderStatus: any;
  deleveredOn: any;
  orderedAmount: number;
  orderItems: number;
  orderHistoryResp: any = [];
  orderHistoryObj: any;
  selectedorderItems: any = [];
  cancelOrderMSG: string;
  invoicedOrderResp:any;

  constructor(private orderhistory: OrderHistoryService, private appcomp: AppComponent,
    private cartService: CartService, private authService: AuthService,
    private orderService: OrderService, private modalService: NgbModal,
    private dialog: MatDialog, private sharedService: SharedmobService, 
    private routerService: RouterService, private router: Router) { }

  ngOnInit(): void {
    if (this.userIdFromSession) {
      this.getOrderDetailsByUserId();
    }
  }

  getOrderDetailsByUserId() {
    let useridFromSession = window.localStorage.getItem("userId");
    if (useridFromSession) {
      let getloginTimediff = this.appcomp.getLoginTimeDif();
      if (getloginTimediff >= 14) {
        this.authService.refreshToken().subscribe(resp => {
          getloginTimediff = 1;
          if (resp['tkn_Status']) {
            let token = resp['token'];
            if (token) {
              this.authService.setBearerToken(token);
              let currentTime = new Date().getTime();
              window.localStorage.setItem("userlogintime", currentTime + "");
              this.getMyOrder();
            } else {              
              this.openLoginDialog(window.localStorage.getItem("mobileNumber"));
            }
          }
          else {
            this.openLoginDialog(window.localStorage.getItem("mobileNumber"));
          }
        },
          err => {
            this.openLoginDialog(window.localStorage.getItem("mobileNumber"));
          });
      }
      else {
        this.getMyOrder();
      }
    }
  }

  getMyOrder(){
    this.orderhistory.getOrderByCustomer(this.userIdFromSession).subscribe(resp => {
      this.orderHistoryResp = resp;
      this.hideSpinner = false;
    }, err => {
      this.errMessage = "error in my order list";
    })
  }
  openLoginDialog(mobNo): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '350px',
      data: {
        'mobileNumber': mobNo
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === "Success") {
        let logedinmobileNumber = window.localStorage.getItem("userName");
        this.sharedService.changeMobileNo(logedinmobileNumber);
        this.getOrderDetailsByUserId();
      }
    });
  }

  switchtoViewOrder(indexval) {
    this.isorderDetails = true;
    this.isorderHistory = false;
    this.orderHistoryObj = this.orderHistoryResp[indexval];
    this.selectedorderItems = this.orderHistoryObj["orderItemDetails"];
  }

  onClickcancelOrder(orderId: string) {
    this.openCancelOrderDialog(orderId);
  }
  openCancelOrderDialog(orderId) {
    const modalRef = this.modalService.open(CancelOrderModalComponent,
      { size: 'sm', backdrop: 'static', centered: true });

    modalRef.result.then((resp) => {
      if (resp == "Yes") {
        this.cancelOrder(orderId);
      }
    });
  }

  cancelOrder(orderId: string) {
    this.orderService.cancelOrder(orderId).subscribe(resp => {
      if (resp['cancelOrderStatus'] == "Success") {
        this.cancelOrderMSG = "Your order has been successfully cancelled.";
        this.getOrderDetailsByUserId();
      } else if (resp['cancelOrderStatus'] == "Failed") {
        this.cancelOrderMSG = resp['description'];
      }
    }, err => {
      this.errMessage = "ordered cancel error";
    })
  }

  cancelEditAddress() {
    this.isorderDetails = false;
    this.isorderHistory = true;
  }

  public searchCall(data, isclose) {
    this.cartService.searchCall(data, isclose, 'myorderid');
  }

  viewInvoices(delvaddid) {
    this.orderhistory.viewInvoice(delvaddid).subscribe(resp => {
      this.invoicedOrderResp = resp;
      localStorage.setItem("viewInvoiceData", JSON.stringify(this.invoicedOrderResp));
      let currenturl = window.location.href;
      let url = this.RemoveLastDirectoryPartOf(currenturl);
    const finalurl = url + this.router.serializeUrl(
      this.router.createUrlTree(['/viewInvoice'])
    );
    window.open(finalurl);
      // this.routerService.routeToViewInvoice();
    }, err => {
      this.errMessage = "error in my order view invoice";
    })
  }

  RemoveLastDirectoryPartOf(url) {
    let removedurl = url.substring(0, url.lastIndexOf('/'));
    return removedurl;
  }
}
