import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { tap, delay } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { RouterService } from './router.service';
import { User } from '../login/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userResp: any;
  otpResp: any;
  toeknResp: any;
  currentNameSubject$ = new BehaviorSubject("");
  sessionOtpObj: any;
  private refreshTokenTimeout;
  pincodeList: any;

  constructor(private httpClient: HttpClient, private routerService: RouterService,
    public jwtHelper: JwtHelperService
  ) { }

  setBearerToken(token) {
    window.localStorage.setItem('bearerToken', token);
  }

  getBearerToken() {
    return window.localStorage.getItem('bearerToken');
  }

  getOTP(mobileNo) {
    let otpObj = { "mobileNumber": mobileNo, "user_Type": "Retail" }
    return this.httpClient.post(AppComponent.AUTH_API_ENDPOINT + `/getotp`, otpObj, {
      headers: new HttpHeaders().set('Content-Type', `application/json`)
    }).pipe(tap(resp => {
      this.otpResp = resp;
      this.currentNameSubject$.next(this.otpResp);
    }))
  }

  validateOtpService(otp) {
    let validateOtpObj = {
      "mobileNumber": window.localStorage.getItem("mobileNumber"), "otp": otp, "user_Type": localStorage.getItem("user_Type"),
      "uuId": window.localStorage.getItem("uuId")
    }
    return this.httpClient.post(AppComponent.AUTH_API_ENDPOINT + `/validateotp`, validateOtpObj, {
      headers: new HttpHeaders().set('Content-Type', `application/json`)
    }).pipe(tap(resp => {
      this.userResp = resp;
      this.currentNameSubject$.next(this.userResp);
    }))
  }

  isLoggedIn() {
    return !!this.getBearerToken();
  }


  refreshToken() {
    let refreshTokenObj = {
      "user_Id": window.localStorage.getItem("userId"),
      "uuId": window.localStorage.getItem("uuId")
    }
    return this.httpClient.post(AppComponent.TOKEN_API_ENDPOINT+`rfrsh`, refreshTokenObj, {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
    }).pipe(tap(resp => {
      this.toeknResp = resp;
      this.currentNameSubject$.next(this.toeknResp);
    }))
  }

  public isAuthenticated(): boolean {
    const token = window.localStorage.getItem('bearerToken');
    // Check whether the token is expired and return
    // true or false
    // return !this.jwtHelper.isTokenExpired(token);
    // if(token){
    return !this.jwtHelper.isTokenExpired(token);
    // }      
  }



  // helper methods


  // private startRefreshTokenTimer() {
  //   console.log("start refresh token");
  //   // parse json object from base64 encoded jwt token
  //   const jwtToken = this.token;

  //   // set a timeout to refresh the token a minute before it expires
  //   const expires = new Date(jwtToken.exp * 1000);
  //   const timeout = expires.getTime() - Date.now() - (60 * 1000);
  //   console.log("timeout:"+timeout);
  //   this.refreshTokenTimeout = setTimeout(() =>
  //     this.refreshToken().subscribe(), timeout);
  // }

  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  logout() {
    window.localStorage.removeItem("bearerToken");
    window.localStorage.removeItem("cartItemObj");
    window.localStorage.removeItem("mobileNumber");
    window.localStorage.removeItem("uuId");
    window.localStorage.removeItem("user_Type");
    window.localStorage.removeItem("userName");
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("shippingObj");
    window.localStorage.removeItem("outOfStockItems");
    window.localStorage.removeItem("radioaddresstype");
    window.sessionStorage.removeItem("priceChangedItems");
    window.sessionStorage.removeItem("outOfStockItems");

    this.currentNameSubject$.next(null);


  }
}