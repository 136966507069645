<div class="css-1bcgouf">
    <div class="container" id="prodDescId">
        <section class="product-desc-view-ui">
            <div>
                <div class="row">
                    <div class="col-lg-12 justify-content-center" *ngIf="!allProdDescResp">
                        <div style="color: red;text-align: center;font-size: 20px;">Out of stock</div>
                    </div>
                    <div class="col-12 col-lg-10 col-md-10 col-sm-12 col-xs-12 css-leftpart-10" style="max-height:770px;overflow-y:scroll;" *ngIf="allProdDescResp">
                        <div style="display: flex;flex-direction: row">
                            <div class="css-normalfont" (click)="onClickHome()" style="cursor: pointer;text-decoration: none;">Home
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                          </svg>
                            </div>
                            <div class="css-normalfont" (click)="onClickProductType(ProductType)" style="cursor: pointer">{{ProductType}}
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                            </div>
                            <div class="css-normalfont" (click)="onClickSubProductType(allProdDescResp.farm_Name)" style="cursor: pointer">{{allProdDescResp.farm_Name}}</div>
                        </div>
                        <div class="css-boldfont">Allfresh {{allProdDescResp.product_Desc}} - {{defaultButtonSelected}}</div>
                        <div class="row">
                            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-md-offset-2">
                                <div class="css-leftpart-7">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-12 col-sx-12">
                                            <div style="padding-left: 10px;padding-bottom: 10px;" *ngFor="let pimg of allProdDescResp.product_Image;index as i">
                                                <div class="left-supprt-img-div" *ngIf="i !== 0 && pimg && pimg !==' '" style="cursor: pointer;">
                                                    <img [src]="pimg " class="left-supprt-img " (mouseover)="onclicksideimage(pimg) ">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 ">
                                            <div style="height: 400px;margin-top: 20px;width: 300px;">
                                                <lib-ngx-image-zoom [thumbImage]=product_Image [fullImage]=product_Image [zoomMode]=" 'hover' " [magnification]="2" [enableScrollZoom]="true" [lensWidth]="200">
                                                </lib-ngx-image-zoom>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12" style="padding-left: 0;">
                                <div class="css-leftside-5">
                                    <div class="css-inputfont " style="padding-left: 10px; ">Allfresh - Price</div>
                                    <span class="pricefont ">
                                        Rs. {{allfreshPrice}}/-
                                      </span>
                                    <span class="pricestrickfont" *ngIf="allfreshPrice !== sellPrice">
                                    Rs. {{sellPrice}}/-
                                </span>
                                    <span class="css-inputfont ">
                                    {{discount}}
                                </span>
                                    <div class="row " style="margin-left: 0;margin-right: 0; ">
                                        <div class="col-3 col-xs-3 col-sm-3 col-md-4 col-lg-3 " style="margin-right: 10px;margin-left: -4px; " *ngFor="let pp of allProdDescResp.product_Qty_Details;let i=index ">
                                            <button class="card-text" id="unitbtnid{{i}}" (click)="onSelectUnitsDetails(pp.quantity_Desc,i)" [ngClass]="(i===0)? 'unit-butoon': 'unit-butoon1' ">
                                                  {{pp.quantity_Desc}}
                                          </button>

                                        </div>
                                    </div>
                                    <div class="row " style="margin-left: 0;margin-right: 0; ">
                                        <div class="col-lg-12 bestbeforefont " style="margin-top: 10px; ">
                                            {{allProdDescResp.best_Before}}
                                        </div>
                                        <div class="col-lg-12 availabilityfont ">
                                            Availability
                                            <span class="availabilityfont " [ngClass]="(stockAbailable==='In-Stock')? 'css-instock': 'css-outofstock' ">{{stockAbailable}}</span>
                                        </div>
                                    </div>
                                    <div style="width: 45%;margin-left: 25% ">
                                        <div class="css-xupwym ">
                                            <button title="Decrement " class="css-110od4n " (click)="onClickDecrement() ">
                                              <svg stroke="currentColor " fill="currentColor " stroke-width="0 " viewBox="0 0 512 512 " height="1em " width="1em " xmlns="http://www.w3.org/2000/svg ">
                                                  <path d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z "></path>
                                              </svg>
                                          </button>
                                            <div class="css-vurnku " style="font-weight: 700;font-size: 25px ">
                                                {{newcartQuantity}}
                                            </div>
                                            <button title="Increment " class="css-110od4n " (click)="onClickIncrement() ">
                                                  <svg stroke="currentColor " fill="currentColor " stroke-width="0 " viewBox="0 0 512 512 " height="1em " width="1em " xmlns="http://www.w3.org/2000/svg ">
                                                      <path d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.78.8
                                                0 16-7.2 16-16V272h96.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z "></path>
                                                  </svg>
                                        </button>
                                        </div>
                                    </div>
                                </div>
                                <div style=" margin-top: 10px; padding-left: 10px;">
                                    <div class="product-desc-heading ">About this product :</div>
                                    <p class="product-desc-font ">{{allProdDescResp.about_Product_Details}}</p>

                                </div>
                            </div>
                            <div class="col d-flex justify-content-center ">
                                <mat-spinner *ngIf="hideSpinner "></mat-spinner>
                            </div>
                        </div>
                        <div class="row " *ngIf="allProdDescResp.recipe ">
                            <div class="col-lg-12 ">
                                <div style="border-style: solid;border-color: #f0f0f0;border-width: 2px;height: 245px;margin-bottom: 10px; ">
                                    <span class="css-recipefont ">Recipes</span>
                                    <div class="row " *ngFor="let recipe of allProdDescResp.recipe ">
                                        <div class="col-lg-6 " style="display: flex;flex-direction: row; ">
                                            <div style="display: flex;flex-direction: column; ">
                                                <span class="recipe-heading ">{{recipe.recipe_Name}}</span>
                                                <img [src]="recipe.recipe_Image_Video " alt="recipes image " width="200px; height200px; ">
                                            </div>
                                            <div>
                                                <span class="css-recipefont ">How to prepare</span>
                                                <p class="product-desc-font " style="white-space: pre-line ">
                                                    {{recipe.recipe_Description}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 " *ngIf="allProdDescResp ">
                        <div class="css-relatedproduct ">
                            <div class="pricefont ">Related Products</div>
                            <div style="padding-left: 10px;padding-right: 10px;padding-bottom: 10px; " *ngFor="let rp of allProdDescResp.related_Products ">
                                <div class="card-text ">Allfresh</div>
                                <div class="card-text ">{{rp.product_Name}}</div>
                                <div class="relative-img-div " (click)="openProductDesc(rp._id) " style="cursor: pointer; ">
                                    <img [src]="rp.product_Image[0] " style="width: 100%;height: 100%;border-radius: 6px; ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="responsive-product-desc-view">
            <div>
                <div class="row">
                    <div class="col-lg-12 justify-content-center" *ngIf="!allProdDescResp">
                        <div style="color: red;text-align: center;font-size: 20px;">Out of stock</div>
                    </div>
                    <div class="col-12 col-lg-10 col-md-10 col-sm-12 col-xs-12 css-leftpart-10" *ngIf="allProdDescResp">
                        <div style="display: flex;flex-direction: row;padding-top: 10px;;">
                            <div class="responsive-normalfont" (click)="onClickHome()" style="cursor: pointer;text-decoration: none;">Home
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                          </svg>
                            </div>
                            <div class="responsive-normalfont" (click)="onClickProductType(ProductType)" style="cursor: pointer">{{ProductType}}
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                            </div>
                            <div class="responsive-normalfont" (click)="onClickSubProductType(allProdDescResp.farm_Name)" style="cursor: pointer">{{allProdDescResp.farm_Name}}</div>
                        </div>
                        <div class="css-boldfont">Allfresh {{allProdDescResp.product_Desc}} - {{defaultButtonSelected}}</div>
                        <div class="row">
                            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-md-offset-2">
                                <ngb-carousel *ngIf="allProdDescResp.product_Image" class="css-ngb-courisal">
                                    <ng-template ngbSlide *ngFor="let pimg of allProdDescResp.product_Image">
                                        <img [src]="pimg" alt="Random slide" class="css-ngb-courisal-img" />
                                    </ng-template>
                                </ngb-carousel>
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12" style="padding-left: 0;">
                                <div class="css-leftside-5">
                                    <div class="css-inputfont " style="padding-left: 10px; ">Allfresh - Price</div>
                                    <span class="pricefont ">
                                        Rs. {{allfreshPrice}}/-
                                      </span>
                                    <span class="pricestrickfont" *ngIf="allfreshPrice !== sellPrice">
                                    Rs. {{sellPrice}}/-
                                </span>
                                    <span class="css-inputfont ">
                                    {{discount}}
                                </span>
                                    <div class="row " style="margin-left: 0;margin-right: 0; ">
                                        <div class="col-3 col-xs-3 col-sm-3 col-md-4 col-lg-3 " style="margin-right: 10px;margin-left: -4px; " *ngFor="let pp of allProdDescResp.product_Qty_Details;let i=index ">
                                            <button class="card-text" id="unitbtnid{{i}}" (click)="onSelectUnitsDetails(pp.quantity_Desc,i)" [ngClass]="(i===0)? 'unit-butoon': 'unit-butoon1' ">
                                                  {{pp.quantity_Desc}}
                                          </button>

                                        </div>
                                    </div>
                                    <div class="row " style="margin-left: 0;margin-right: 0; ">
                                        <div class="col-lg-12 bestbeforefont " style="margin-top: 10px; ">
                                            {{allProdDescResp.best_Before}}
                                        </div>
                                        <div class="col-lg-12 availabilityfont ">
                                            Availability
                                            <span class="availabilityfont " [ngClass]="(stockAbailable==='In-Stock')? 'css-instock': 'css-outofstock' ">{{stockAbailable}}</span>
                                        </div>
                                    </div>
                                    <div style="width: 45%;margin-left: 25% ">
                                        <div class="css-xupwym ">
                                            <button title="Decrement " class="css-110od4n " (click)="onClickDecrement() ">
                                              <svg stroke="currentColor " fill="currentColor " stroke-width="0 " viewBox="0 0 512 512 " height="1em " width="1em " xmlns="http://www.w3.org/2000/svg ">
                                                  <path d="M368.5 240h-225c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7h225c8.8 0 16-7.2 16-16s-7.2-16-16-16z "></path>
                                              </svg>
                                          </button>
                                            <div class="css-vurnku " style="font-weight: 700;font-size: 25px ">
                                                {{newcartQuantity}}
                                            </div>
                                            <button title="Increment " class="css-110od4n " (click)="onClickIncrement() ">
                                                  <svg stroke="currentColor " fill="currentColor " stroke-width="0 " viewBox="0 0 512 512 " height="1em " width="1em " xmlns="http://www.w3.org/2000/svg ">
                                                      <path d="M368.5 240H272v-96.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-96.5c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.7H240v96.5c0 4.4 1.8 8.4 4.7 11.3 2.9 2.9 6.9 4.7 11.3 4.78.8
                                                0 16-7.2 16-16V272h96.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z "></path>
                                                  </svg>
                                        </button>
                                        </div>
                                    </div>
                                </div>
                                <div style=" margin-top: 10px; padding-left: 10px;">
                                    <div class="product-desc-heading ">About this product :</div>
                                    <p class="product-desc-font ">{{allProdDescResp.about_Product_Details}}</p>

                                </div>
                            </div>
                            <div class="col d-flex justify-content-center ">
                                <mat-spinner *ngIf="hideSpinner "></mat-spinner>
                            </div>
                        </div>
                        <div class="row " *ngIf="allProdDescResp.recipe ">
                            <div class="col-lg-12 ">
                                <div style="border-style: solid;border-color: #f0f0f0;border-width: 2px;height: 245px;margin-bottom: 10px; ">
                                    <span class="css-recipefont ">Recipes</span>
                                    <div class="row " *ngFor="let recipe of allProdDescResp.recipe ">
                                        <div class="col-lg-6 " style="display: flex;flex-direction: row; ">
                                            <div style="display: flex;flex-direction: column; ">
                                                <span class="recipe-heading ">{{recipe.recipe_Name}}</span>
                                                <img [src]="recipe.recipe_Image_Video " alt="recipes image " width="200px; height200px; ">
                                            </div>
                                            <div>
                                                <span class="css-recipefont ">How to prepare</span>
                                                <p class="product-desc-font " style="white-space: pre-line ">
                                                    {{recipe.recipe_Description}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 css-relatedproduct" *ngIf="allProdDescResp ">
                        <h2 class="resp-pricefont">Related Products</h2>
                        <div class="row">
                            <div class="col-6 col-xs-6 col-sm-6 col-md-12 col-lg-12" *ngFor="let rp of allProdDescResp.related_Products">
                                <div class="card-text">Allfresh</div>
                                <div class="allfresh-product-font">{{rp.product_Name}}</div>
                                <div class="resp-relative-img-div" (click)="openProductDesc(rp._id) " style="cursor: pointer; ">
                                    <img [src]="rp.product_Image[0] " style="width: 100%;height: 100%;border-radius: 6px; ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- div for add to cart -->
    <app-cartbar [allCartItems]="allCartItems " (onCartChange)="changedCartData() "></app-cartbar>
</div>