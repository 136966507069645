<div class="css-11zk6ke" *ngIf="iseditProfile" id="myeditprofid">
    <!-- <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12"> -->
    <div class="card">
        <h2 class="card-header d-block">Edit Profile Details</h2>
        <div class="card" style="border-color: #6db500;">
            <div class="row">
                <div class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <button class="css-cardheader btn" style="margin-left: 20px;margin-top: 20px;"> Personal Details
                        <!-- <a data-toggle="collapse" href="#collapse-personaldetails" aria-expanded="true" aria-controls="collapse-personaldetails"
                            id="heading-personaldetails" class="d-block">
                            Personal Details
                        </a> -->
                    </button>
                    <div id="collapse-personaldetails" class="collapse show" aria-labelledby="heading-personaldetails">
                        <div class="card-body">
                            <form [formGroup]="personalInfoForm">
                                <div class="form-group row">
                                    <label for="salutation" class="col-12 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-form-label required labelfort">Salutation</label>
                                    <div class="col-12 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                        <select id="inputState" #salutationid tabindex="1" class="form-control css-inputfont" formControlName="salutation">
                                            <option *ngFor="let option of options; index as i" [selected]="option === defaultoption">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </div>
                                    <label for="dob" class="col-12 col-lg-2 col-md-12 col-sm-12 col-xs-12 col-form-label required labelfort">DOB</label>
                                    <div class="col-12 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                        <input type="date" class="form-control css-inputfont" placeholder="DOB" [value]="personalInfoForm.get('dob').value | date: 'yyyy-MM-dd'" formControlName="dob" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }">
                                        <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                                            <div *ngIf="f.dob.errors.required">DOB required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="firstName" class="col-12 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-form-label required labelfort">First
                                        Name</label>
                                    <div class="col-12 col-lg-9 col-md-12 col-sm-12 col-xs-12">
                                        <input type="text" class="form-control css-inputfont" pattern="[a-zA-Z ]*" autocomplete="disabled" placeholder="First Name" formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
                                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                            <div *ngIf="f.firstName.errors.required">First Name required</div>
                                            <div *ngIf="f.firstName.errors.pattern"> Please enter only chars.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lastName" class="col-12 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-form-label required labelfort">Last
                                        Name</label>
                                    <div class="col-12 col-lg-9 col-md-12 col-sm-12 col-xs-12">
                                        <input type="text" class="form-control css-inputfont" pattern="[a-zA-Z ]*" autocomplete="disabled" placeholder="Last Name" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
                                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                            <div *ngIf="f.lastName.errors.required">Last Name required</div>
                                            <div *ngIf="f.lastName.errors.pattern"> Please enter only chars.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="email" class="col-12 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-form-label required labelfort">Email</label>
                                    <div class="col-12 col-lg-9 col-md-12 col-sm-12 col-xs-12">
                                        <input type="email" class="form-control css-inputfont" autocomplete="disabled" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email required</div>
                                            <div *ngIf="f.email.errors.pattern"> Please enter a valid email </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="mobileNumber" class="col-12 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-form-label required labelfort">Mobile
                                        Number</label>
                                    <div class="col-12 col-lg-9 col-md-12 col-sm-12 col-xs-12">
                                        <input type="text" class="form-control css-inputfont" maxlength="10" autocomplete="disabled" placeholder="Mobile Number" formControlName="mobileNumber" [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }">
                                        <div *ngIf="submitted && f.mobileNumber.errors" class="invalid-feedback">
                                            <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required
                                            </div>
                                            <div *ngIf="f.mobileNumber.errors?.minlength">Number should be 10 digit</div>
                                            <div *ngIf="f.mobileNumber.errors.pattern"> Please enter a valid mobile number</div>
                                        </div>
                                    </div>
                                </div>
                                <strong *ngIf="successMessage" class="success-message">{{successMessage}}</strong>
                                <strong *ngIf="errMessage" class="error-message">{{errMessage}}</strong>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <button class="css-cardheader btn" style="margin-left: 20px;margin-top: 20px;"> Delivery Address
                        <!-- <a data-toggle="collapse" href="#collapse-da" aria-expanded="true" aria-controls="collapse-da"
                            id="heading-da" class="d-block">
                            Delivery Address
                        </a> -->
                    </button>
                    <div id="collapse-da" class="collapse show" aria-labelledby="heading-da">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-3 col-lg-1 col-md-2 col-sm-2 col-xs-2 text-center">
                                    <img src="assets/images/myProfileImages/HomeAddress.png" class="css-profileimg">
                                </div>
                                <div class="col-9 col-lg-11 col-md-10 col-sm-10 col-xs-10">
                                    <div class="card css-orderboxdetails">
                                        <div class="card-body" *ngIf="isHomeAddrexist">
                                            <div class="card-text addr-text">
                                                <b>Address 1(Home) : </b>
                                                <span style="color: red;font-size: 14px;font-weight: 600;">{{
                                                    homePreferedAdd}}</span>
                                            </div>
                                            <div class="card-text addr-text">
                                                {{house_No}}, {{apartment_Name}}
                                            </div>
                                            <div class="card-text addr-text">
                                                {{address1}},{{address2}}, {{landMark}}
                                            </div>
                                            <div class="card-text addr-text">
                                                {{city}}, {{state}}, {{pinCode}}
                                            </div>
                                            <div class="row">
                                                <div class="css-editbutton" (click)="editDeliveryAddress('Home')">
                                                    <svg class="bi bi-pencil" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M11.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z" />
                                                        <path fill-rule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 0 0 .5.5H4v.5a.5.5 0 0 0 .5.5H5v.5a.5.5 0 0 0 .5.5H6v-1.5a.5.5 0 0 0-.5-.5H5v-.5a.5.5 0 0 0-.5-.5H3z" />
                                                    </svg>
                                                </div>
                                                <div class="css-deletebutton" (click)="deleteDeliveryAddress('Home')">
                                                    <svg class="bi bi-x" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                                                        <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body text-center" style="margin:0 auto;" *ngIf="!isHomeAddrexist">
                                            <button class="btn btn-sm addbtn" id="center" (click)="openAddDeliveryAddress('Home')">
                                                Add
                                                <svg class="bi bi-house" width="1.4em" height="1.4em" viewBox="0 0 16 16"
                                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                                                    <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
                                                </svg>
                                                Address
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 col-lg-1 col-md-2 col-sm-2 col-xs-2 text-center">
                                    <img src="assets/images/myProfileImages/OfficeAddress.png" class="css-profileimg">
                                </div>
                                <div class="col-9 col-lg-11 col-md-10 col-sm-10 col-xs-10">
                                    <div class="card css-orderboxdetails">
                                        <div class="card-body" *ngIf="isOfficeAddrexist">
                                            <div class="card-text addr-text">
                                                <b> Address 2(Office) :</b>
                                                <span style="color: red;font-size: 14px;font-weight: 600;">{{
                                                    ofPreferedAdd}}</span>

                                            </div>
                                            <div class="card-text addr-text">
                                                {{ofhouse_No}}, {{ofapartment_Name}}
                                            </div>
                                            <div class="card-text addr-text">
                                                {{ofaddress1}},{{ofaddress2}}, {{oflandMark}}
                                            </div>
                                            <div class="card-text addr-text">
                                                {{ofcity}}, {{ofstate}}, {{ofpinCode}}
                                            </div>

                                            <div class="row">
                                                <div class="css-editbutton" (click)="editDeliveryAddress('Office')">
                                                    <svg class="bi bi-pencil" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M11.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z" />
                                                        <path fill-rule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 0 0 .5.5H4v.5a.5.5 0 0 0 .5.5H5v.5a.5.5 0 0 0 .5.5H6v-1.5a.5.5 0 0 0-.5-.5H5v-.5a.5.5 0 0 0-.5-.5H3z" />
                                                    </svg>
                                                </div>
                                                <div class="css-deletebutton" (click)="deleteDeliveryAddress('Office')">
                                                    <svg class="bi bi-x" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                                                        <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body text-center" style="margin:0 auto;" *ngIf="!isOfficeAddrexist">
                                            <button class="btn btn-sm addbtn" id="center" (click)="openAddDeliveryAddress('Office')">
                                                Add
                                                <svg class="bi bi-house" width="1.4em" height="1.4em" viewBox="0 0 16 16"
                                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                                                    <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
                                                </svg>
                                                Address
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 col-lg-1 col-md-2 col-sm-2 col-xs-2 text-center">
                                    <img src="assets/images/myProfileImages/OtherAddress.png" class="css-profileimg">
                                </div>
                                <div class="col-9 col-lg-11 col-md-10 col-sm-10 col-xs-10">
                                    <div class="card css-orderboxdetails">
                                        <div class="card-body" *ngIf="isOthersAddrexist">
                                            <div class="card-text addr-text">
                                                <b> Address 3(Other):</b>
                                                <span style="color: red;font-size: 14px;font-weight: 600;">
                                                    {{oPreferedAdd}} </span>

                                            </div>
                                            <div class="card-text addr-text">
                                                {{ohouse_No}}, {{oapartment_Name}}
                                            </div>
                                            <div class="card-text addr-text">
                                                {{oaddress1}},{{oaddress2}}, {{olandMark}}
                                            </div>
                                            <div class="card-text addr-text">
                                                {{ocity}}, {{ostate}}, {{opinCode}}
                                            </div>
                                            <div class="row">
                                                <div class="css-editbutton" (click)="editDeliveryAddress('Other')">
                                                    <svg class="bi bi-pencil" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M11.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z" />
                                                        <path fill-rule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 0 0 .5.5H4v.5a.5.5 0 0 0 .5.5H5v.5a.5.5 0 0 0 .5.5H6v-1.5a.5.5 0 0 0-.5-.5H5v-.5a.5.5 0 0 0-.5-.5H3z" />
                                                    </svg>
                                                </div>
                                                <div class="css-deletebutton" (click)="deleteDeliveryAddress('Other')">
                                                    <svg class="bi bi-x" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                                                        <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body text-center" style="margin:0 auto;" *ngIf="!isOthersAddrexist">
                                            <button class="btn btn-sm addbtn" id="center" (click)="openAddDeliveryAddress('Other')">
                                                Add
                                                <svg class="bi bi-card-heading" width="1.4em" height="1.4em" viewBox="0 0 16 16"
                                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                                    <path fill-rule="evenodd" d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
                                                    <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                                                </svg>
                                                Address
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin: 0 auto;width:96%;margin-bottom: 10px;padding-top: 5px;">
            <button class="btn css-mybutton" style="width: 80px;" (click)="saveEditProfile()">Save</button>
            <button class="btn css-mybutton" style="margin-left: 20px;width: 80px;" [routerLink]="['/Home']">Cancel</button>
        </div>
    </div>
</div>

<!--
    Edit address
-->

<div class="css-11zk6ke" *ngIf="iseditDelivAddr">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="card">
                <h2 class="card-header">Edit Delivery Address Details</h2>
                <div class="card" style="margin: 0 auto;width:80%">
                    <h4 class="css-cardheader">Delivery Address Details ({{editAddrType}})
                        <!-- <a data-toggle="collapse" href="#collapse-daedit" aria-expanded="true" aria-controls="collapse-daedit"
                            id="heading-daedit" class="d-block">
                            <i class="fa fa-chevron-down pull-right"></i>
                            Delivery Address Details ({{editAddrType}})
                        </a> -->
                    </h4>
                    <div id="collapse-daedit" class="collapse show" aria-labelledby="heading-daedit">
                        <div class="card-body">
                            <!-- <h3 class="card-title"> Delivery Address Details ({{editAddrType}})</h3> -->
                            <form [formGroup]="editDelvAddressForm">
                                <div class="row">
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="labelfort required" for="houseNo">House Number</label>
                                            <input type="text" id="houseNo" pattern="^[A-Za-z0-9 . , /]+$" placeholder="House Number" autofocus autocomplete="disabled" formControlName="houseNo" class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f1.houseNo.errors }" />
                                            <div *ngIf="submitted && f1.houseNo.errors" class="invalid-feedback">
                                                <div *ngIf="f1.houseNo.errors.required">House No is required</div>
                                                <div *ngIf="f1.houseNo.errors.pattern"> Please enter valid house number
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="labelfort required" for="apartmentName">Building Name</label>
                                            <input type="text" id="apartmentName" placeholder="Building Name" pattern="^[a-zA-Z0-9 !@#()_\-\[\]';:\\|,.\/]+$" autocomplete="disabled" formControlName="apartmentName" class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f1.apartmentName.errors }"
                                            />
                                            <div *ngIf="submitted && f1.apartmentName.errors" class="invalid-feedback">
                                                <div *ngIf="f1.apartmentName.errors.required">Building Name is required</div>
                                                <div *ngIf="f1.apartmentName.errors.pattern"> Please enter valid building name </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="labelfort required" for="address1">Address 1</label>
                                            <input type="text" id="address1" placeholder="Address 1" autocomplete="disabled" formControlName="address1" class="form-control css-inputfont" pattern="^[a-zA-Z0-9 !@#()_\-\[\]';:\\|,.\/]+$" [ngClass]="{ 'is-invalid': submitted && f1.address1.errors }"
                                            />
                                            <div *ngIf="submitted && f1.address1.errors" class="invalid-feedback">
                                                <div *ngIf="f1.address1.errors.required">Address 1 is required</div>
                                                <div *ngIf="f1.address1.errors.pattern"> Please enter valid address
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="labelfort" for="address1">Address 2</label>
                                            <input type="text" id="address2" placeholder="Address 2" autocomplete="disabled" formControlName="address2" class="form-control css-inputfont" pattern="^[a-zA-Z0-9 !@#()_\-\[\]';:\\|,.\/]+$" [ngClass]="{ 'is-invalid': submitted && f1.address2.errors }"
                                            />
                                            <div *ngIf="submitted && f1.address2.errors" class="invalid-feedback">
                                                <div *ngIf="f1.address2.errors.pattern"> Please enter valid address</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="labelfort" for="Landmark">Landmark</label>
                                            <input type="text" id="Landmark" placeholder="Landmark" autocomplete="disabled" formControlName="landmark" class="form-control css-inputfont" pattern="^[A-Za-z0-9 . -- / ,]+$" [ngClass]="{ 'is-invalid': submitted && f1.landmark.errors }" />
                                            <div *ngIf="submitted && f1.landmark.errors" class="invalid-feedback">
                                                <div *ngIf="f1.landmark.errors.pattern"> Please enter valid land mark</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="labelfort required" for="City">City</label>
                                            <select class="form-control css-inputfont" formControlName="city" (change)="onChangeCity($event)">
                                                <option *ngFor="let citys of locationlist; index as i" [value]="citys._id">
                                                  {{ citys._id }}
                                                </option>
                                              </select>
                                            <!-- <input type="text" id="City" placeholder="City" autocomplete="disabled"
                                                pattern="[A-Za-z]*" formControlName="city" class="form-control css-inputfont"
                                                [ngClass]="{ 'is-invalid': submitted && f1.city.errors }" /> -->
                                            <div *ngIf="submitted && f1.city.errors" class="invalid-feedback">
                                                <div *ngIf="f1.city.errors.required">City is required</div>
                                                <div *ngIf="f1.city.errors.pattern"> Please enter valid city </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="labelfort required" for="country">Country</label>
                                            <input type="text" id="country" class="form-control css-inputfont" value="INDIA" readonly>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="labelfort required" for="state">State</label>
                                            <input type="text" id="state" placeholder="State" class="form-control css-inputfont" formControlName="state" readonly />
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="labelfort required" for="pincode">Pin Code</label>
                                            <select class="form-control css-inputfont" formControlName="pincode">
                                                <option *ngFor="let pincodes of pincodeList; index as i" [value]="pincodes.pin_Code">
                                                  {{ pincodes.pin_Code }}
                                                </option>
                                              </select>
                                            <!-- <input type="text" id="pincode" placeholder="Pin Code" maxlength="6"
                                                pattern="^(0|[1-9][0-9]*)$" autocomplete="disabled" formControlName="pincode"
                                                class="form-control css-inputfont" [ngClass]="{ 'is-invalid': submitted && f1.pincode.errors }" /> -->
                                            <div *ngIf="submitted && f1.pincode.errors" class="invalid-feedback">
                                                <div *ngIf="f1.pincode.errors.required">Pincode is required</div>
                                                <div *ngIf="f1.pincode.errors?.minlength">Number should be 6 digit</div>
                                                <div *ngIf="f1.pincode.errors.pattern"> Please enter only numbers </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <div class="checkbox">
                                                    <label><input type="checkbox" formControlName="preferredAddress"
                                                            value="" class="css-inputfont">
                                                        Save this as my default delivery address
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <strong *ngIf="addressErrMsg" class="error-message">{{addressErrMsg}}</strong>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <div style="margin: 0 auto;width:80%;margin-bottom: 10px;margin-top: 10px;">
                    <button class="btn css-mybutton" style="width: 80px;" (click)="saveDelvAddress()">Save</button>
                    <button class="btn css-mybutton" style="margin-left: 20px;width: 80px;" (click)="cancelEditDelvAddress()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>