import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-max-quantity-allowed-pop-up',
  templateUrl: './max-quantity-allowed-pop-up.component.html',
  styleUrls: ['./max-quantity-allowed-pop-up.component.css']
})
export class MaxQuantityAllowedPopUpComponent implements OnInit {

  @Input() public maxQuantity;
  @Input() public maxQuantitypopup;
  @Input() public dodPopUp;
  @Input() public dodVal;
  @Input() public totalchagesWithOutDod;

  restAmountToplaceOrderWithDod:number;

  isdodpopup:boolean = false;
  ismaxQuantitypopup:boolean = false;

  actualMaxQuantity:any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    
    if(this.dodPopUp){
      this.isdodpopup = true;
      this.restAmountToplaceOrderWithDod = this.dodVal - this.totalchagesWithOutDod;
    }

    if(this.maxQuantitypopup){
      if(this.maxQuantity === 1){
        this.actualMaxQuantity = this.maxQuantity +" "+ "quantity";
      } else{
        this.actualMaxQuantity = this.maxQuantity +" "+"quantities";
      }
      
      this.ismaxQuantitypopup = true;
      setTimeout(() => {
        this.activeModal.close();
      }, 2000);
    }
    
  }

  OnCLickOk() {
    this.activeModal.close("Success");
  }
}
