import { Component, OnInit, NgZone } from '@angular/core';
import { RouterService } from '../services/router.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { SharedmobService } from '../services/sharedmob.service';
import { CartService } from '../services/cart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '../alert/alert.component';
import { OrderService } from '../services/order.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-show-cust-order',
  templateUrl: './show-cust-order.component.html',
  styleUrls: ['./show-cust-order.component.css']
})
export class ShowCustOrderComponent implements OnInit {

  errMessage: string;
  allOrderedItems: any = [];
  shippingDetails: any;
  orderResp: any;
  myorderId: any;
  submitMessage: any;
  subTotal: number = 0;
  totalCharges: number = 0;
  shippingCharge: number = 0;
  isloaded: boolean = false;
  custShippingObj: any;
  charges: any;
  custName: string;
  salutation: string;
  orderedDate: any;
  expectedDelvDate: any;
  dodVal: number = 0;
  freeDelCharges: number;

  orderdata: any;
  orderConfirmationResp: any;

  isOrderSuccessPage: boolean = false;
  hideSpinner: boolean = true;

  successmsg1: string;
  successmsg2: string;

  constructor(private routerService: RouterService,
    private sanitizer: DomSanitizer, private sharedService: SharedmobService,
    private cartService: CartService, private orderService: OrderService, 
    private dialog: MatDialog) {  }

  ngOnInit(): void {
    this.sharedService.currentOrderedData.subscribe(d => this.orderdata = d);
    if(this.orderdata){
      this.successmsg1 = "Please do not close or press back button until order confirmation.";
      this.orderConfirmation(this.orderdata);
      this.custShippingObj = JSON.parse(window.localStorage.getItem("shippingObj"));
      this.allOrderedItems = JSON.parse(window.localStorage.getItem("cartItemObj"));
      this.charges = JSON.parse(window.localStorage.getItem("sCharges"));
    }else{
      this.sharedService.changeOrderedData("");
    }
    
  }

  orderConfirmation(orderdata) {
    this.myorderId = "";
    this.orderService.confirmOrder(orderdata).subscribe(resp => {
      if (resp) {
        this.myorderId = resp["_id"];
        this.orderConfirmationResp = resp;
        this.loadData();
        this.hideSpinner = false;
        this.isOrderSuccessPage = true;
      }
    }, err => {
      this.hideSpinner = false;
      this.isOrderSuccessPage = false;
      this.successmsg1 = "Unable to complete your order, please contact customer care.";
      if (err.status === 403) {
        this.openLoginDialog(localStorage.getItem("mobileNumber"));
      }
      this.successmsg1 = "Payment Successfull.Please Wait For Order Confirmation!!";
    })
  }

  openLoginDialog(mobNo): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '350px',
      data: {
        'mobileNumber': mobNo
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === "Success") {
        let logedinmobileNumber = window.localStorage.getItem("userName");
        this.sharedService.changeMobileNo(logedinmobileNumber);
      }
    });
  }

  loadData() {
    var currentDate = new Date();
    this.orderedDate = new Date().toISOString();

    currentDate.setDate(currentDate.getDate() + this.charges["expected_Delivery_Date"]);
    const todayISOString = currentDate.toISOString();
    this.expectedDelvDate = todayISOString;
    if (this.custShippingObj) {
      this.salutation = this.custShippingObj["salutation"];
      this.custName = this.custShippingObj["first_Name"];
    }
    if (this.charges) {
      this.dodVal = this.charges["dod_condition_value"];
      this.freeDelCharges = this.charges["delivery_charges_free"];
    }

    for (let cartItem of this.allOrderedItems) {
      let totalQuantityPrice = 0;
      if (cartItem.deal_Of_Day_Item) {
        totalQuantityPrice = cartItem.cart_Quantity * cartItem.deal_Price;
      } else {
        // totalQuantityPrice = cartItem.cart_Quantity * cartItem.sell_Price;
        totalQuantityPrice = cartItem.cart_Quantity * cartItem.allfresh_Price;
      }
      this.subTotal += totalQuantityPrice;
    }

    if (this.subTotal < this.freeDelCharges) {
      this.shippingCharge = this.charges["delivery_Charges"];
      this.totalCharges = this.subTotal + this.shippingCharge;
    } else {
      this.shippingCharge = 0;
      this.totalCharges = this.subTotal + 0;
    }
  }

  imageconvertor(imgdata) {
    let objectURL = 'data:image/png;base64,' + imgdata;
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  showCustOrderPopupDismis() {
    window.localStorage.removeItem("cartItemObj");
    window.localStorage.removeItem("shippingObj");
    window.sessionStorage.removeItem("outOfStockItems");
    window.sessionStorage.removeItem("priceChangedItems");
    this.sharedService.changeCount(0);
    this.cartService.clearAllData();
    this.sharedService.changeOrderedData("");
    this.routerService.routeToHome();
  }

}
