import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedmobService {

  //for mobile number after login
  private mobileSource = new BehaviorSubject<string>('');
  currentMobile = this.mobileSource.asObservable();

  //for count
  private countSource = new BehaviorSubject<number>(0);
  currentCount = this.countSource.asObservable();

  //for total price
  private totPriceSource = new BehaviorSubject<number>(0);
  currentTotalPrice = this.totPriceSource.asObservable();

  //for search 
  private searchkey = new BehaviorSubject<string>('');
  currentSearch = this.searchkey.asObservable();
  
  //for Order Confirmation 
  private ordereddata = new BehaviorSubject<any>('');
  currentOrderedData = this.ordereddata.asObservable();
  constructor() { }

  changeMobileNo(mobile: string) {
    this.mobileSource.next(mobile)
  }

  changeCount(count: number) {
    this.countSource.next(count);
  }

  changeTotalPrice(tot: number) {
    this.totPriceSource.next(tot);
  }

  changeToSearch(search: string) {
    this.searchkey.next(search);
  }

  changeOrderedData(orderedData: string) {
    this.ordereddata.next(orderedData);
  }
}
