import { Component, OnInit, ViewChildren, ElementRef, QueryList, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserProfileService } from '../services/user-profile.service';
import { CartService } from '../services/cart.service';
import { AppComponent } from '../app.component';
import { AuthService } from '../services/auth.service';
import { LoginComponent } from '../login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedmobService } from '../services/sharedmob.service';


@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.css']
})
export class EditprofileComponent implements OnInit {

  @ViewChildren("salutationid") sowDelvAdrressView: QueryList<ElementRef>;
  @ViewChild("salutationid") showSalDiv: ElementRef;

  errMessage: string;
  successMessage: string;
  addressErrMsg: string;
  personalInfoForm: FormGroup;
  editDelvAddressForm: FormGroup;
  submitted = false;
  userPersonalInfoObj: any = [];
  getUserByUserIdObj: any = [];
  useridFromSession: any;
  isHomeAddrexist: boolean = false;
  isOfficeAddrexist: boolean = false;
  isOthersAddrexist: boolean = false;
  iseditDelivAddr: boolean = false;
  iseditProfile: boolean = true;

  address1: any;
  address2: any;
  apartment_Name: any;
  house_No: any;
  address_Type: any;
  city: any;
  state: any;
  pinCode: any;
  landMark: any;
  homePreferedAdd: any;

  ofaddress1: any;
  ofaddress2: any;
  ofapartment_Name: any;
  ofhouse_No: any;
  ofaddress_Type: any;
  ofcity: any;
  ofstate: any;
  ofpinCode: any;
  oflandMark: any;
  ofPreferedAdd: any;

  oaddress1: any;
  oaddress2: any;
  oapartment_Name: any;
  ohouse_No: any;
  oaddress_Type: any;
  ocity: any;
  ostate: any;
  opinCode: any;
  olandMark: any;
  oPreferedAdd: any;

  editAddrType: string;
  delAddrType: string;

  options: any = ["Mr", "Mrs", "Ms"];
  defaultoption: string = "Mr";

  updateDelvAddressResp: any;
  transitionForm: any;
  dobdate;

  locationlist: any;
  pincodeList: any;
  selectedCity: any;
  selectedPinCode: any;

  constructor(private formBuilder: FormBuilder, private appcomp: AppComponent,
    private userprofileService: UserProfileService, private authService: AuthService,
    private cartService: CartService, private dialog: MatDialog,
    private sharedService: SharedmobService) {

  }

  ngOnInit(): void {
    this.personalInfoForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern("[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]],
      mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      salutation: [''],
      dob: ['', Validators.required],
    });


    this.getUserInfoByUserId();
    this.editDelvAddressForm = this.formBuilder.group({
      pincode: ['', [Validators.required, Validators.minLength(6)]],
      address1: ['', Validators.required],
      address2: [''],
      apartmentName: ['', Validators.required],
      houseNo: ['', Validators.required],
      city: ['', Validators.required],
      landmark: [''],
      state: [''],
      addressType: [''],
      preferredAddress: ['']
    });
    this.locationlist = JSON.parse(localStorage.getItem("deliveryLocationList"));
    this.selectedCity = localStorage.getItem("delLoc");
    this.loadCity(this.selectedCity);

  }

  loadCity(selCity) {

    if (this.locationlist && this.selectedCity) {
      for (let loc of this.locationlist) {
        if (loc._id === selCity) {
          this.editDelvAddressForm.controls.state.setValue(loc.state);
          this.pincodeList = loc["pin_Codes"];
        }
      }
    }
  }
  ngAfterViewInit() {
    //for focus field
    if (this.showSalDiv) {
      this.showSalDiv.nativeElement.focus();
    }
    this.sowDelvAdrressView.changes.subscribe((list: QueryList<ElementRef>) => {
      if (list.length > 0) {
        list.first.nativeElement.focus();
      }
    });
  }

  getUserInfoByUserId() {
    this.useridFromSession = window.localStorage.getItem("userId");
    if (this.useridFromSession) {
      let getloginTimediff = this.appcomp.getLoginTimeDif();
      if (getloginTimediff >= 14) {
        this.authService.refreshToken().subscribe(resp => {
          getloginTimediff = 1;
          if (resp['tkn_Status']) {
            let token = resp['token'];
            if (token) {
              this.authService.setBearerToken(token);
              let currentTime = new Date().getTime();
              window.localStorage.setItem("userlogintime", currentTime + "");
              this.getMyProfile();
            } else {
              this.openLoginDialog(window.localStorage.getItem("mobileNumber"));
            }
          }
          else {
            this.openLoginDialog(window.localStorage.getItem("mobileNumber"));
          }
        },
          err => {
            this.openLoginDialog(window.localStorage.getItem("mobileNumber"));
          });
      }
      else {
        this.getMyProfile();
      }
    }
  }

  getMyProfile() {
    this.userprofileService.getUserById(this.useridFromSession).subscribe(resp => {
      this.getUserByUserIdObj = resp;
      this.displayPersonalInfo();
    }, err => {
      this.errMessage = err.error.message;
    })
  }
  openLoginDialog(mobNo): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '350px',
      data: {
        'mobileNumber': mobNo
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === "Success") {
        let logedinmobileNumber = window.localStorage.getItem("userName");
        this.sharedService.changeMobileNo(logedinmobileNumber);
        this.getUserInfoByUserId();
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.personalInfoForm.controls; }
  clarDelvAddressdata() {
    this.isHomeAddrexist = false;
    this.isOfficeAddrexist = false;
    this.isOthersAddrexist = false;
  }
  displayPersonalInfo() {
    this.clarDelvAddressdata();
    var currentDate = "";
    if (this.getUserByUserIdObj && this.getUserByUserIdObj['dob']) {
      currentDate = new Date(this.getUserByUserIdObj['dob']).toISOString().substring(0, 10);
    }
    this.personalInfoForm.controls['salutation'].setValue(this.getUserByUserIdObj['salutation']);
    this.personalInfoForm.controls['firstName'].setValue(this.getUserByUserIdObj['first_Name']);
    this.personalInfoForm.controls['lastName'].setValue(this.getUserByUserIdObj['last_Name']);
    this.personalInfoForm.controls['dob'].setValue(currentDate);
    this.personalInfoForm.controls['email'].setValue(this.getUserByUserIdObj['email']);
    this.personalInfoForm.controls['mobileNumber'].setValue(this.getUserByUserIdObj['mobileNumber']);
    let userDeliveryAddress = this.getUserByUserIdObj['userDeliveryAddress'];
    if (userDeliveryAddress && userDeliveryAddress.length > 0) {
      for (let address of userDeliveryAddress) {
        if (address['user_Id'] && address['address_Type'] === "Home") {
          this.isHomeAddrexist = true;
          this.address1 = address['address1'];
          this.address2 = address['address2'];
          this.apartment_Name = address['apartment_Name'];
          this.house_No = address['house_No'];
          this.address_Type = address['address_Type'];
          this.city = address['city'];
          this.state = address['state'];
          this.pinCode = address['pinCode'];
          this.landMark = address['landMark'];
          if (address['preferred_Address'] === true) {
            this.homePreferedAdd = "Default Address";
            this.ofPreferedAdd = "";
            this.oPreferedAdd = "";
          }

        }
        if (address['user_Id'] && address['address_Type'] === "Office") {
          this.isOfficeAddrexist = true;
          this.ofaddress1 = address['address1'];
          this.ofaddress2 = address['address2'];
          this.ofapartment_Name = address['apartment_Name'];
          this.ofhouse_No = address['house_No'];
          this.ofaddress_Type = address['address_Type'];
          this.ofcity = address['city'];
          this.ofstate = address['state'];
          this.ofpinCode = address['pinCode'];
          this.oflandMark = address['landMark'];
          if (address['preferred_Address'] === true) {
            this.ofPreferedAdd = "Default Address";
            this.homePreferedAdd = "";
            this.oPreferedAdd = "";
          }
        }
        if (address['user_Id'] && address['address_Type'] === "Other") {
          this.isOthersAddrexist = true;
          this.oaddress1 = address['address1'];
          this.oaddress2 = address['address2'];
          this.oapartment_Name = address['apartment_Name'];
          this.ohouse_No = address['house_No'];
          this.oaddress_Type = address['address_Type'];
          this.ocity = address['city'];
          this.ostate = address['state'];
          this.opinCode = address['pinCode'];
          this.olandMark = address['landMark'];
          if (address['preferred_Address'] === true) {
            this.oPreferedAdd = "Default Address";
            this.homePreferedAdd = "";
            this.ofPreferedAdd = "";
          }
        }
      }
    }
  }

  saveEditProfile() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.personalInfoForm.invalid) {
      return;
    }

    this.userPersonalInfoObj = {
      "_id": this.useridFromSession,
      "active": true,
      "created_DateTime": new Date().toISOString(),
      "dob": this.personalInfoForm.get('dob').value,
      "email": this.personalInfoForm.get('email').value,
      "first_Name": this.personalInfoForm.get('firstName').value,
      "first_Time_Login": true,
      "last_Login_DateTime": this.getUserByUserIdObj['last_Login_DateTime'],
      "last_Name": this.personalInfoForm.get('lastName').value,
      "mobileNumber": this.personalInfoForm.get('mobileNumber').value,
      "profile_Updated": true,
      "salutation": this.personalInfoForm.get('salutation').value,
      "userDeliveryAddress": this.getUserByUserIdObj['userDeliveryAddress'],
      "user_Type": "Retail"
    }
    this.userprofileService.updateUserById(this.userPersonalInfoObj).subscribe(resp => {
      this.successMessage = "Personal Details Saved Successfully";
    }, err => {
      this.errMessage = "Unable to save Personal details";
    })
  }

  openAddDeliveryAddress(addrType) {
    this.editAddrType = addrType;
    this.iseditDelivAddr = true;
    this.iseditProfile = false;
    this.successMessage = '';
    this.errMessage = '';
    this.editDelvAddressForm.controls['address1'].setValue('');
    this.editDelvAddressForm.controls['address2'].setValue('');
    this.editDelvAddressForm.controls['apartmentName'].setValue('');
    this.editDelvAddressForm.controls['houseNo'].setValue('');
    this.editDelvAddressForm.controls['pincode'].setValue(this.pincodeList[0]['pin_Code']);
    this.editDelvAddressForm.controls['landmark'].setValue('');
    this.editDelvAddressForm.controls['city'].setValue(this.selectedCity);
    this.editDelvAddressForm.controls['preferredAddress'].setValue(false);

  }

  cancelEditDelvAddress() {
    this.iseditDelivAddr = false;
    this.iseditProfile = true;
    this.successMessage = '';
    this.errMessage = '';
  }

  editDeliveryAddress(addrType) {
    let selectAddrType;
    this.editAddrType = addrType;

    for (let address of this.getUserByUserIdObj["userDeliveryAddress"]) {
      if (address['user_Id'] && address['address_Type'] === addrType) {
        selectAddrType = address;
      }
      // if (address['user_Id'] && address['address_Type'] === "Office") {
      //   selectAddrType = address;
      // }
      // if (address['user_Id'] && address['address_Type'] === "Other") {
      //   selectAddrType = address;
      // }
    }
    if (selectAddrType) {
      this.loadCity(selectAddrType.city);
      this.editDelvAddressForm.controls['address1'].setValue(selectAddrType.address1);
      this.editDelvAddressForm.controls['address2'].setValue(selectAddrType.address2);
      this.editDelvAddressForm.controls['apartmentName'].setValue(selectAddrType.apartment_Name);
      this.editDelvAddressForm.controls['houseNo'].setValue(selectAddrType.house_No);
      this.editDelvAddressForm.controls['pincode'].setValue(selectAddrType.pinCode);
      this.editDelvAddressForm.controls['landmark'].setValue(selectAddrType.landMark);
      this.editDelvAddressForm.controls['city'].setValue(selectAddrType.city);
      this.editDelvAddressForm.controls['state'].setValue(selectAddrType.state);
      this.address_Type = selectAddrType.address_Type;
      this.editDelvAddressForm.controls['preferredAddress'].setValue(selectAddrType.preferred_Address);
      this.iseditDelivAddr = true;
      this.iseditProfile = false;
    }
  }
  // convenience getter for easy access to form fields
  get f1() { return this.editDelvAddressForm.controls; }

  saveDelvAddress() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.editDelvAddressForm.invalid) {
      return;
    }
    let DelvAddressObj =
    {
      "address1": this.editDelvAddressForm.get('address1').value,
      "address2": this.editDelvAddressForm.get('address2').value,
      "address_Type": this.editAddrType,
      "apartment_Name": this.editDelvAddressForm.get('apartmentName').value,
      "city": this.editDelvAddressForm.get('city').value,
      "house_No": this.editDelvAddressForm.get('houseNo').value,
      "landMark": this.editDelvAddressForm.get('landmark').value,
      "pinCode": this.editDelvAddressForm.get('pincode').value,
      "state": this.editDelvAddressForm.get('state').value,
      "user_Id": window.localStorage.getItem("userId"),
      "preferred_Address": this.editDelvAddressForm.get('preferredAddress').value
    }

    this.userprofileService.updateDeliveryAddress(DelvAddressObj).subscribe(resp => {
      this.updateDelvAddressResp = resp;
      if (this.updateDelvAddressResp) {
        this.iseditDelivAddr = false;
        this.iseditProfile = true;
        this.getUserInfoByUserId();
      }
      this.submitted = false;
    }, err => {
      this.addressErrMsg = "Unable to update address details";
      this.submitted = false;
    })
  }

  deleteDeliveryAddress(addrType) {
    let selectAddrType;
    this.delAddrType = addrType;
    for (let address of this.getUserByUserIdObj["userDeliveryAddress"]) {
      if (address['user_Id'] && address['address_Type'] === this.delAddrType) {
        selectAddrType = address;
      }
    }
    selectAddrType["user_Id"] = window.localStorage.getItem("userId");

    this.userprofileService.deleteDeliveryAddress(selectAddrType).subscribe(resp => {
      this.getUserInfoByUserId();
    }, err => {
      this.errMessage = err.error.message;
    })
  }

  public searchCall(data, isclose) {
    this.cartService.searchCall(data, isclose, 'myeditprofid');
  }

  onChangeCity(e) {
    for (let loc of this.locationlist) {
      if (e.target.value == loc._id) {
        this.editDelvAddressForm.controls.state.setValue(loc.state);
        this.pincodeList = loc["pin_Codes"];
      }
    }
  }
}
