<div class="row accordion-gradient-bcg d-flex justify-content-center" style="margin-right: 0px;margin-left: 0px;margin-top: 10px;">
    <div class="col-md-10 col-xl-8 py-7" style="padding-right: 0px;">
        <div class="accordion md-accordion accordion-2" id="accordionEx7" role="tablist" aria-multiselectable="true">
            <div class="card">
                <div class="card-header rgba-stylish-strong z-depth-1 mb-1" role="tab" id="heading1">
                    <p class="card-text">
                        {{heading}}
                    </p>
                </div>
                <div>
                    <div class="card-header card-text" style="background: white;color: orange;">
                        <p class="card-text">
                            {{subHeading}}
                        </p>
                    </div>
                    <div class="collapse show" role="tabpanel" aria-labelledby="heading2" data-parent="#accordionEx7">
                        <div class="card-body mb-1 rgba-grey-light white-text">
                            <div>
                                <div class="css-mjrs0s">
                                    <div class="css-vurnku">
                                        <div class="row" *ngFor="let ost of outOfStockItems">
                                            <div class=" col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 css-cartitemmdl">
                                                <div class="css-1kxr47f">
                                                    <div class=" gatsby-image-wrapper" style="position: relative; overflow: hidden;">
                                                        <div aria-hidden="true" style="width: 100%; padding-bottom: 100%;"></div>
                                                        <img aria-hidden="true" [src]="ost.product_Image" alt="" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; object-fit: cover; object-position: center center;">
                                                    </div>
                                                    <div class="css-shownumber card-text">
                                                        <button class="css-pncr0q" title="Order Quantity">
                                                            {{ost.cart_Quantity}}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 css-cartitemmdl">
                                                <div class="css-vurnku">
                                                    <div class="white-text font-thin css-1q9j665"><b>{{ost.farm_Short_Name}}</b></div>
                                                    <div class="white-text font-thin css-1facnq0">{{ost.product_Desc}} {{ost.quantity_Desc}}
                                                    </div>
                                                    <div class="white-text font-thin css-1facnq0">Rs.
                                                        <!-- {{ost.sell_Price | number:'1.2-2'}} -->
                                                        {{ost.allfresh_Price | number:'1.2-2'}}
                                                    </div>
                                                    <div class="white-text font-thin css-avlqty">Available Quantity - {{ost.available_Qty}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 css-cartitemmdl">
                                                <div class="white-text font-thin card-text">
                                                    <!-- Rs. {{ost.cart_Quantity * ost.sell_Price | number:'1.2-2' }} -->
                                                    Rs. {{ost.cart_Quantity * ost.allfresh_Price | number:'1.2-2' }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngFor="let pcp of priceChangedItemsList">
                                            <div class=" col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 css-cartitemmdl">
                                                <div class="css-1kxr47f">
                                                    <div class=" gatsby-image-wrapper" style="position: relative; overflow: hidden;">
                                                        <div aria-hidden="true" style="width: 100%; padding-bottom: 100%;"></div>
                                                        <img aria-hidden="true" [src]="pcp.product_Image" alt="" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; object-fit: cover; object-position: center center;">
                                                    </div>
                                                    <div class="css-shownumber card-text">
                                                        <button class="css-pncr0q" title="Order Quantity">
                                                            {{pcp.cart_Quantity}}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 css-cartitemmdl">
                                                <div class="css-vurnku">
                                                    <div class="white-text font-thin css-1q9j665"><b>{{pcp.farm_Short_Name}}</b></div>
                                                    <div class="white-text font-thin css-1facnq0">{{pcp.product_Desc}} {{pcp.quantity_Desc}}
                                                    </div>
                                                    <div class="white-text font-thin css-1facnq0">
                                                        <!-- Rs. {{pcp.sell_Price | number:'1.2-2'}} -->
                                                        Rs. {{pcp.allfresh_Price | number:'1.2-2'}}
                                                    </div>
                                                    <div class="white-text font-thin css-1facnq0" style="text-decoration: line-through;">Rs. {{pcp.oldPrice | number:'1.2-2'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 css-cartitemmdl">
                                                <div class="white-text font-thin card-text">
                                                    <!-- Rs. {{pcp.cart_Quantity * pcp.sell_Price | number:'1.2-2' }} -->
                                                    Rs. {{pcp.cart_Quantity * pcp.allfresh_Price | number:'1.2-2' }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-text" style="border-top: 1px solid rgba(0,0,0,.125);padding: 15px;">
                    {{otfooter}}
                </div>
                <div style="padding: 10px;">
                    <button class="btn css-mybutton" style="margin-right: 10px;" (click)="confirmStockItems()"> Confirm
                    </button>
                    <button class="btn css-mybutton" (click)="goBackShopping()"> Go Back Shopping </button>
                </div>
            </div>
        </div>
    </div>
</div>